import React from 'react';
import {FormProvider, useForm, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {Button, NewNumberInputField, NewRadioField, NewTextareaField} from '@edna/components';
import {Label} from '@edna/components/FormField';
import {Stack} from '@edna/components/primitives';

import {FilePickerField, Message} from 'src/components';
import {FieldsRow, SecondaryText} from 'src/components/primitives';
import {EChannelType, MAX_CALENDAR_DATE} from 'src/constants';

import * as S from '../style';
import {showConfirmModal} from './ConfirmModal';
import {
  DEFAULT_VALUES,
  ETariffActionType,
  MAX_DECIMAL_SCALE,
  MIN_DATE,
  TFormData,
  channelOptions,
} from './definitions';
import {mapTariffIds, resolver} from './utils';

const PriceFields = React.memo(() => {
  const {t} = useTranslation();
  const {watch} = useFormContext<TFormData>();

  const channelType = watch('channelType');

  if (channelType !== EChannelType.WHATSAPP) {
    return (
      <Stack size="2">
        <Label htmlFor="licensePrice">{t('Corrections:tariffs.licensePrice')}</Label>
        <S.NumberInputField
          isDecimal
          decimalScale={MAX_DECIMAL_SCALE}
          noMargin
          name="licensePrice"
        />
      </Stack>
    );
  }

  return (
    <FieldsRow size="5">
      <Label htmlFor="licensePrice">{t('Corrections:tariffs.licensePriceWA')}</Label>
      <NewNumberInputField isDecimal decimalScale={MAX_DECIMAL_SCALE} name="licensePrice" />
      <Label htmlFor="mauCount">{t('Corrections:tariffs.mauCount')}</Label>
      <NewNumberInputField name="mauCount" />
      <Label htmlFor="initPayment">{t('Corrections:tariffs.initPayment')}</Label>
      <NewNumberInputField isDecimal decimalScale={MAX_DECIMAL_SCALE} name="initPayment" />
      <Label htmlFor="defaultMauPrice">{t('Corrections:tariffs.defaultMauPrice')}</Label>
      <NewNumberInputField isDecimal decimalScale={MAX_DECIMAL_SCALE} name="defaultMauPrice" />
    </FieldsRow>
  );
});

const Content = React.memo(() => {
  const {t} = useTranslation();
  const [showTariffIdsWarning, setShowTariffIdsWarning] = React.useState(false);

  const {
    watch,
    trigger,
    getValues,
    handleSubmit,
    reset,
    formState: {isValid},
  } = useFormContext<TFormData>();

  const actionType = watch('action');
  const isTariffTemplate = watch('isTariffTemplate');
  const tariffIds = watch('idToUpdate');
  const fromDate = watch('fromDate');

  React.useEffect(() => {
    trigger('fromTime');
  }, [fromDate]);

  const handleReset = React.useCallback(() => reset(), [reset]);
  const showConfirm = React.useCallback(
    () => showConfirmModal({onSuccess: handleReset, values: getValues()}),
    [handleReset, getValues],
  );

  const handleBlurTariffIds = React.useCallback(
    () => setShowTariffIdsWarning(!!tariffIds),
    [tariffIds],
  );

  return (
    <>
      <SecondaryText weight={500} size="L" topIndent="5" bottomIndent="2">
        {t('Corrections:tariffs.newSection')}
      </SecondaryText>
      <Stack size="4">
        <PriceFields />
        <Stack size="2">
          <Label>{t('Corrections:tariffs.file')}</Label>
          <FilePickerField name="file" accept=".xlsx" />
        </Stack>
        {!isTariffTemplate && (
          <Stack size="2">
            <Label htmlFor="fromDate">{t('Corrections:tariffs.date')}</Label>
            <FieldsRow size="4" isWithoutLabels>
              <S.DatePickerField name="fromDate" minDate={MIN_DATE} maxDate={MAX_CALENDAR_DATE} />
              <S.TimePickerField name="fromTime" />
            </FieldsRow>
          </Stack>
        )}
        <NewTextareaField
          name="idToUpdate"
          noMargin
          mapValue={mapTariffIds}
          label={t('Corrections:tariffs.tariffIds')}
          rows={3}
          onBlur={handleBlurTariffIds}
        />
        {showTariffIdsWarning && (
          <Message type="Warning" shadowSize="XS" fontSize="S" noMargin closible>
            {t('Corrections:tariffs.updateWarning')}
          </Message>
        )}
        <NewTextareaField
          name="newName"
          noMargin
          label={t(`Corrections:tariffs.comment.${actionType}`)}
          placeholder={t('Corrections:tariffs.commentPlaceholder')}
        />
        <Stack direction="ROW" size="5">
          {isValid && <Button onClick={handleReset}>{t('Common:button.cancel')}</Button>}
          <Button onClick={handleSubmit(showConfirm)} disabled={!isValid} appearance="primary">
            {t(`Corrections:tariffs.submitButton.${actionType}`)}
          </Button>
        </Stack>
      </Stack>
    </>
  );
});

export const TariffsTab = React.memo(() => {
  const {t} = useTranslation();

  const methods = useForm<TFormData>({
    mode: 'all',
    defaultValues: DEFAULT_VALUES,
    resolver,
  });

  const actionType = methods.watch('action');
  const channelType = methods.watch('channelType');

  const actionOptions = React.useMemo(
    () =>
      Object.values(ETariffActionType).map((action) => ({
        value: action,
        label: t(`Corrections:tariffs.actionOption.${action}`),
      })),
    [t],
  );

  return (
    <FormProvider {...methods}>
      <Stack size="4">
        <SecondaryText weight={500} size="L">
          {t('Corrections:tariffs.mainSection')}
        </SecondaryText>
        {!!actionType && !!channelType && (
          <Message type="Info" shadowSize="XS" fontSize="S" noMargin closible>
            {t('Corrections:tariffs.updateInfo')}
          </Message>
        )}
        <Stack size="1">
          <NewRadioField
            label={t('Corrections:tariffs.tariffType')}
            type="radio"
            name="isTariffTemplate"
            value={false}
            noMargin
          >
            {t('Corrections:tariffs.tariff')}
          </NewRadioField>
          <NewRadioField type="radio" name="isTariffTemplate" value={true} noMargin>
            {t('Corrections:tariffs.tariffTemplate')}
          </NewRadioField>
        </Stack>
        <S.SelectField
          name="action"
          label={t('Corrections:tariffs.actionType')}
          placeholder={t('Corrections:tariffs.actionTypePlaceholder')}
          options={actionOptions}
        />
        <S.SelectField
          name="channelType"
          label={t('Corrections:tariffs.channelType')}
          placeholder={t('Corrections:tariffs.channelTypePlaceholder')}
          options={channelOptions}
        />
      </Stack>
      {!!actionType && !!channelType && <Content />}
    </FormProvider>
  );
});

TariffsTab.displayName = 'TariffsTab';
