export default {
  title: 'Настройка Multi-Partner Solutions',
  name: 'Solution name',
  tenantId: 'ID тенанты',
  solutionId: 'Solution ID',
  solutionIdError: 'SolutionID не зарегистрирован на edna Enterprise. Ошибка "{{error}}"',
  solutionNameError: 'Solution name не зарегистрирован на edna Enterprise. Ошибка "{{error}}"',
  solutionIdDuplicateError:
    'SolutionID {{solutionId}} уже зарегистрирован для тенанты {{tenantId}}',
};
