import {ESmsCategory} from 'src/containers/MessageMatchers/SMSForm/definitions';
import {ELicensePeriod} from 'src/containers/Tariffs/definitions';

import {EWAConversation} from '../definitions';

export default {
  period: 'Период',
  downloadReport: 'Выгрузить отчет',
  hint: 'Количество единиц, за которые вам необходимо будет заплатить согласно вашему тарифу',
  more: 'Подробнее',
  direction: 'Направление',
  count: 'Количество',
  cost: 'Стоимость, {{currencySymbol}}',
  costPerSegment: 'Стоимость за 1 сегмент, {{currencySymbol}} ',
  operator: 'Оператор',
  type: 'Тип',
  outgoing: 'Исходящие',
  incoming: 'Входящие',
  incomingMessages: 'Входящие сообщения',
  outgoingMessages: 'Исходящие сообщения',
  messagesCount: 'Всего',
  sent: 'Отправлено',
  delivered: 'Доставлено',
  notDelivered: 'Не доставлено',
  read: 'Прочитано',
  total: 'Итого, {{currencySymbol}}',
  incomingCount: 'Количество входящих сообщений',
  charged: 'Протарифицировано',
  promotional: 'Рекламные',
  service: 'Сервисные',
  other: 'Прочее',
  viberReport: {
    minimumPayment: 'Минимальный платеж',
    viberMinimumPayment: 'Минимальный платеж Viber, {{currencySymbol}}',
  },
  smsReport: {
    emptyMessages: 'За выбранный период данных о сообщениях нет',
    subscriptionFee: 'Абонентская плата',
    operator: 'Оператор',
    priceForMonth: 'Стоимость, в месяц',
    trafficType: 'Тип трафика',
    segmentCount: 'Всего сегментов',
    multinaming: 'Мультинейминг',
    category: 'Категория',
    averageCost: 'Стоимость за 1 сообщение',
    totalCost: 'Стоимость итого, руб.',
    currency: 'Валюта',
    billingInfo:
      'Указанная информация актуальна на момент выполнения итогового отчета. Данные за текущий месяц могут измениться',
    defaultInfo:
      'Указана предварительная информация, актуальная на момент выполнения итогового отчета. Точная информация будет доступна в конце отчетного периода',
    outgoingHint: 'Стоимость сообщений зависит от количества сегментов в сообщении',
    categories: {
      [ESmsCategory.SERVICE]: 'Сервис',
      [ESmsCategory.AUTHORISATION]: 'Авторизация',
      [ESmsCategory.TEMPLATE_ADV]: 'Шаблонируемая реклама',
      [ESmsCategory.ADVERTISEMENT]: 'Реклама',
    },
    download: 'Выгрузить отчет',
    averageCostHint:
      'Если для оператора действует степ-тарификация, то мы выводим усредненную стоимость сообщения для категории ',
  },
  whatsappReport: {
    ndsHint: 'Все цены указаны без НДС',
    for: 'за',
    licenseTableTitle: 'Лицензия',
    licensePeriodTitle: 'Период лицензии',
    licenseDuration: 'Срок действия лицензии',
    licensePrice: 'Стоимость лицензии по тарифу, {{currencySymbol}}',
    licenseBilled: 'Фактически начислено за период, {{currencySymbol}}',
    licensePeriod: {
      [ELicensePeriod.MONTH]: 'Ежемесячная',
      [ELicensePeriod.YEAR]: 'Годовая',
    },
    mauTableTitle: 'MAU / AMAU',
    mauInPackage: 'MAU в пакете',
    mauQuantity: 'Текущее кол-во MAU',
    remainingMau: 'Остаток MAU',
    amauQuantity: 'Текущее кол-во AMAU',
    amauPrice: 'Цена за AMAU, {{currencySymbol}} ',
    totalForAmau: 'Итого за AMAU, {{currencySymbol}} ',
    conversations: 'Диалоги',
    conversationsHint:
      'Oтображаются только ненулевые значения. Если какая-то категория диалогов отсутствует, то за выбранный период времени количество диалогов этой категории было нулевым.',
    free: 'Бесплатные',
    chatMessages: 'Чат-сообщения',
    hsm: 'HSM',
    user: 'Входящие',
    business: 'Исходящие',
    [EWAConversation.REFERRAL]: 'Бесплатные точки входа',
    [EWAConversation.SERVICE]: 'Входящие',
    [EWAConversation.UTILITY]: 'Служебные',
    [EWAConversation.AUTHENTICATION]: 'Авторизационные',
    [EWAConversation.MARKETING]: 'Маркетинговые',
  },
};
