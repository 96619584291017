import {EAudienceGroupState} from 'src/constants';

import {EFormStep, EStatisticsCount, EStatus} from './definitions';

export default {
  createBroadcast: 'broadcast',
  actions: {
    cancelBroadcast: 'Cancel broadcast',
    returnToDrafts: 'Return to drafts',
  },
  details: {
    actions: 'Actions with the broadcast',
    statusAt: {
      [EStatus.SCHEDULED]: 'Last updated at {{date}}',
      [EStatus.RUNNING]: 'Running at {{date}}',
      [EStatus.COMPLETED]: 'Completed at {{date}}',
      [EStatus.CANCELLED]: 'Canceled at {{date}}',
      [EStatus.TRIGGERED]: 'Running at {{date}}',
      [EStatus.CANCELING]: 'Canceled at {{date}}',
    },
    nameLabel: 'Broadcast name',
    initiatorLabel: 'Broadcast initiator',
    statisticsTitle: 'Broadcast summary',
    stagesStatisticsTitle: 'Broadcast stages summary',
    clicksStatisticsTitle: 'Link button click statistics',
    requestedAt: 'Data at {{date}}',
    statistics: {
      [EStatisticsCount.totalCount]: 'Sent messages',
      [EStatisticsCount.deliveredCount]: 'Delivered messages',
      [EStatisticsCount.readCount]: 'Read messages',
      [EStatisticsCount.clickedCount]: 'Clicked on link button in message',
      [EStatisticsCount.undeliveredCount]: 'Undelivered messages',
    },
    th: {
      name: 'Stage step/channel',
      sentCount: 'Sent via channel',
      deliveredCount: 'Received via channel',
      clickedCount: 'Clicked on link button',
      clickedPercentCount: '% of broadcast audience',
      readCount: 'Read in feed',
      undeliveredCount: 'Not received (expired)',
      insideChannel: 'In channel message',
      buttonCount: 'Button {{count}}: <0>{{name}}</0>',
      button: 'Button: <0>{{name}}</0>',
    },
    report: 'Message report',
  },
  allEmpty: {
    title: 'No broadcasts here yet',
    subtitle:
      'A broadcast is a set of attributes and rules that define how you send bulks of messages to your clients.',
    listTitle: 'To get started with broadcasts, you need to:',
    listItem1: 'Have at least one active <0>channel</0> in edna Pulse',
    listItem2:
      'If the channel only works with templates, create and register at least one <0>template</0> for it',
    messageMatchersButton: 'To templates',
    channelsButton: 'To channels',
  },
  empty: {
    title: 'You are a pioneer!',
    subtitle: 'There are no broadcasts here yet, but you can easily fix that!',
    listTitle: 'Verify that you have everything you need to get started:',
    listItem1: 'At least one active <0>channel</0>',
    listItem2: 'If the channel only works with templates, at least one <0>template</0> for it',
    createButton: 'Create broadcast',
  },
  th: {
    startPlannedAt: 'Start date',
    name: 'Name',
    status: 'Status',
    audience: 'Audience',
    receivedMessagesNumber: 'Number of messages received',
    openedMessagesNumber: 'Number of messages opened',
    notReceivedMessagesNumber: 'Number of messages not received ',
  },
  td: {
    cascade: 'Cascade: {{name}}',
  },
  status: {
    [EStatus.DRAFT]: 'Draft',
    [EStatus.SCHEDULED]: 'Scheduled',
    [EStatus.TRIGGERED]: 'Scheduled',
    [EStatus.RUNNING]: 'In progress',
    [EStatus.CANCELING]: 'Canceling',
    [EStatus.COMPLETED]: 'Finished',
    [EStatus.CANCELLED]: 'Canceled',
  },
  confirm: {
    cancelTitle: 'Would you like to cancel the broadcast?',
    cancelBody:
      'If you click "yes" now, the broadcast you have selected will be stopped and you will not be able to restart it, you will have to create it again',
    delete: 'Are you sure you want to remove the broadcast "{{name}}"? This cannot be undone.',
  },
  steps: {
    [EFormStep.SETTINGS]: 'Broadcast settings',
    [EFormStep.AUDIENCE]: 'Broadcast audience',
    [EFormStep.CONTENT]: 'Broadcast content',
    [EFormStep.CHECK]: 'Broadcast check',
  },
  messageSpeedOptions: {
    perSecond_one: '{{count}} message per second',
    perSecond_other: '{{count}} messages per second',
    defaultPrefix: 'Default is ',
  },
  settingsForm: {
    name: 'Broadcast name',
    nameHint: 'This name will be used in the broadcast list and in the messages analytics',
    startPlannedAt: 'Date and time for sending the broadcast',
    startNow: 'Send now',
    startPlannedAtCustom: 'Set date and time',
    timeZone: 'Timezone for sending messages',
    timeZoneHint:
      'Your current timezone is {{timeZone}}. To schedule this broadcast in a different timezone,\nselect it in the list below. Broadcast results will be shown in your current timezone.',
    timePeriod: 'Timeframe when you want the clients to receive the message',
    timePeriodHint: 'The timeframe when it is allowed to send a broadcast',
    timePeriodOff: 'Do not set',
    timePeriodOn: 'Set a timeframe and ignore the clients’ local time',
    timePeriodOnHint:
      'We send the broadcast in the specified timeframe using timezone from your profile  {{timeZone}}',
    timePeriodOnWithTimezoneHint:
      'We send the broadcast in the specified timeframe using timezone {{timeZone}}',
    timePeriodOnByLocal: 'Set a timeframe and use the clients’ local time',
    timePeriodOnByLocalHint:
      'If we cannot determine the client local time, we will send the broadcast in the specified timeframe\nusing timezone from your profile {{timeZone}}',
    timePeriodOnByLocalWithTimezoneHint:
      'If we cannot determine the client local time, we will send the broadcast in the specified timeframe\nusing timezone {{timeZone}}',
    messageSpeed: 'Number of messages to be sent per second',
    flowIntegration:
      'Integration with trigger chains (Enable this if any of your trigger chains reacts to or is initiated by an outgoing message)',
    flowIntegrationHint:
      'Enable this if any of your trigger chains reacts to or is initiated by an outgoing message',
    comment: 'Additional info about the broadcast',
    commentHint:
      'Comment, meta data about the broadcast (optional). The comment you enter will be\npassed in the callback along with the message delivery status. Any symbols and\nvariables are allowed.',
  },
  contentForm: {
    title: 'Broadcast content and sequence for sending messages',
    cascade: 'Select a cascade based on which the broadcast will be created',
    cascadeRules: 'Rules of the selected cascade',
    contentTitle: 'Message contents by channel',
    attachDocumentLabel: 'Add a file',
    attachImageLabel: 'Add an image',
    attachDocument: 'Attach file',
    attachAnotherDocument: 'Attach another file',
  },
  testForm: {
    recipient: 'Test the broadcast',
    recipientPlaceholder: 'Add number to test',
    sendTest: 'Send a test message',
    sendMessage: 'Send message',
    byPhone: 'by phone: {{phone}}',
    pushRecipient: 'Test the push notification',
    pushRecipientHint: 'Select clients for testing and send them push notifications',
    sendPushTest: 'Send a test push notification',
  },
  smsForm: {
    findMatch: 'Check for template compliance',
    templateMatch: {
      found: 'The template is registered.',
      foundHint:
        'The entered text matches the registered template. The tariffs are cheaper for the following operators:',
      foundPartial: 'The template is partially registered.',
      foundPartialHint:
        "Messages that don't match the template will be charged as advertising by the following operators:",
      notFound: 'The template is not found in the system.',
      notFoundHint:
        "Messages that don't match the template are charged by operators as advertising.",
      otherHint:
        "For other operators, messages don't match the template and are charged as advertising",
    },
    phoneNumberHint:
      'If you try to send a broadcast to the phone numbers of different operators, you can see the cost of the messages in the final report. The broadcast is paid.',
    subject: 'Sender name',
    messageMatcherId: 'Select a template (optional)',
    text: 'Message',
    textHint: `You can address the client by name using the {name} tag in the message text. For example, "Hi {name}! Have a nice day". If the client's name is filled in the audience list, the message will look like this: "Hi Julia! Have a nice day". If the name is not filled in, the system skips the tag.`,
    operatorsApproved:
      'Messages for active operator template are cheaper. The template is active for the following operators:',
    operatorsPending:
      'After registration of the operator template, the tariffs for the following operators are cheaper (messages are charged as advertising before registration):',
    operatorsRejected:
      'Messages are charged as advertising after rejecting the operator template. The template is rejected for the following operators:',
  },
  viberForm: {
    accountName: 'Broadcast sender account',
    messageMatcherId: 'Select a template (optional)',
    text: 'Message',
    type: 'Content type',
    textHint: `You can address the client by name using the {name} tag in the message text. For example, "Hi {name}! Have a nice day". If the client's name is filled in the audience list, the message will look like this: "Hi Julia! Have a nice day". If the name is not filled in, the system skips the tag.`,
    imageLabel: 'JPEG, JPG, PNG image size of 400x400 px, up to 25 MB',
    buttonLabel: 'Button name',
    buttonLinkLabel: 'Button link',
    shouldCollectClicks: 'Track clicks on link buttons',
    shouldCollectClicksHint: 'The link in the message is replaced individually for each recipient',
    optional: ' (optional)',
  },
  notifyForm: {
    accountName: 'Broadcast sender account',
    messageMatcherId: 'Template name',
  },
  whatsAppForm: {
    accountName: 'Broadcast sender account',
    text: 'Message',
    messageMatcherId: 'Template',
    imageUrlHint: 'Supported image formats: JPEG, PNG. Maximum image size -1600x1200px, up to 2Mb',
    documentUrlHint:
      'Supported attachment formats: text files, PDF, Excel (.xls and .xlsx). Maximum file size is 100 MB',
    videoUrlHint: 'Supported video file formats (MP4). Maximum file size is 16 MB',
    buttonFields: {
      textLabel: 'Label',
      urlLabel: 'Link',
      urlHint: 'Only https URL is allowed',
      payloadLabel: 'Code',
      payloadHint: 'Use this code to track the button clicks via API',
      phoneLabel: 'Phone number',
      staticUrlLabel: 'Button URL (static part of the URL)',
      collectClicksLabel: 'Сlicks on link buttons are tracked',
      dynamicUrlLabel: 'Dynamic part of the URL',
      dynamicUrlHint:
        'For a dynamic part of the URL, enter its value without <b>/</b>. For example, <b>action234</b> (not /action234). To make the URL unique, use variables. You can only add variables to the end of the URL.',
    },
    buttonTypes: {
      URL: 'Link button',
    },
    hasHeader: 'Add header',
    headerType: 'Type',
    header: 'Content',
    addFooter: 'Add footer',
    addButtons: 'Add buttons',
    message:
      'In this template, you need to manually enter the dynamic part of your button URL (that is, add the missing part of the link and/or enter a variable)',
  },
  pushForm: {
    messageMatcherId: 'Select a template',
    header: 'Message header',
    headerHint:
      'The device displays the header in one line. Maximum length is approximately 50 characters (Android limitations)',
    text: 'Enter the message and variables',
    textHint: `You can address the client by name using the {name} tag in the message text. For example, "Hi {name}! Have a nice day". If the client's name is filled in the audience list, the message will look like this: "Hi Julia! Have a nice day". If the name is not filled in, the system skips the tag.`,
    pushImages: 'Images in push notifications',
    pushHeaderImage: 'Icon for push notifications',
    pushHeaderImageHint:
      'An icon (logo) that will be displayed in a collapsed push notification. The recommended aspect ratio is 1x1; no more than 1024x1024 in size',
    pushImage: 'Bigger image for push notifications',
    pushImageHint:
      'A bigger image that will be displayed in expanded push notifications. The recommended aspect ratio is 2x1 where the main visual mass remains when cropped to 1.79x1 and 2,5x1 (Android limitations)',
    buttonsAndActions: 'Buttons and actions',
    additionalParameters: 'Additional settings',
    key: 'Key',
    value: 'Value',
    addParameter: ' parameter',
    addPushAction: 'Add a push notification tap link',
    pushAction: 'Push notification link',
    pushActionHint:
      'A link that is transferred to the app when the client taps the push notification',
  },
  checkForm: {
    title: 'Check the broadcast details before sending',
    name: 'Broadcast name',
    startDateAndTime: 'Date and time of sending',
    sendSettings: 'Send settings',
    unknown: 'Unknown',
    notSet: 'Not set',
    date: 'Date',
    time: 'Time',
    startNow: 'Send now',
    broadcastDuration: 'Broadcast duration',
    timePeriod: 'Timeframe when the client will get the messages',
    countingRecipients: 'Counting in progress',
    numberOfRecipients: 'Number of recipients',
    uploadSubscribers: 'Uploading your audience. <0>Check the progress</0>',
    includedRecipients: 'Included recipients',
    excludedRecipients: 'Excluded recipients',
    messageSpeed: 'Message sending interval',
    previewTitle: 'Message preview',
    buttonLink: 'Button link',
    cascade: 'Broadcast cascade',
    additionalInfo: 'Additional info',
    comment: 'Comment, meta data about the broadcast',
  },
  audienceForm: {
    [EAudienceGroupState.included]: 'Include recipients with these tags',
    [EAudienceGroupState.excluded]: 'Exclude recipients with these tags',
    subscribers: 'recipients',
    tags: 'Available tags',
    tagsSearch: 'Search tags',
    emptyTags: {
      [EAudienceGroupState.included]: 'Add tags to include',
      [EAudienceGroupState.excluded]: 'Add tags to exclude',
    },
    group: 'Group',
    addGroup: 'Add another group',
    groupOperator: 'Operator inside the group',
    uploadSubtitle:
      'Uploading and updating your audience is going to take some time. \n' +
      "You don't have to stay on this page. The process will continue if you open another page.\n" +
      'Come back to <0>this page</0> to check the current upload status.',
  },
  sendConfirmation: {
    title: 'Send the broadcast?',
    message: 'Everything is checked and ready, you can send the broadcast',
  },
  planingConfirmation: {
    title: 'Schedule the broadcast?',
    message:
      'Everything is checked and ready, the system will send the broadcast at the scheduled time',
  },
  countdown: {
    title: 'Sending the broadcast',
    message1: 'The broadcast will be sent in 5 seconds.',
    message2:
      'You have 5 seconds to change your mind before the system sends the broadcast to the clients.',
  },
  sentAlert: {
    title: 'Your broadcast has been sent!',
    message1: 'Your clients will receive the broadcast within {{value}}',
    message2: 'For the broadcast status and information on it, see the broadcast page.',
  },
  selectTemplate: {
    title: 'Use template',
  },
  plannedAlert: {
    title: 'Your broadcast has been scheduled!',
    message1: 'Your clients will start receiving the broadcast on the day and time you specified',
    message2: 'For the broadcast status and information on it, see the broadcast page.',
  },
  plannedError: {
    title: 'Error in creating broadcast',
    message: `We found error when checking broadcast data.<br/>Please copy the error code below and send it to out support:`,
    copyErrorCode: 'Copy error code',
  },
  loadingAudienceModal: {
    title: 'Client list has not been uploaded yet',
    subtitle: 'You can send a broadcast after the process is complete',
  },
  validationError: {
    title: 'Error checking data',
    message: `When checking the broadcast data, we found an error in the settings,
    which prevents us from scheduling the broadcast`,
  },
  notification: {
    testSentSuccess: 'Test message sent successfully',
    returnToDraftsSuccess: 'Broadcast returned to drafts',
  },
  error: {
    failedToTest: 'Sorry, the broadcast could not be tested.',
    failedToCancel: 'Sorry, the broadcast could not be cancelled.',
  },
  and: 'And',
  or: 'Or',
  invalidDateAndTime: 'Check the date and time of sending the broadcast',
  invalidMessageSpeed: 'Check the message sending interval',
  hasInvalidRecipients: 'There is an error with the audience. Nobody will receive the broadcast',
  invalidContent: 'There is an error with the content of the selected channel',
  invalidEmptyCascade: 'No cascade selected',
  startsNow: 'Immediately after confirmation of sending',
  template: 'Template',
  balanceProblem: {
    title: 'You cannot send the broadcast',
    message1:
      'There is currently not enough funds on your account balance to send the broadcast. To be able to send broadcasts, top up your balance. Do not worry, this broadcast will be saved as a draft in the meantime.',
    message2:
      'There is currently not enough overdraft amount to send the broadcast. To resolve the issue with your balance and be able to send broadcasts, contact your manager. In the meantime, this broadcast will be saved as a draft.',
    topUp: 'Top up balance',
    writeManager: 'Contact manager',
  },
  filters: {
    searchPlaceholder: 'Quick search by broadcast name',
    broadcastStartedDate: 'Broadcast launch date',
    broadcastStatus: 'Broadcast status',
    channelType: 'Channel',
    broadcastName: 'Broadcast name',
    initiatorName: 'Broadcast initiator',
    cascadeName: 'Cascade name',
    channelName: 'Channel name',
    subscribersTags: 'Broadcast recipient tags',
    selectTags: 'Select tags',
    comment: 'Comment, meta data about the broadcast',
    trafficType: 'Traffic type',
    commentFilled: 'Filled',
    trafficHint: 'Advertising and service traffic types match the template used in the broadcast',
    commentHint:
      'The comment you enter will be passed in the callback along with the message delivery status. Any symbols and variables are allowed.',
    broadcasts: 'Broadcasts',
  },
};
