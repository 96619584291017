import {EDocumentType} from 'src/containers/Billing';

export default {
  th: {
    uploadedAt: 'Дата операции',
    document: 'Документ',
  },
  filters: {
    title: 'Фильтры',
    placeholder: 'Все типы документов',
    reset: 'Очистить',
  },
  button: {
    downloadItem: 'Скачать .pdf',
    uploadDocument: 'Загрузить документ',
  },
  uploadDocumentModal: {
    title: 'Загрузить документ',
    chooseTypeLabel: 'Выберите тип документа, который хотите загрузить',
    chooseTypePlaceholder: 'Выберите тип',
    timeAndDateLabel: 'Задайте отображаемое время загрузки документа',
    attachDocument: 'Прикрепить файл',
    attachAnotherDocument: 'Прикрепить другой файл',
  },
  documentTypes: {
    [EDocumentType.ALL]: 'Все документы',
    [EDocumentType.INVOICE]: 'Счет-фактура',
    [EDocumentType.ACCOUNTING_BILL]: 'Счет',
    [EDocumentType.ACCEPTANCE_ACT]: 'Акт',
    [EDocumentType.LICENSE_BILL]: 'Счет на оплату лицензии',
    [EDocumentType.PAYMENT_BILL]: 'Счет на оплату',
    [EDocumentType.OTHER]: 'Другое',
  },
  successUploadModal: {
    title: 'Документ успешно загружен',
    subtitle: 'Загруженный документ теперь виден в списке документов в Личном кабинете клиента',
  },
  title: 'Документы клиента',
};
