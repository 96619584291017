import React from 'react';
import {matchPath} from 'react-router-dom';

import PATHS, {AUTH_PATH_PREFIX} from 'src/paths';

type TReactLazyComponentType = React.LazyExoticComponent<React.ComponentType<TAnyObject>>;

type TSetFunction = (componentResult: TReactLazyComponentType) => void;
type TImportFunction = () => Promise<{default: React.ComponentType<any>}>; // eslint-disable-line @typescript-eslint/no-explicit-any

type TComponentsMap<K extends string> = {[P in K]: TReactLazyComponentType};
type TComponentImportsMap<K extends string> = {[P in K]: TImportFunction};

const componentLoader = (setFunction: TSetFunction, importFunction: TImportFunction) =>
  setFunction(
    React.lazy(() =>
      importFunction().catch((error) => {
        componentLoader(setFunction, importFunction);
        throw error;
      }),
    ),
  );

const bootstrapComponents = <K extends string>(componentImports: TComponentImportsMap<K>) => {
  const result = {} as TComponentsMap<K>;

  Object.entries<TImportFunction>(componentImports).forEach(
    ([componentName, componentImportFunction]) => {
      const setComponent = (component: TReactLazyComponentType) => {
        result[componentName as K] = component;
      };

      componentLoader(setComponent, componentImportFunction);
    },
  );

  return result;
};

const customLayoutPaths: string[] = [
  PATHS.TRIGGERS_EDIT,
  PATHS.TRIGGERS_VIEW,
  PATHS.TRIGGERS_NEW,
  PATHS.SUPPORT_TRIGGERS_VIEW,
];

const checkCustomLayout = (pathname: string) =>
  pathname.startsWith(AUTH_PATH_PREFIX) ||
  customLayoutPaths.some((path) => matchPath(path, pathname));

export {bootstrapComponents, checkCustomLayout};
