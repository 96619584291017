import {isAllOf} from '@reduxjs/toolkit';

import {and, buildQueryString} from '@edna/utils';

import {TData, TPayload} from 'src/components/List';
import {TItemId} from 'src/constants';
import {TDocumentFilters} from 'src/containers/Licenses/PaymentTab/definitions';
import {EPermission} from 'src/containers/User';
import {
  ECacheTag,
  isMatchQueryMeta,
  listenerMiddleware,
  rootApi,
  showErrorNotification,
} from 'src/models';

import {
  EDocumentType,
  TBalance,
  TBalanceModalFormData,
  TBalanceModalPayload,
  TDocument,
  TDocumentType,
  TOverdraftHistory,
} from './definitions';

export const prepareFilters = (payload: TAnyObject) => {
  const {period, types, isSupport, ...other} = payload;

  return {
    ...other,
    createdAtTo: period.stopDate,
    createdAtFrom: period.startDate,
    types: EDocumentType.ALL === types ? '' : types,
  };
};

const DEFAULT_HOURS = 4;

const prepareBalanceData = (payload: TBalanceModalFormData) => {
  const {createdAt, ...other} = payload;

  const convertedCreatedAt = new Date(
    createdAt.getFullYear(),
    createdAt.getMonth(),
    createdAt.getDate(),
    DEFAULT_HOURS,
  ).toISOString();

  return {
    ...other,
    createdAt: convertedCreatedAt,
  };
};

const balanceApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getBalance: builder.query<TBalance, TItemId | void>({
      query: (tenantId) => ({
        method: 'GET',
        url: tenantId === undefined ? '/billing/balance' : `/billing/tenant/${tenantId}/balance`,
        meta: {
          permissions:
            tenantId === undefined
              ? and<EPermission>('PERMISSION_BALANCE_READ')
              : and<EPermission>('PERMISSION_TENANT_BALANCE_READ'),
        },
      }),
      providesTags: (_, __, tenantId) =>
        tenantId ? [{type: ECacheTag.BALANCE, id: tenantId}] : [ECacheTag.BALANCE],
    }),
    topUpBalance: builder.mutation<TBalance, TBalanceModalPayload>({
      query: ({supportTenantId, ...data}) => ({
        method: 'POST',
        url: `/billing/tenant/${supportTenantId}/balance/add`,
        data: prepareBalanceData(data),
        meta: {
          isShowError: true,
          permissions: and<EPermission>('PERMISSION_TENANT_BALANCE_ADD'),
        },
      }),
      invalidatesTags: (_, __, data) => [{type: ECacheTag.BALANCE, id: data.supportTenantId}],
    }),
    withdrawBalance: builder.mutation<TBalance, TBalanceModalPayload>({
      query: ({supportTenantId, ...data}) => ({
        method: 'POST',
        url: `/billing/tenant/${supportTenantId}/balance/subtract`,
        data: prepareBalanceData(data),
        meta: {
          isShowError: true,
          permissions: and<EPermission>('PERMISSION_TENANT_BALANCE_SUBTRACT'),
        },
      }),
      invalidatesTags: (_, __, data) => [{type: ECacheTag.BALANCE, id: data.supportTenantId}],
    }),
    getOverdraftHistory: builder.query<TOverdraftHistory[], TItemId>({
      query: (supportTenantId) => ({
        method: 'GET',
        url: `/tenantmanagement/tenant/${supportTenantId}/overdraft-history`,
        meta: {isShowError: true},
      }),
      providesTags: (_, __, id) => [{id, type: ECacheTag.OVERDRAFT}],
    }),
    setMaxOverdraft: builder.mutation<void, {supportTenantId: TItemId; newMaxOverdraft: number}>({
      query: (data) => ({
        method: 'POST',
        url: `/billing/tenant/${data.supportTenantId}/balance/change_max_overdraft/${data.newMaxOverdraft}`,
        meta: {
          isShowError: true,
          isShowSuccess: true,
          successMessageKey: 'BalanceWidget:changeOverdraftModal.notification',
        },
      }),
      invalidatesTags: (_, __, data) => [
        {id: data.supportTenantId, type: ECacheTag.OVERDRAFT},
        {id: data.supportTenantId, type: ECacheTag.BALANCE},
      ],
    }),
    getDocuments: builder.query<TData<TDocument>, TPayload<TDocumentFilters>>({
      query: (data) => ({
        method: 'POST',
        url: data.isSupport
          ? `/billing/tenant/${data.tenantId}/document/list`
          : '/billing/document/list',
        meta: {
          isShowError: true,
          permissions: data.isSupport
            ? and<EPermission>('PERMISSION_TENANT_TRANSACTION_READ')
            : and<EPermission>('PERMISSION_TRANSACTION_READ'),
        },
        data: prepareFilters(data),
      }),
      providesTags: [ECacheTag.COMPANY_DOCUMENT],
    }),
    uploadDocument: builder.mutation<
      {
        id: number;
        type: TDocumentType;
        publicUrl: string;
        createdAt: string;
        uploadedAt: string;
      },
      {
        tenantId?: TItemId;
        documentType?: TDocumentType;
        uploadedAt?: Date;
        document: File | null;
      }
    >({
      query: (data) => {
        const formData = new FormData();

        if (data.document) {
          formData.append('document', data.document);
        }

        return {
          method: 'POST',
          url: `/billing/tenant/${data.tenantId}/document/upload${buildQueryString({
            uploadedAt: JSON.parse(JSON.stringify(data.uploadedAt)),
            documentType: data.documentType,
          })}`,
          data: formData,
          meta: {
            isShowError: true,
          },
        };
      },
      invalidatesTags: [ECacheTag.COMPANY_DOCUMENT],
    }),
  }),
});

listenerMiddleware.startListening({
  matcher: isAllOf(
    balanceApi.endpoints.getBalance.matchRejected,
    isMatchQueryMeta({isBackendError: true}),
  ),
  effect: (action) => {
    if (action.payload.status !== 404) {
      showErrorNotification(action.payload);
    }
  },
});

export const {
  useGetBalanceQuery,
  useTopUpBalanceMutation,
  useWithdrawBalanceMutation,
  useGetOverdraftHistoryQuery,
  useSetMaxOverdraftMutation,
  useGetDocumentsQuery,
  useUploadDocumentMutation,
} = balanceApi;
