import {PayloadAction, createSelector, createSlice} from '@reduxjs/toolkit';

import {storage} from '@edna/utils';

import {EStorageKeys, TItemId} from 'src/constants';
import {listenerMiddleware} from 'src/models';

type TNewsState = Record<TItemId, {isOpen: boolean}>;

const slice = createSlice({
  name: 'notifications',
  initialState: () => ({
    statuses: (storage.get<TNewsState>(EStorageKeys.NEWS) as TNewsState) ?? {},
  }),
  reducers: {
    closeNews(state, action: PayloadAction<TItemId>) {
      state.statuses[action.payload] = {isOpen: false};
    },
    resetAllNews() {
      return {
        statuses: {},
      };
    },
  },
});

listenerMiddleware.startListening({
  predicate: (_, currentState, previousState) => currentState !== previousState,
  effect: (_, listenerApi) => storage.set(EStorageKeys.NEWS, listenerApi.getState().news.statuses),
});

export const selectNewsOpen = (state: TRootState) => state.notifications.isNewNotificationsReceived;

const newsSelect = (state: TRootState) => state;

export const selectIsNewsOpenById = (id: TItemId) => {
  return createSelector(newsSelect, (state) => state.news.statuses[id]?.isOpen ?? true);
};
export const {closeNews, resetAllNews} = slice.actions;

export default slice.reducer;
