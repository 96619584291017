import {
  EDeliveryStatus,
  EDirection,
  EMessageSource,
  ETrafficType,
  IncomingMessageStatus,
} from './definitions';

export default {
  filter: {
    period: 'Time interval',
    phone: "Search by client's phone number",
    channelType: 'Channel',
    deliveryStatus: 'Message status',
    broadcasts: 'Broadcast name',
    cascades: 'Cascade name',
    trafficType: 'Traffic type',
    direction: 'Traffic direction',
    subjects: 'Sender channel',
    initiator: 'Broadcast initiator',
    source: 'Message sending source',
    actions: 'Message interaction',
    actionsHint:
      'The filter only applies to Viber and WhatsApp messages with the "Link button" content type and the "Click on link button" parameter. This parameter is displayed for messages if the "Track clicks on link buttons" setting is selected when creating the broadcast, or a template with this setting is selected.',
    isClicked: 'Click on link button',
  },
  searchMessages: 'Search messages',
  messagesByCriteria: 'Messages by criteria – {{count, number}}',
  button: {
    downloadList: 'Download list',
  },
  list: {
    head: {
      sendDateTime: 'Date and time when\nthe message was sent',
      deliveryStatus: 'Message\nstatus',
      recipient: 'Recipient',
      sender: 'Sender',
      traffic: 'Traffic',
      inout: 'IN/OUT',
      content: 'Content',
      source: 'Source',
      sourceHint: '',
    },
    loading: 'loading the list',
  },
  sourceHint: {
    [EMessageSource.FLOW]: 'Scenario — "edna Pulse scenarios" function',
    [EMessageSource.IMHPX]: 'Chat Center — personal edna Chat Center account',
    [EMessageSource.JIVO]: 'Jivo — Jivo platform',
    [EMessageSource.BROADCAST]: 'Personal account — personal edna Pulse account',
    [EMessageSource.JSON_API]: 'API — API edna Pulse',
    [EMessageSource.INTERNAL_API]: 'Test — "Test broadcast" function',
    [EMessageSource.BROADCAST_TEST_MESSAGE]: 'Test — "Test broadcast" function',
  },
  source: {
    [EMessageSource.FLOW]: 'Scenario',
    [EMessageSource.IMHPX]: 'Chat Center',
    [EMessageSource.JIVO]: 'Jivo',
    [EMessageSource.BROADCAST]: 'Personal account',
    [EMessageSource.JSON_API]: 'API',
    [EMessageSource.INTERNAL_API]: 'Test',
    [EMessageSource.BROADCAST_TEST_MESSAGE]: 'Test',
  },
  status: {
    [EDeliveryStatus.ENQUEUED]: 'Queued',
    [EDeliveryStatus.SENT]: 'Sent',
    [EDeliveryStatus.FAILED]: 'Failed to send',
    [EDeliveryStatus.ACCEPTED]: 'Failed to send',
    [EDeliveryStatus.INVALID]: 'Failed to send',
    [EDeliveryStatus.DELIVERED]: 'Delivered',
    [EDeliveryStatus.CANCELLED]: 'Canceled',
    [EDeliveryStatus.UNDELIVERED]: 'Not delivered',
    [EDeliveryStatus.READ]: 'Read',
    [EDeliveryStatus.EXPIRED]: 'Expired',
    [EDeliveryStatus.BLOCKED]: 'Blocked',
    [EDeliveryStatus.SUSPENDED]: 'Suspended',
    [IncomingMessageStatus.RECEIVED]: 'Received',
  },
  trafficType: {
    [ETrafficType.AD]: 'Advertising',
    [ETrafficType.MULTINAMING]: 'Advertising',
    [ETrafficType.SERVICE]: 'Service',
    [ETrafficType.HSM]: 'HSM',
    [ETrafficType.CHAT]: 'Chat',
  },
  trafficTypeShort: {
    [ETrafficType.AD]: 'Adv',
    [ETrafficType.SERVICE]: 'Serv',
    [ETrafficType.HSM]: 'HSM',
    [ETrafficType.CHAT]: 'Chat',
  },
  direction: {
    [EDirection.IN]: 'Incoming',
    [EDirection.OUT]: 'Outgoing',
  },
  directionShort: {
    [EDirection.IN]: 'IN',
    [EDirection.OUT]: 'OUT',
  },
  content: {
    headerText: 'Header',
    operator: 'Receiver operator',
    text: 'Text',
    footerText: 'Footer',
    securityAdvice: 'Security disclaimer',
    codeExpirationMinutes: '<0>Code expiration interval (minutes):</0> {{minutes}}',
    image: 'Image',
    video: 'Video',
    audio: 'Audio',
    document: 'File',
    location: 'Location',
    name: 'Name',
    link: 'Link',
    links: 'Links',
    buttonText: 'Button text',
    value: 'Value',
    coordinates: 'Coordinates',
    error: 'Error',
    broadcast: 'Broadcast',
    cascade: 'Cascade',
    segments: 'Segments',
    story: 'Story',
    menu: 'Menu',
    buttons: 'Buttons',
    contact: 'Contact',
    firstName: 'Name',
    lastName: 'Last Name',
    phone: 'Phone',
    subtitle: 'Subtitle',
    sender: 'Sender',
    recipientUID: 'Recipient UID',
    deviceAddress: 'Recipient deviceAddress',
    deviceModel: 'Device model',
    referral: 'Referral',
    locationButtonText: 'Location',
    linkClick: 'Click on link button',
    clickedLinkHint: 'Redirect URL',
    hasLinkClick: 'Clicked',
    noLinkClick: 'Not clicked',
    initiator: 'Initiator',
  },
  noDataWithFilters: {
    title: "Sorry, we didn't find anything",
    text: 'There are no messages for the selected filters',
    hint: 'Check for a mistake somewhere in the settings and verify you set the correct time interval',
    button: 'Try again',
  },
  noData: {
    title: 'Nothing here yet',
    text: 'After you start your first broadcast, the data on the sent messages will appear here. Would you like to create a broadcast?',
    button: 'To broadcasts',
  },
  referral: {
    body: 'Body',
    headline: 'Headline',
    sourceId: 'SourceID',
    sourceType: 'SourceType',
    sourceUrl: 'SourceURL',
  },
  orderProduct: {
    product: 'Message Business',
    catalogId: 'catalog_id:',
    productId: 'product_retailer_id:',
    order: 'Placing an Order',
  },
  reportGeneration: {
    title: 'Criteria report',
    description:
      'Generate and download your own message report according to the specified criteria',
    period: 'Select the period',
    phone: 'Phone number',
    generate: 'Generate report',
  },
  existReportModal: {
    title: 'Generating the report',
    description:
      'A report with such criteria is already being generated. See the result in the <0>"Event log" → "Downloads"</0>.',
  },
  emptyReportModal: {
    title: 'No data',
    description:
      'Unable to generate the report according to the specified criteria. Try changing the criteria',
  },
  reportGenerationInfo: 'The report {{name}} is being generated. <0>View the result</0>',
};
