/* eslint-disable func-style */
import React from 'react';
import {useTranslation} from 'react-i18next';

import {showNewsModal, useNewsState} from 'src/containers/News';
import {useGetWhatsAppTariffChangeBannerDatesQuery} from 'src/containers/User/api';
import {TariffImage} from 'src/images';
import {isDateBetween} from 'src/utils';

import {WhatsAppLicensePriceUpdatePlannedContent} from './SystemNews';
import {usePollNotificationsQuery} from './api';
import {
  ENotificationLevel,
  ENotificationStatus,
  ESystemNewsType,
  ESystemNotificationEntityType,
  ESystemNotificationType,
  EWebNotificationType,
  TSystemNews,
  TSystemNotification,
  TWebNotification,
} from './model';
import {TWhatsAppLicensPriceNews} from './model/systemNews';

type TConfig<T> = {
  pollingInterval?: number;
  skip?: boolean;
  type: T | T[];
};

type TWebConfig = TConfig<keyof typeof EWebNotificationType>;

function useWebNotifications<T extends TWebNotification>(config: TWebConfig): T[];

function useWebNotifications(config?: Omit<TWebConfig, 'type'>): TWebNotification[];

function useWebNotifications<T extends TWebNotification>(
  config: Partial<TWebConfig> = {},
): TWebNotification[] | T[] {
  const {data} = usePollNotificationsQuery(undefined, {
    pollingInterval: config.pollingInterval,
    skip: config.skip,
  });

  if (!data) {
    return [];
  }

  if (config.type) {
    const types = Array.isArray(config.type) ? config.type : [config.type];

    return data.webNotifications.filter((notification): notification is T =>
      types.includes(notification.type),
    );
  }

  return data.webNotifications;
}

type TSystemConfig = TConfig<keyof typeof ESystemNotificationType>;

function useSystemNotifications<T extends TSystemNotification>(config: TSystemConfig): T[];

function useSystemNotifications(config?: Omit<TSystemConfig, 'type'>): TSystemNotification[];

function useSystemNotifications<T extends TSystemNotification>(
  config: Partial<TSystemConfig> = {},
): TSystemNotification[] | T[] {
  const {data} = usePollNotificationsQuery(undefined, {
    pollingInterval: config.pollingInterval,
    skip: config.skip,
  });

  if (!data) {
    return [];
  }

  const systemNotifications =
    data.systemNotifications?.filter(
      (notification): notification is TSystemNotification =>
        notification.entityType === ESystemNotificationEntityType.NOTIFICATION,
    ) ?? [];

  if (config.type) {
    const types = Array.isArray(config.type) ? config.type : [config.type];

    return systemNotifications.filter((notification): notification is T =>
      types.includes(notification.type),
    );
  }

  return systemNotifications;
}

type TSystemNewsConfig = TConfig<keyof typeof ESystemNewsType>;

const EDNA_WA_TARIFF_CHANGE_NEWS_01_04_2025 = 'EDNA_WA_TARIFF_CHANGE_NEWS_01_04_2025';
const EDNA_WA_TARIFF_CHANGE_MODAL_01_04_2025 = 'EDNA_WA_TARIFF_CHANGE_MODAL_01_04_2025';

function useSystemNews<T extends TSystemNews>(config: TSystemNewsConfig): T[];

function useSystemNews(config?: Omit<TSystemNewsConfig, 'type'>): TSystemNews[];

function useSystemNews<T extends TSystemNews>(
  config: Partial<TSystemNewsConfig> = {},
): TSystemNews[] | T[] {
  const {t} = useTranslation();
  const {data} = usePollNotificationsQuery(undefined, {
    pollingInterval: config.pollingInterval,
    skip: config.skip,
  });
  const {data: bannerDates} = useGetWhatsAppTariffChangeBannerDatesQuery();

  const licensePriceUpdateNews = React.useMemo(() => {
    if (bannerDates && isDateBetween(bannerDates.startDate, bannerDates.endDate)) {
      return {
        type: ESystemNewsType.WhatsAppLicensePriceUpdatePlanned,
        entityType: ESystemNotificationEntityType.NEWS,
        id: EDNA_WA_TARIFF_CHANGE_NEWS_01_04_2025,
        status: ENotificationStatus.UNREAD,
        level: ENotificationLevel.WARNING,
        occurredAt: '',
        meta: {},
      } as TWhatsAppLicensPriceNews;
    }

    return undefined;
  }, [bannerDates]);

  const systemNotifications = React.useMemo(() => {
    if (licensePriceUpdateNews) {
      return [...(data?.systemNotifications ?? []), licensePriceUpdateNews];
    }

    return data?.systemNotifications;
  }, [data, licensePriceUpdateNews]);

  const {isNewsOpen: isModalOpen, closeNews: closeModal} = useNewsState(
    EDNA_WA_TARIFF_CHANGE_MODAL_01_04_2025,
  );

  React.useEffect(() => {
    if (!isModalOpen || !licensePriceUpdateNews) {
      return;
    }

    showNewsModal({
      onClose: closeModal,
      image: TariffImage,
      title: t(`Notifications:news.${ESystemNewsType.WhatsAppLicensePriceUpdatePlanned}.title`),
      children: <WhatsAppLicensePriceUpdatePlannedContent item={licensePriceUpdateNews} />,
    });
  }, [isModalOpen, licensePriceUpdateNews]);

  if (!systemNotifications) {
    return [];
  }

  const news =
    systemNotifications?.filter(
      (notification): notification is TSystemNews =>
        notification.entityType === ESystemNotificationEntityType.NEWS,
    ) ?? [];

  if (config.type) {
    const types = Array.isArray(config.type) ? config.type : [config.type];

    return news.filter((newsItem) => types.includes(newsItem.type));
  }

  return news;
}

export {useSystemNews, useWebNotifications, useSystemNotifications};
/* eslint-enable func-style */
