import styled, {css} from 'styled-components';

import {Button as BaseButton, Button} from '@edna/components';
import {mixins} from '@edna/components/styles';

export const Form = styled.form`
  ${({theme}) => css`
    padding: ${theme.spacing.s4};
  `}
`;

export const FilterFormContent = styled.div`
  display: flex;
  gap: ${({theme}) => theme.spacing.s6};
`;

export const LeftButton = styled(BaseButton)`
  display: flex;
  gap: ${({theme}) => theme.spacing.s1};
  justify-content: center;
  margin-right: auto;
`;

export const FormFooter = styled.div`
  display: flex;
  gap: ${({theme}) => theme.spacing.s3};
  justify-content: space-between;
`;

export const FiltersWrapper = styled.div`
  ${mixins.paper}
  ${({
    theme: {
      palette,
      spacing,
      animation: {duration},
      borderRadius,
      zIndex,
    },
  }) => css`
    position: sticky;
    top: 68px;
    z-index: ${zIndex.dropdown};
    margin-bottom: ${spacing.s2};
    padding: ${spacing.s4};
    transition: top ${duration}ms;
    border: 1px solid ${palette.grey100};
    border-radius: ${borderRadius.br2};
  `}
`;

export const FilterButton = styled(Button)`
  position: relative;
  ${mixins.flexAlign('center')}
  ${({theme}) => css`
    gap: ${theme.spacing.s1};
  `}
`;
