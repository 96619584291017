import {EAutopaymentError} from 'src/containers/Autopayment';
import {EApproveStatus} from 'src/containers/MessageMatchers/definitions';
import {
  EAutopaymentNotificationStatus,
  ENotificationLevel,
  EWebNotificationSection,
  EWebNotificationType,
} from 'src/containers/Notifications';

import {ECategory} from './definitions';

export default {
  today: 'Сегодня',
  yesterday: 'Вчера',
  previously: 'Ранее',
  filters: {
    levels: 'Статус',
    uploadCategory: 'Тип загрузки',
    notificationCategory: 'Тип уведомления',
    broadcasts: 'Название рассылки',
    initiator: 'Инициатор загрузки',
  },
  level: {
    [ENotificationLevel.SUCCESS]: 'Успешно',
    [ENotificationLevel.WARNING]: 'Требуется внимание',
    [ENotificationLevel.ERROR]: 'Ошибка',
    [ENotificationLevel.LOADING]: 'Загрузка',
  },
  category: {
    [ECategory.SUBSCRIBERS_UPLOAD]: 'Список аудитории',
    [ECategory.BROADCAST_LIFECYCLE]: 'Статус рассылки',
    [ECategory.LICENSE]: 'Лицензия',
    [ECategory.CALLBACK_LIFECYCLE]: 'Callback',
    [ECategory.APIKEY_USER_LIFECYCLE]: 'API-ключ',
    [ECategory.TENANT_DETAILS_UPDATES]: 'Реквизиты',
    [ECategory.TARIFF_ACCEPTANCE]: 'Тариф принят',
    [ECategory.TARIFF_CHANGE]: 'Тариф изменен',
    [ECategory.TARIFF_REJECTION]: 'Тариф отклонен',
    [ECategory.MESSAGE_REPORT]: 'Отчет по сообщениям',
  },
  tabs: {
    [EWebNotificationSection.NOTIFICATIONS]: 'Уведомления',
    [EWebNotificationSection.UPLOADS]: 'Загрузки',
  },
  message: {
    [EWebNotificationType.SubscribersUploadLoading]:
      'Загружаем список аудитории {{fileName}}. <0>Посмотреть прогресс</0>',
    [EWebNotificationType.SubscribersUploadFailed]:
      'Загрузка списка аудитории была прервана. Список клиентов базы {{fileName}}> не загружен',
    [EWebNotificationType.SubscribersUploadPartial]:
      'Список аудитории {{fileName}} загружен частично. <0>Посмотреть результат</0>',
    [EWebNotificationType.SubscribersUploadSuccess]: `Список аудитории {{fileName}} загружен успешно. <0>Посмотреть результат</0>`,
    [EWebNotificationType.BroadcastCompleted]: `Рассылка "{{name}}" завершена. Перейти в <0>отчет о рассылке</0>`,
    [EWebNotificationType.BroadcastCancelled]: `Рассылка "{{name}}" остановлена. Перейти в <0>отчет о рассылке</0>`,
    [EWebNotificationType.LicenseExpiring]:
      'Ваша лицензия в Системе edna Pulse истекает {{date}}. Чтобы продлить действие лицензии, обратитесь, пожалуйста, к вашему персональному менеджеру: {{name}} (<0>{{email}}</0>)',
    [EWebNotificationType.CallbackUpdated]: `Callback URL успешно изменен. Перейти в <0>раздел API-ключ и callbacks</0>`,
    [EWebNotificationType.ApikeyUserUpdated]: `API-ключ успешно изменен. Перейти в <0>раздел API-ключ и callbacks</0>`,
    [EWebNotificationType.CompanyDetailsUpdated]: `Пользователь "{{login}}" поменял реквизиты компании. Перейти в <0>реквизиты</0>`,
    [EWebNotificationType.PaymentDetailsUpdated]: `Пользователь "{{login}}" поменял платежные реквизиты компании. Перейти в <0>реквизиты</0>`,
    [EWebNotificationType.TenantTariffAccepted]: `Пользователь "{{login}}" согласился с доступными тарифами. Перейти в <0>тарифы</0>`,
    [EWebNotificationType.AgreedChangeTariffRequest]: `Пользователь "{{login}}" принял изменения по тарифу <b>{{tariffName}}</b>. Перейти в <0>тарифы</0>`,
    [EWebNotificationType.NotAgreedChangeTariffRequest]: `Пользователь "{{login}}" отклонил изменения по тарифу <b>{{tariffName}}</b>. Перейти в <0>тарифы</0>`,
    [EWebNotificationType.JivoEnabled]:
      'Пользователь {{login}} подключил весь трафик канала WhatsApp к чат-платформе Jivo',
    [EWebNotificationType.JivoDisabled]:
      'Пользователь {{login}} отключил весь трафик канала WhatsApp от чат-платформы Jivo',
    [EWebNotificationType.MessageMatcherStatusChanged]: {
      [EApproveStatus.DISABLED]:
        'Шаблон "{{name}}" был отключен. Все запланированные рассылки, в которых используется данный шаблон, будут остановлены.',
      [EApproveStatus.PAUSED]:
        'Шаблон "{{name}}" был остановлен. Все запланированные рассылки, в которых используется данный шаблон, будут остановлены.',
      [EApproveStatus.APPROVED]: 'У шаблона "{{name}}" изменился статус. Новый статус: Активный.',
      [EApproveStatus.REJECTED]: 'У шаблона "{{name}}" изменился статус. Новый статус: Отклонен.',
      [EApproveStatus.PENDING]: 'У шаблона "{{name}}" изменился статус. Новый статус: Регистрация.',
      [EApproveStatus.NOT_SENT]: 'У шаблона "{{name}}" изменился статус. Новый статус: Черновик.',
      [EApproveStatus.ARCHIVED]: 'У шаблона "{{name}}" изменился статус. Новый статус: В архиве.',
    },
    [EWebNotificationType.MessageMatcherCategoryChanged]: `Категория шаблона WhatsApp "{{name}}" была изменена на стороне WhatsApp. Новая категория шаблона: "{{newCategory}}".`,
    [EWebNotificationType.InvoicePaid]: 'Успех! Баланс пополнен.',
    [EWebNotificationType.ChannelProfilePartnerProfileCreated]:
      'Выберите тариф канала для WhatsApp-аккаунта "{{name}}", чтобы завершить регистрацию.',
    [EWebNotificationType.ChannelProfileQualityScoreChanged]:
      'WhatsApp Business API {{name}}: изменилось качество. Новое качество: {{qualityScore}}.',
    [EWebNotificationType.ChannelProfileMessagingLimitChanged]:
      'WhatsApp Business API {{name}}: изменился лимит. Новый лимит: {{messagingLimit}}.',
    [EWebNotificationType.ChannelProfileQualityStatusChanged]:
      'У WhatsApp-аккаунта {{name}} изменился статус. Новый статус: {{qualityStatus}}.',
    [EWebNotificationType.ChannelProfileWABAViolation]:
      'Бизнес-аккаунт WhatsApp {{wabaId}} нарушает правила или политику использования WhatsApp. Причина — {{violationType}}. Есть риск ограничения или отключения следующих каналов: {{channels}}. Рекомендуем <0>следовать рекомендациям</0>.',
    [EWebNotificationType.ChannelProfileWABARestriction]:
      'Бизнес-аккаунт WhatsApp {{wabaId}} ограничен из-за нарушения правил или политики использования WhatsApp. Следующие каналы ограничены до {{expirationDate}} и могут только принимать входящие сообщения: {{channels}}. Чтобы подать апелляцию, <0>следуйте рекомендациям</0>.',
    [EWebNotificationType.ChannelProfileWABADisable]:
      'Бизнес-аккаунт WhatsApp {{wabaId}} отключен из-за нарушения правил или политики использования WhatsApp. Работа следующих каналов остановлена: {{channels}}. Регистрация новых каналов в этом бизнес-аккаунте WhatsApp недоступна. Чтобы подать апелляцию, <0>следуйте рекомендациям</0>.',
    [EWebNotificationType.ChannelProfileWABAReinstate]:
      'Бизнес-аккаунт WhatsApp {{wabaId}} восстановлен. Следующие каналы снова активны: {{channels}}.',
    [EWebNotificationType.StripeCheckout]: {
      [EAutopaymentError.GENERIC_DECLINE]:
        'Не удалось выполнить автоплатеж {{date}}. Для получения дополнительной информации обратитесь в службу технической поддержки <0>{{email}}</0>.',
      [EAutopaymentError.AUTHENTICATION_REQUIRED]:
        'Не удалось выполнить автоплатеж {{date}}. Ваш банк запросил подтверждение платежа.',
      [EAutopaymentError.CARD_DECLINE]:
        'Не удалось выполнить автоплатеж с помощью привязанной карты.',
      [EAutopaymentError.INSUFFICIENT_FUNDS]:
        'Не удалось выполнить списание {{date}}. Недостаточно средств на карте.',
      [EAutopaymentNotificationStatus.AUTO_REPLENISHMENT_ENABLED]: 'Автоплатеж подключен.',
      [EAutopaymentNotificationStatus.AUTO_REPLENISHMENT_DISABLED]: 'Автоплатеж отключен.',
      [EAutopaymentNotificationStatus.PAYMENT_SUCCEEDED]:
        'Автоплатеж подключен. Для получения чека об оплате перейдите по ссылке на <0>сайт платежной системы Stripe</0>.',
    },
    [EWebNotificationType.MessageReportLoading]: 'Отчет {{name}} формируется',
    [EWebNotificationType.MessageReportSuccess]: 'Отчет {{name}} сформирован. <0>Скачать</0>',
    [EWebNotificationType.MessageReportFailed]: 'Формирование отчета {{name}} прервано',
  },
  notFound: {
    title: 'Увы, мы ничего не нашли',
    button: 'Попробовать еще раз',
    [EWebNotificationSection.NOTIFICATIONS]: 'Уведомлений по выбранным фильтрам нет',
    [EWebNotificationSection.UPLOADS]: 'Загрузок по выбранным фильтрам нет',
  },
  noData: {
    title: 'Здесь пока пусто',
  },
};
