import {EAutopaymentError} from '../Autopayment';
import {EApproveStatus} from '../MessageMatchers/definitions';
import {
  EAutopaymentNotificationStatus,
  ESystemNewsType,
  ESystemNotificationType,
  EWebNotificationType,
} from './model';

export default {
  button: {
    downloadErrorFile: 'Скачать файл с ошибкой',
    toAudienceSection: 'В раздел аудитории',
    contactTechnicalSupport: 'Свяжитесь с техподдержкой',
    toReportBroadcast: 'В отчет рассылки',
  },
  news: {
    [ESystemNewsType.UpdatePlanned]: {
      title: 'Обновление {{date}}',
      content:
        '<0></0> будет обновление edna Pulse. Мы рекомендуем не планировать и не отправлять рассылки с {{startTime}} до {{endTime}}, так как в работе программы возможны кратковременные перебои.\n' +
        'Мы стараемся быть лучше для вас! Надеемся, вам понравится обновление.',
    },
    [ESystemNewsType.WhatsAppLicensePriceUpdatePlanned]: {
      title: 'Изменение стоимости лицензии WhatsApp',
      content:
        'С <0>1 апреля</0> повышается стоимость вашей лицензии WhatsApp Business API. С новой стоимостью вы можете ознакомиться в разделе <1>Профиль -> Профиль Компании -> Тарифы</1>. Учитывайте это при оплате лицензии за апрель, чтобы продолжать пользоваться сервисом.' +
        '<br/><br/>Мы не повышали цены на WhatsApp Business API почти 2 года, но больше не можем бороться с возросшими затратами. В связи со значительным ростом расходов на закупку нового инфраструктурного оборудования, поддержку текущего оборудования, оплату высококвалифицированных ИТ-специалистов и существующей инфляцией на другие компоненты нашей работы, мы вынуждены поднять стоимость лицензии. Кроме этого на платформе edna были проведены глобальные доработки, которые позволят работать с сервисом ещё лучше.' +
        '<br/>При возникновение вопросов обратитесь к вашему менеджеру edna.',
    },
  },
  system: {
    [ESystemNotificationType.UpdateRunning]:
      '{{date}} с {{startTime}} до {{endTime}} <0>мы обновляем edna Pulse</0>. В это время возможны кратковременные перебои в работе системы.',
    [ESystemNotificationType.MaintenanceRunning]:
      'В работе edna Pulse наблюдаются кратковременные перебои. Мы уже работаем над их устранением!',
  },
  toUploads: 'В загрузки',
  toNotifications: 'В уведомления',
  web: {
    [EWebNotificationType.SubscribersUploadFailed]: `Список аудитории не загружен.`,
    [EWebNotificationType.SubscribersUploadPartial]: `Список аудитории загружен частично.`,
    [EWebNotificationType.SubscribersUploadSuccess]: `Список аудитории загружен.`,
    [EWebNotificationType.BroadcastCompleted]: `Рассылка "{{name}}" завершена.`,
    [EWebNotificationType.BroadcastCancelled]: `Рассылка "{{name}}" остановлена.`,
    [EWebNotificationType.LicenseExpiring]:
      'Ваша лицензия в Системе edna Pulse истекает {{date}}. Чтобы продлить действие лицензии, обратитесь, пожалуйста, к вашему персональному менеджеру: {{name}} (<0>{{email}}</0>)',
    [EWebNotificationType.CallbackUpdated]: `Callback URL успешно изменен.`,
    [EWebNotificationType.ApikeyUserUpdated]: `API-ключ успешно изменен.`,
    [EWebNotificationType.CompanyDetailsUpdated]: `Пользователь "{{login}}" поменял реквизиты компании.`,
    [EWebNotificationType.PaymentDetailsUpdated]: `Пользователь "{{login}}" поменял платежные реквизиты компании.`,
    [EWebNotificationType.TenantTariffAccepted]: `Пользователь "{{login}}" согласился с доступными тарифами.`,
    [EWebNotificationType.AgreedChangeTariffRequest]: `Пользователь "{{login}}" принял изменения по тарифу <b>{{tariffName}}</b>.`,
    [EWebNotificationType.NotAgreedChangeTariffRequest]: `Пользователь "{{login}}" отклонил изменения по тарифу <b>{{tariffName}}</b>.`,
    [EWebNotificationType.JivoEnabled]:
      'Пользователь "{{login}}" подключил весь трафик канала WhatsApp к чат-платформе Jivo.',
    [EWebNotificationType.JivoDisabled]:
      'Пользователь "{{login}}" отключил весь трафик канала WhatsApp от чат-платформы Jivo.',
    [EWebNotificationType.MessageMatcherStatusChanged]: {
      [EApproveStatus.DISABLED]:
        'Шаблон "{{name}}" был отключен. Все запланированные рассылки, в которых используется данный шаблон, будут остановлены.',
      [EApproveStatus.PAUSED]:
        'Шаблон "{{name}}" был остановлен. Все запланированные рассылки, в которых используется данный шаблон, будут остановлены.',
      [EApproveStatus.APPROVED]: 'У шаблона "{{name}}" изменился статус. Новый статус: Активный.',
      [EApproveStatus.REJECTED]: 'У шаблона "{{name}}" изменился статус. Новый статус: Отклонен.',
      [EApproveStatus.PENDING]: 'У шаблона "{{name}}" изменился статус. Новый статус: Регистрация.',
      [EApproveStatus.NOT_SENT]: 'У шаблона "{{name}}" изменился статус. Новый статус: Черновик.',
      [EApproveStatus.ARCHIVED]: 'У шаблона "{{name}}" изменился статус. Новый статус: В архиве.',
    },
    [EWebNotificationType.MessageMatcherCategoryChanged]: `Категория шаблона WhatsApp "{{name}}" была изменена на стороне WhatsApp. Новая категория шаблона: "{{newCategory}}".`,
    [EWebNotificationType.InvoicePaid]: 'Успех! Баланс пополнен.',
    [EWebNotificationType.ChannelProfilePartnerProfileCreated]:
      'Выберите тариф канала для WhatsApp-аккаунта "{{name}}", чтобы завершить регистрацию.',
    [EWebNotificationType.ChannelProfileQualityScoreChanged]:
      'WhatsApp Business API {{name}}: изменилось качество. Новое качество: {{qualityScore}}.',
    [EWebNotificationType.ChannelProfileMessagingLimitChanged]:
      'WhatsApp Business API {{name}}: изменился лимит. Новый лимит: {{messagingLimit}}.',
    [EWebNotificationType.ChannelProfileQualityStatusChanged]:
      'У WhatsApp-аккаунта {{name}} изменился статус. Новый статус: {{qualityStatus}}.',
    [EWebNotificationType.ChannelProfileWABAViolation]:
      'Бизнес-аккаунт WhatsApp {{wabaId}} нарушает правила или политику использования WhatsApp. Причина — {{violationType}}. Есть риск ограничения или отключения следующих каналов: {{channels}}. Рекомендуем <0>следовать рекомендациям</0>.',
    [EWebNotificationType.ChannelProfileWABARestriction]:
      'Бизнес-аккаунт WhatsApp {{wabaId}} ограничен из-за нарушения правил или политики использования WhatsApp. Следующие каналы ограничены до {{expirationDate}} и могут только принимать входящие сообщения: {{channels}}. Чтобы подать апелляцию, <0>следуйте рекомендациям</0>.',
    [EWebNotificationType.ChannelProfileWABADisable]:
      'Бизнес-аккаунт WhatsApp {{wabaId}} отключен из-за нарушения правил или политики использования WhatsApp. Работа следующих каналов остановлена: {{channels}}. Регистрация новых каналов в этом бизнес-аккаунте WhatsApp недоступна. Чтобы подать апелляцию, <0>следуйте рекомендациям</0>.',
    [EWebNotificationType.ChannelProfileWABAReinstate]:
      'Бизнес-аккаунт WhatsApp {{wabaId}} восстановлен. Следующие каналы снова активны: {{channels}}.',
    [EWebNotificationType.StripeCheckout]: {
      [EAutopaymentError.GENERIC_DECLINE]:
        'Не удалось выполнить автоплатеж {{date}}. Для получения дополнительной информации обратитесь в службу технической поддержки <0>{{email}}</0>.',
      [EAutopaymentError.AUTHENTICATION_REQUIRED]:
        'Не удалось выполнить автоплатеж {{date}}. Ваш банк запросил подтверждение платежа.',
      [EAutopaymentError.CARD_DECLINE]:
        'Не удалось выполнить автоплатеж с помощью привязанной карты.',
      [EAutopaymentError.INSUFFICIENT_FUNDS]:
        'Не удалось выполнить списание {{date}}. Недостаточно средств на карте.',
      [EAutopaymentNotificationStatus.AUTO_REPLENISHMENT_ENABLED]: 'Автоплатеж подключен.',
      [EAutopaymentNotificationStatus.AUTO_REPLENISHMENT_DISABLED]: 'Автоплатеж отключен.',
      [EAutopaymentNotificationStatus.PAYMENT_SUCCEEDED]:
        'Автоплатеж подключен. Для получения чека об оплате перейдите по ссылке на <0>сайт платежной системы Stripe</0>.',
    },
    [EWebNotificationType.MessageReportSuccess]: 'Отчет {{name}} сформирован.',
    [EWebNotificationType.MessageReportFailed]: 'Формирование отчета {{name}} прервано.',
  },
};
