/* eslint-disable @typescript-eslint/no-var-requires */
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import * as Sentry from '@sentry/react';

import accountStatementSlice from 'src/containers/AccountStatement/slice';
import appSlice from 'src/containers/App/slice';
import broadcastsSlice from 'src/containers/Broadcasts/slice';
import cascadesSlice from 'src/containers/Cascades/slice';
import channelsSlice from 'src/containers/Channels/slice';
import leadClientsSlice from 'src/containers/LeadCompanyClients/slice';
import whatsAppMessageMatcherSlice from 'src/containers/MessageMatchers/WhatsAppForm/slice';
import messageMatchersSlice from 'src/containers/MessageMatchers/slice';
import newsSlice from 'src/containers/News/slice';
import notificationsSlice from 'src/containers/Notifications/slice';
import notificationsHistorySlice from 'src/containers/NotificationsHistory/slice';
import parametersSlice from 'src/containers/Parameters/slice';
import reportSlice from 'src/containers/Report/slice';
import subjectsSlice from 'src/containers/Subjects/slice';
import subscribersSlice from 'src/containers/Subscribers/slice';
import summaryReportSlice from 'src/containers/SummaryReport/slice';
import supportClientsSlice from 'src/containers/SupportClients/slice';
import triggersSlice from 'src/containers/Triggers/slice';
import {userSlice} from 'src/containers/User';
import whatsAppChannelSlice from 'src/containers/WhatsAppChannel/slice';
import {listenerMiddleware, rootApi} from 'src/models';
import {requestInterceptors} from 'src/models/requestInterceptors';

import {isEnvDevelopment} from './constants';

const composeWithDevTools = require('redux-devtools-extension').composeWithDevTools;

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    const actionType = action.type;

    if (actionType.startsWith('auth')) {
      return {
        ...action,
        password: null,
        newPassword: null,
        otpId: null,
      };
    }

    if (actionType.startsWith('/channel-profile/instagram')) {
      return {
        ...action,
        userAccessToken: null,
      };
    }

    if (actionType.startsWith('channel-profile/embedded-signup')) {
      return {
        ...action,
        token: null,
      };
    }

    return action;
  },
});

export const reducers = {
  [rootApi.reducerPath]: rootApi.reducer,
  app: appSlice,
  news: newsSlice,
  user: userSlice,
  triggers: triggersSlice,
  channels: channelsSlice,
  notifications: notificationsSlice,
  notificationsHistory: notificationsHistorySlice,
  cascades: cascadesSlice,
  broadcasts: broadcastsSlice,
  report: reportSlice,
  summaryReport: summaryReportSlice,
  parameters: parametersSlice,
  accountStatement: accountStatementSlice,
  supportClients: supportClientsSlice,
  leadClients: leadClientsSlice,
  subscribers: subscribersSlice,
  whatsAppChannel: whatsAppChannelSlice,
  subjects: subjectsSlice,
  messageMatchers: messageMatchersSlice,
  whatsAppMessageMatcher: whatsAppMessageMatcherSlice,
};

const middlewares = [listenerMiddleware.middleware, thunk, rootApi.middleware];

const makeStore = ({extraReducers = {}} = {}) => {
  const store = isEnvDevelopment
    ? createStore(
        combineReducers({...reducers, ...extraReducers}),
        composeWithDevTools(applyMiddleware(...middlewares)),
      )
    : createStore(
        combineReducers({...reducers, ...extraReducers}),
        compose(applyMiddleware(...middlewares), sentryReduxEnhancer),
      );

  requestInterceptors(store);

  return store;
};

export {makeStore};

export default makeStore();
/* eslint-enable @typescript-eslint/no-var-requires */
