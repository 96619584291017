import {EWeekDay} from './types';

export default {
  title: 'Настройки',
  allSettings: 'Общие настройки',
  name: 'Название сценария',
  subject: 'Выберите имя отправителя WhatsApp для сообщений в сценарии',
  subjectHint: 'Используйте данный аккаунт только для отправки сообщений в сценарии',
  onlyOnes: 'Не запускать повторно для тех же получателей',
  onlyOnesHint: 'После завершения сценария получатель не попадет в него повторно',
  saveContinue: 'Сохранить и продолжить',
  chainRepeat: {
    title: 'Периодичность сценария',
    singleLaunch: 'Единичный запуск',
    singleLaunchHint:
      'Сценарий будет запущен один раз после сохранения или в указанные дату и время',
    regular: 'По расписанию',
    regularHint:
      'Сценарий выполняется согласно заданному расписанию вплоть до даты и времени остановки',
  },
  regularTrigger: {
    title: 'Периодичность сценария',
  },
  startDate: {
    title: 'Когда запустим сценарий?',
    now: 'Запустить сразу',
    setDate: 'Задать дату и время',
    date: 'Дата запуска',
    time: 'Время запуска',
  },
  endDate: {
    date: 'Дата остановки',
    time: 'Время остановки',
    title: 'Завершить сценарий в определенную дату',
    label: 'Когда завершим сценарий',
    labelHint:
      'Cценарий будет завершен принудительно, если получатель бездействует в течение 48 часов',
    titleHint:
      'При завершении сценария ваши диалоги с получателями будут прерваны. Абоненты, которые участвуют в сценарии, не смогут его завершить',
  },
  chooseDayAndTime: 'Выберите дни недели и расписание работы сценария',
  addDate: 'Добавить дату',
  weekDays: {
    [EWeekDay.MONDAY]: 'Понедельник',
    [EWeekDay.TUESDAY]: 'Вторник',
    [EWeekDay.WEDNESDAY]: 'Среда',
    [EWeekDay.THURSDAY]: 'Четверг',
    [EWeekDay.FRIDAY]: 'Пятница',
    [EWeekDay.SATURDAY]: 'Суббота',
    [EWeekDay.SUNDAY]: 'Воскресенье',
  },
};
