import PATHS from 'src/paths';
import {getTitleForPath} from 'src/utils';

export default {
  loading: 'Загрузка...',
  [PATHS.HOME]: 'Главная',
  [PATHS.UNAVAILABLE]: 'Сервис временно недоступен',
  [PATHS.SIGN_IN]: 'Вход в аккаунт',
  [PATHS.PASSWORD_SAVED]: 'Пароль сохранен!',
  [getTitleForPath(PATHS.PUBLIC_WHATSAPP_REGISTRATION)]: 'Регистрация канала WhatsApp',
  [getTitleForPath(PATHS.SIGN_IN_BY_INVITE)]: 'Вход',
  [PATHS.EMAIL_CONFIRM]: 'Email подтверждение',
  [PATHS.CHANNELS_LIST]: 'Каналы',
  [PATHS.MESSAGE_MATCHERS_LIST]: 'Шаблоны',
  [PATHS.CHANNELS_WHATSAPP_NEW]: 'Создание канала WhatsApp',
  [getTitleForPath(PATHS.CHANNELS_WHATSAPP_EDIT)]: 'Редактирование канала WhatsApp',
  [getTitleForPath(PATHS.CHANNELS_WHATSAPP_VIEW)]: 'Просмотр канала WhatsApp',
  [PATHS.CHANNELS_EMBEDDED_WHATSAPP_NEW]: 'Создание канала WhatsApp Embedded Signup',
  [getTitleForPath(PATHS.CHANNELS_EMBEDDED_WHATSAPP_EDIT)]:
    'Редактирование канала WhatsApp Embedded Signup',
  [getTitleForPath(PATHS.CHANNELS_EMBEDDED_WHATSAPP_VIEW)]:
    'Просмотр канала WhatsApp Embedded Signup',
  [PATHS.CHANNELS_WHATSAPP_TECH_PARTNER_NEW]: 'Создание канала WhatsApp Embedded Signup',
  [getTitleForPath(PATHS.CHANNELS_WHATSAPP_TECH_PARTNER_EDIT)]:
    'Редактирование канала WhatsApp Embedded Signup',
  [getTitleForPath(PATHS.CHANNELS_WHATSAPP_TECH_PARTNER_VIEW)]:
    'Просмотр канала WhatsApp Embedded Signup',
  [PATHS.CHANNELS_VIBER_NEW]: 'Создание канала Viber',
  [getTitleForPath(PATHS.CHANNELS_VIBER_EDIT)]: 'Редактирование канала Viber',
  [getTitleForPath(PATHS.CHANNELS_VIBER_VIEW)]: 'Просмотр канала Viber',
  [PATHS.CHANNELS_SMS_NEW]: 'Регистрация канала SMS',
  [getTitleForPath(PATHS.CHANNELS_SMS_EDIT)]: 'Данные канала SMS',
  [getTitleForPath(PATHS.CHANNELS_SMS_VIEW)]: 'Данные канала SMS',
  [PATHS.CHANNELS_INSTAGRAM_NEW]: 'Создание канала Instagram',
  [getTitleForPath(PATHS.CHANNELS_INSTAGRAM_VIEW)]: 'Просмотр канала Instagram',
  [PATHS.MESSAGE_MATCHERS_WHATSAPP_NEW]: 'Новый шаблон',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_WHATSAPP_EDIT)]: 'Редактирование шаблона WhatsApp',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_WHATSAPP_VIEW)]: 'Просмотр шаблона WhatsApp',
  [PATHS.MESSAGE_MATCHERS_VIBER_NEW]: 'Новый шаблон Viber',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_VIBER_EDIT)]: 'Редактирование шаблона Viber',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_VIBER_VIEW)]: 'Просмотр шаблона Viber',
  [PATHS.MESSAGE_MATCHERS_SMS_NEW]: 'Новый шаблон SMS',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_SMS_EDIT)]: 'Редактирование шаблона SMS',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_SMS_VIEW)]: 'Просмотр шаблона SMS',
  [PATHS.MESSAGE_MATCHERS_INSTAGRAM_NEW]: 'Новый шаблон Instagram',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_INSTAGRAM_EDIT)]: 'Редактирование шаблона Instagram',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_INSTAGRAM_VIEW)]: 'Просмотр шаблона Instagram',
  [PATHS.MESSAGE_MATCHERS_PUSH_NEW]: 'Новый шаблон Push',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_PUSH_EDIT)]: 'Редактирование шаблона Push',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_PUSH_VIEW)]: 'Просмотр шаблона Push',
  [PATHS.MESSAGE_MATCHERS_OK_NOTIFY_NEW]: 'Новый шаблон OK Notify',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_OK_NOTIFY_EDIT)]: 'Редактирование шаблона OK Notify',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_OK_NOTIFY_VIEW)]: 'Просмотр шаблона OK Notify',
  [PATHS.MESSAGE_MATCHERS_VK_NOTIFY_NEW]: 'Новый шаблон VK Notify',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_VK_NOTIFY_EDIT)]: 'Редактирование шаблона VK Notify',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_VK_NOTIFY_VIEW)]: 'Просмотр шаблона VK Notify',
  [PATHS.BROADCASTS_LIST]: 'Рассылки',
  [getTitleForPath(PATHS.BROADCASTS_DETAILS)]: 'Детали рассылки',
  [PATHS.BROADCASTS_NEW]: 'Создание рассылки',
  [getTitleForPath(PATHS.BROADCASTS_EDIT)]: 'Редактирование рассылки',
  [PATHS.CASCADES_LIST]: 'Каскады',
  [PATHS.CASCADES_NEW]: 'Создание каскада',
  [getTitleForPath(PATHS.CASCADES_VIEW)]: 'Просмотр каскада',
  [PATHS.SUBSCRIBERS_LIST]: 'Клиенты',
  [PATHS.SUBSCRIBERS_NEW]: 'Создать пользователя',
  [getTitleForPath(PATHS.SUBSCRIBERS_EDIT)]: 'Редактирование пользователя',
  [PATHS.SUBSCRIBERS_GROUP_EDIT]: 'Добавление списка клиентов',
  [PATHS.PROFILE]: 'Мой профиль',
  [PATHS.COMPANY_PROFILE]: 'Профиль {{companyName}}',
  [PATHS.COMPANY_CLIENTS]: 'Лиды',
  [getTitleForPath(PATHS.COMPANY_CLIENT_CARD)]: 'Карточка клиента',
  [getTitleForPath(PATHS.COMPANY_CLIENT_NEW)]: 'Новый клиент',
  [PATHS.SUPPORT_CLIENTS]: 'Клиенты компании',
  [getTitleForPath(PATHS.SUPPORT_CLIENT_CARD)]: 'Карточка клиента',
  [PATHS.ANALYTICS_MESSAGES]: 'Отчет по сообщениям',
  [PATHS.ANALYTICS_SUMMARY_REPORT]: 'Итоговый отчет',
  [getTitleForPath(PATHS.ANALYTICS_CHANNEL)]: 'Итоговый отчет',
  [PATHS.NOTIFICATIONS]: 'Журнал событий',
  [PATHS.HELP]: 'Помощь',
  [PATHS.TRIGGERS_NEW]: 'Новый сценарий',
  [PATHS.TRIGGERS_LIST]: 'Сценарии',
  [getTitleForPath(PATHS.SUPPORT_TRIGGERS_LIST)]: 'Сценарии',
  [getTitleForPath(PATHS.TRIGGERS_EDIT)]: 'Редактирование триггерной цепочки',
  [getTitleForPath(PATHS.TRIGGERS_VIEW)]: 'Просмотр триггерной цепочки',
  [getTitleForPath(PATHS.SUPPORT_TRIGGERS_VIEW)]: 'Просмотр триггерной цепочки',
  [PATHS.CHANNELS_PUSH_NEW]: 'Новый пуш-канал',
  [getTitleForPath(PATHS.CHANNELS_PUSH_APP_DATA)]: 'Данные приложения',
  [getTitleForPath(PATHS.CHANNELS_PUSH_LIST)]: 'Данные пуш-канала',
  [getTitleForPath(PATHS.CHANNELS_PUSH_APP_NEW)]: 'Настройка приложения {{appType}}',
  [getTitleForPath(PATHS.CHANNELS_PUSH_IOS_NEW_STEP)]: 'Настройка приложения iOS',
  [getTitleForPath(PATHS.CHANNELS_PUSH_IOS_EDIT)]: 'Настройка приложения iOS',
  [getTitleForPath(PATHS.CHANNELS_PUSH_ANDROID_NEW_STEP)]: 'Настройка приложения Android',
  [getTitleForPath(PATHS.CHANNELS_PUSH_ANDROID_EDIT)]: 'Настройка приложения Android',
  [getTitleForPath(PATHS.INTEGRATION)]: 'Интеграция',
  [PATHS.FILES]: 'Скачивание',
  [PATHS.CORRECTIONS]: 'Корректировки',
  [PATHS.CHANNELS_VK_NOTIFY_NEW]: 'Создание канала VK Notify',
  [getTitleForPath(PATHS.CHANNELS_VK_NOTIFY_EDIT)]: 'Редактирование канала VK Notify',
  [getTitleForPath(PATHS.CHANNELS_VK_NOTIFY_VIEW)]: 'Просмотр канала VK Notify',
  [PATHS.CHANNELS_OK_NOTIFY_NEW]: 'Создание канала OK Notify',
  [getTitleForPath(PATHS.CHANNELS_OK_NOTIFY_EDIT)]: 'Редактирование канала OK Notify',
  [getTitleForPath(PATHS.CHANNELS_OK_NOTIFY_VIEW)]: 'Просмотр канала OK Notify',
  [PATHS.SUPPORT_INVOICE_ASSIGN]: 'Зачисление платежа',
  [PATHS.SUPPORT_INVOICE_CANCEL]: 'Отмена платежа',
};
