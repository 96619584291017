import {TBadgeColor} from 'src/components';
import {TItemId} from 'src/constants';
import {TNotificationLevel} from 'src/containers/Notifications';
import {AndroidCircleIcon, IOSCircleIcon} from 'src/icons';

export type TLabel = {
  type: TNotificationLevel;
  text: string;
};

export enum EAppStatus {
  DEVICES_CONNECTED = 'DEVICES_CONNECTED',
  DEVICES_NOT_CONNECTED = 'DEVICES_NOT_CONNECTED',
  ERROR = 'ERROR',
  BLOCKED = 'BLOCKED',
}

export type THuaweiItem = {
  appGalleryId: string;
  appGallerySecret: string;
};

export type TProductionPushCertificate = {
  expirationDate: string;
  fileName: string;
  password: string;
};

export type TIOSAppInfo = {
  productionPushCertificate: TProductionPushCertificate;
};

export type TAndroidAppInfo = {
  serviceJson?: {fileName: string};
  huaweiPushAppInfo?: THuaweiItem;
};

export type TRegistrationInfo = {
  id?: TItemId;
  currentStep?: number;
  maxOpenedStep?: number;
  status?: ELastStepStatus;
  testPushId?: number;
  updatedAt?: string;
  createdAt?: string;
};

export type TPushApp = {
  id: TItemId;
  appPackage: string;
  tenantUuid: string;
  pushProfileId: TItemId;
  type: EPushAppType;
  status: EAppStatus;
  createdAt: string;
  updatedAt: string;
  data: {
    androidPushAppInfo?: TAndroidAppInfo;
    iosPushAppInfo?: TIOSAppInfo;
    automaticDevicesRegistration: boolean;
  };
  ednaPushToken: string;
  registrationInfo: TRegistrationInfo;
  label: TLabel;
  bundleId: string;
};

export enum EPushAppType {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export type TFormDataIOS = {
  appPackage: string;
  productionPushCertificatePassword: TProductionPushCertificate['password'];
  productionPushCertificate: File;
};

export type TFormDataAndroid = TAndroidAppInfo & {
  appPackage: string;
};

export type TFormData = {
  id?: TPushApp['id'];
  pushId?: TPushApp['pushProfileId'];
  appType?: EPushAppType;
  appPackage: TPushApp['appPackage'];
  androidPushAppInfo?: TAndroidAppInfo & {
    serviceJsonFile: File | null;
  };
  productionPushCertificatePassword?: TProductionPushCertificate['password'];
  productionPushCertificate?: File;
  hasHuawei?: boolean;
  automaticDevicesRegistration?: boolean;
};

export type TDevicesCount = {
  pushAppPackageToType: {
    appPackage: string;
    type: EPushAppType;
  };
  countForWeek?: number | null;
  countForAllTime?: number | null;
};

export enum ELastStepStatus {
  DRAFT = 'DRAFT',
  DEVICE_CONNECTED = 'DEVICE_CONNECTED',
  DEVICE_NOT_CONNECTED = 'DEVICE_NOT_CONNECTED',
  TEST_PUSH_SENT = 'TEST_PUSH_SENT',
  TEST_PUSH_NOT_RECEIVED = 'TEST_PUSH_NOT_RECEIVED',
  TEST_PUSH_RECEIVED = 'TEST_PUSH_RECEIVED',
}

export const colorsHash: Record<EAppStatus, TBadgeColor> = {
  DEVICES_CONNECTED: 'GREEN',
  DEVICES_NOT_CONNECTED: 'GRAY',
  ERROR: 'RED',
  BLOCKED: 'GRAY',
};

export const appsIconsHash = {
  [EPushAppType.ANDROID]: AndroidCircleIcon,
  [EPushAppType.IOS]: IOSCircleIcon,
};

export const appsNameHash = {
  [EPushAppType.IOS]: 'iOS',
  [EPushAppType.ANDROID]: 'Android',
};

export const APPS_COLUMNS_WIDTH = ['35%', '25%', '15%', '25%'];

export const FIRST_STEP_NUMBER = 1;
export const SECOND_STEP_NUMBER = 2;
