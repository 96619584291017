import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button} from '@edna/components';
import {Icon} from '@edna/components/primitives';
import CleanFiltersIcon from '@edna/icons/cleanFilters.svg';
import CloseIcon from '@edna/icons/close.svg';
import FilterIcon from '@edna/icons/filter.svg';

import {ActiveFilterIcon} from 'src/icons';

import * as S from './style';

type TFilterFormLayoutProps = {
  onReset: TEmptyFunction;
  isDisabledReset: boolean;
  children: React.ReactNode;
  onSubmit: TEmptyFunction;
  onToggleFilters: TEmptyFunction;
  dataSelector?: string;
};

const FilterFormLayout = React.memo<TFilterFormLayoutProps>(
  ({onReset, isDisabledReset, onSubmit, children, onToggleFilters, dataSelector}) => {
    const {t} = useTranslation();

    const testIdProps = dataSelector ? edna.renderTestId(dataSelector) : null;

    return (
      <S.Form onSubmit={onSubmit} {...testIdProps}>
        <S.FilterFormContent>{children}</S.FilterFormContent>
        <S.FormFooter>
          <S.LeftButton
            name="cancel"
            appearance="secondary"
            disabled={isDisabledReset}
            onClick={onReset}
          >
            <Icon as={CleanFiltersIcon} size="16px" title={t('Filters:resetFilters')} />
            {t('Common:button.resetAll')}
          </S.LeftButton>
          <Button appearance="secondary" onClick={onToggleFilters}>
            <Icon as={CloseIcon} size="20px" title={t('Filters:resetFilters')} />
            {t('Common:button.close')}
          </Button>
          <Button appearance="primary" type="submit">
            {t('Common:button.apply')}
          </Button>
        </S.FormFooter>
      </S.Form>
    );
  },
);

FilterFormLayout.displayName = 'FilterFormLayout';

type TFiltersButton = {
  hasFilters: boolean;
  onResetFilters: TEmptyFunction;
  onToggleFilters?: TEmptyFunction;
};

const FiltersButton = React.memo<TFiltersButton>(({hasFilters, onToggleFilters}) => {
  const {t} = useTranslation();

  return (
    <S.FilterButton
      appearance="secondary"
      block
      data-selector="Filter:filters"
      onClick={onToggleFilters}
    >
      <Icon as={FilterIcon} />
      {t('Filters:filters')}
      {hasFilters && <Icon as={ActiveFilterIcon} />}
    </S.FilterButton>
  );
});

FiltersButton.displayName = 'FiltersButton';

export {FilterFormLayout, FiltersButton};
export {FiltersWrapper, FilterFormContent} from './style';
