import {TOperator, and, any, not, or} from '@edna/utils';

import {EPermission, ERoles} from 'src/containers/User';
import PATHS from 'src/paths';

import {bootstrapComponents} from './utils';

/* prettier-ignore */
const componentImports = {
  Home: () => import(/* webpackChunkName: "channels" */ '../Home'),
  NotFound: () => import(/* webpackChunkName: "not-found" */ '../NotFound'),
  ServiceUnavailable: () => import(/* webpackChunkName: "service-unavailable" */ '../ServiceUnavailable'),
  // Auth
  SignIn: () => import(/* webpackChunkName: "sign-in" */ '../Auth/SignIn'),
  PasswordSaved: () => import(/* webpackChunkName: "password-saved" */ '../Auth/PasswordSaved'),
  EmailConfirm: () => import(/* webpackChunkName: "email-confirm" */ '../Auth/EmailConfirm'),
  SignInByInviteLink: () => import(/* webpackChunkName: "sign-up-by-invite-link" */ '../SignInByInviteLink'),
  InvoiceCancel: () => import(/* webpackChunkName: "invoice-cancel" */ '../Invoices/CancelForm'),
  InvoiceAssign: () => import(/* webpackChunkName: "corrections" */ '../Invoices/AssignForm'),

  // Channels
  Channels: () => import(/* webpackChunkName: "channels" */ '../Channels'),
  WhatsAppChannelEdit: () => import(/* webpackChunkName: "whats-app-channel-edit" */ '../WhatsAppChannel/EditForm'),
  EmbeddedWhatsAppChannel: () => import(/* webpackChunkName: "embedded-whats-app-channel-form" */ '../EmbeddedWhatsAppChannel/ViewForm'),
  EmbeddedWhatsAppChannelForm: () => import(/* webpackChunkName: "embedded-whats-app-channel-form" */ '../EmbeddedWhatsAppChannel/EditForm'),
  WhatsAppTechPartnerChannelForm: () => import(/* webpackChunkName: "whats-app-tech-partner-channel-form" */ '../EmbeddedWhatsAppChannel/TechPartnerForm'),
  WhatsAppPublicChannelForm: () => import(/* webpackChunkName: "embedded-whats-app-public-channel-form" */ '../EmbeddedWhatsAppChannel/PublicForm'),
  ViberChannelEditForm: () => import(/* webpackChunkName: "viber-channel-edit-form" */ '../ViberChannel/EditForm'),
  ViberChannelViewForm: () => import(/* webpackChunkName: "viber-channel-view-form" */ '../ViberChannel/ViewForm'),
  SmsChannelEdit: () => import(/* webpackChunkName: "sms-channel-edit" */ '../SmsChannel/EditForm'),
  InstagramChannelEdit: () => import(/* webpackChunkName: "instagram-channel-edit" */ '../InstagramChannel/EditForm'),
  PushAppData: () => import(/* webpackChunkName: "push-app-data" */ '../PushApps/AppData'),
  PushChannelApps: () => import(/* webpackChunkName: "push-channel-apps" */ '../PushApps'),
  PushChannelEdit: () => import(/* webpackChunkName: "push-channel-edit" */ '../PushChannel/EditForm'),
  StartSetupApp: () => import(/* webpackChunkName: "start-setup-app" */ '../PushApps/EditForm/StartSetupApp'),
  PushAppsIOSEdit: () => import(/* webpackChunkName: "push-apps-ios-edit" */ '../PushApps/EditForm/IOS'),
  PushAppsAndroidEdit: () => import(/* webpackChunkName: "push-apps-android-edit" */ '../PushApps/EditForm/Android'),
  VkNotifyEdit: () => import(/* webpackChunkName: "vk-notify-channel-edit" */ '../VkNotifyChannel/EditForm'),
  OkNotifyEdit: () => import(/* webpackChunkName: "ok-notify-channel-edit" */ '../OkNotifyChannel/EditForm'),

  // Broadcasts
  Broadcasts: () => import(/* webpackChunkName: "broadcasts" */ '../Broadcasts'),
  BroadcastDetails: () => import(/* webpackChunkName: "broadcast-details" */ '../Broadcasts/Details'),
  BroadcastsEdit: () => import(/* webpackChunkName: "broadcasts-edit" */ '../Broadcasts/Edit'),
  // Subscribers
  Subscribers: () => import(/* webpackChunkName: "subscribers" */ '../Subscribers'),
  SubscribersEdit: () => import(/* webpackChunkName: "subscribers-edit" */ '../Subscribers/EditForm'),
  SubscribersGroupEdit: () => import(/* webpackChunkName: "subscribers-group-edit" */ '../Subscribers/GroupEditForm'),
  // MessageMatchers
  MessageMatchers: () => import(/* webpackChunkName: "message-matchers" */ '../MessageMatchers'),
  WhatsAppMessageMatcherEdit: () => import(/* webpackChunkName: "new-whats-app-message-matcher-edit" */ '../MessageMatchers/WhatsAppForm'),
  WhatsAppMessageMatcherView: () => import(/* webpackChunkName: "new-whats-app-message-matcher-view" */ '../MessageMatchers/WhatsAppForm/WhatsAppView'),
  ViberMessageMatcherEdit: () => import(/* webpackChunkName: "viber-message-matcher-edit" */ '../MessageMatchers/ViberForm'),
  SmsMessageMatcherEdit: () => import(/* webpackChunkName: "sms-message-matcher-edit" */ '../MessageMatchers/SMSForm'),
  SmsMessageMatcherView: () => import(/* webpackChunkName: "sms-message-matcher-view" */ '../MessageMatchers/SMSForm/View'),
  InstagramMessageMatcherEdit: () => import(/* webpackChunkName: "instagram-message-matcher-edit" */ '../MessageMatchers/InstagramForm'),
  OkNotifyMessageMatcherEdit: () => import(/* webpackChunkName: "ok-notify-message-matcher-edit" */ '../MessageMatchers/OkNotifyForm'),
  VkNotifyMessageMatcherEdit: () => import(/* webpackChunkName: "vk-notify-message-matcher-edit" */ '../MessageMatchers/VkNotifyForm'),
  // Cascades
  Cascades: () => import(/* webpackChunkName: "cascades" */ '../Cascades'),
  CascadeEdit: () => import(/* webpackChunkName: "cascade-edit" */ '../Cascades/EditForm'),
  Profile: () => import(/* webpackChunkName: "profile" */ '../Profile'),
  //Company
  UserCompanyProfile: () => import(/* webpackChunkName: "user-company-profile" */ '../UserCompanyProfile'),
  LeadCompanyClients: () => import(/* webpackChunkName: "lead-company-clients" */ '../LeadCompanyClients'),
  LeadCompanyClientCard: () => import(/* webpackChunkName: "lead-company-client-card" */ '../LeadCompanyClients/LeadCompanyClientCard'),
  LeadCompanyClientEdit: () => import(/* webpackChunkName: "lead-client-edit" */ '../LeadCompanyClients/EditForm'),
  // Support
  SupportClients: () => import(/* webpackChunkName: "support-clients" */ '../SupportClients'),
  SupportClientCard: () => import(/* webpackChunkName: "support-client-card" */ '../SupportClients/SupportClientCard'),
  // Integration
  Integration: () => import(/* webpackChunkName: "integration" */ '../Integration'),
  // Corrections
  Corrections: () => import(/* webpackChunkName: "corrections" */ '../Corrections'),
  // Analytics
  Report: () => import(/* webpackChunkName: "report" */ '../Report'),
  SummaryReport: () => import(/* webpackChunkName: "summary-report" */ '../SummaryReport'),
  ChannelReport: () => import(/* webpackChunkName: "channel-report" */ '../SummaryReport/ChannelReport'),

  NotificationsHistory: () => import(/* webpackChunkName: "notifications-history" */ '../NotificationsHistory'),
  Help: () => import(/* webpackChunkName: "help" */ '../Help'),
  Triggers: () => import(/* webpackChunkName: "triggers" */ '../Triggers'),
  TriggersEdit: () => import(/* webpackChunkName: "triggers-edit" */ '../Triggers/EditForm'),
  Files: () => import(/* webpackChunkName: "files" */ '../ServerFile'),

  // Support message matchers and analytics
  SupportMessageMatchers: () => import(/* webpackChunkName: "support-message-matchers" */ '../MessageMatchers/support'),
  SupportMessageMatcherView: () => import(/* webpackChunkName: "support-message-matcher-view" */ '../MessageMatchers/support/View'),
  SupportReport: () => import(/* webpackChunkName: "report" */ '../Report'),
  SupportSummaryReport: () => import(/* webpackChunkName: "support-summary-report" */ '../SummaryReport/support'),
  SupportChannelReport: () => import(/* webpackChunkName: "support-channel-report" */ '../SummaryReport/support/ChannelReport'),
};

/* prettier-ignore-end */

const C = bootstrapComponents(componentImports);

export {C as Components};

type TPrivateRoutes = Record<
  ValueOf<typeof PATHS>,
  {
    component: ValueOf<typeof C>;
    permissions: TOperator<EPermission>;
    roles: TOperator<ERoles>;
  }
>;

export const PRIVATE_ROUTES: TPrivateRoutes = {
  [PATHS.HOME]: {
    component: C.Home,
    permissions: any(),
    roles: any(),
  },
  [PATHS.BROADCASTS_LIST]: {
    component: C.Broadcasts,
    permissions: and<EPermission>('PERMISSION_BROADCAST_READ'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.BROADCASTS_NEW]: {
    component: C.BroadcastsEdit,
    permissions: and<EPermission>(
      'PERMISSION_BROADCAST_READ',
      'PERMISSION_BROADCAST_WRITE',
      'PERMISSION_WRITE',
    ),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.BROADCASTS_EDIT]: {
    component: C.BroadcastsEdit,
    permissions: and<EPermission>('PERMISSION_BROADCAST_READ'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.BROADCASTS_DETAILS]: {
    component: C.BroadcastDetails,
    permissions: and<EPermission>('PERMISSION_BROADCAST_READ'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_LIST]: {
    component: C.Channels,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.CHANNELS_WHATSAPP_NEW]: {
    component: C.WhatsAppChannelEdit,
    permissions: and<EPermission>('PERMISSION_WRITE', 'PERMISSION_WHATSAPP_PROFILE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_WHATSAPP_EDIT]: {
    component: C.WhatsAppChannelEdit,
    permissions: and<EPermission>('PERMISSION_WHATSAPP_PROFILE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_WHATSAPP_VIEW]: {
    component: C.WhatsAppChannelEdit,
    permissions: and<EPermission>('PERMISSION_WHATSAPP_PROFILE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_EMBEDDED_WHATSAPP_NEW]: {
    component: C.EmbeddedWhatsAppChannelForm,
    permissions: and<EPermission>('PERMISSION_WRITE', 'PERMISSION_WHATSAPP_PROFILE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_EMBEDDED_WHATSAPP_EDIT]: {
    component: C.EmbeddedWhatsAppChannelForm,
    permissions: and<EPermission>('PERMISSION_WRITE', 'PERMISSION_WHATSAPP_PROFILE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_EMBEDDED_WHATSAPP_VIEW]: {
    component: C.EmbeddedWhatsAppChannel,
    permissions: and<EPermission>('PERMISSION_WHATSAPP_PROFILE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_WHATSAPP_TECH_PARTNER_NEW]: {
    component: C.WhatsAppTechPartnerChannelForm,
    permissions: and<EPermission>('PERMISSION_WRITE', 'PERMISSION_WHATSAPP_PROFILE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_WHATSAPP_TECH_PARTNER_EDIT]: {
    component: C.WhatsAppTechPartnerChannelForm,
    permissions: and<EPermission>('PERMISSION_WRITE', 'PERMISSION_WHATSAPP_PROFILE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_WHATSAPP_TECH_PARTNER_VIEW]: {
    component: C.WhatsAppTechPartnerChannelForm,
    permissions: and<EPermission>('PERMISSION_WHATSAPP_PROFILE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_VIBER_NEW]: {
    component: C.ViberChannelEditForm,
    permissions: and<EPermission>('PERMISSION_WRITE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_VIBER_EDIT]: {
    component: C.ViberChannelEditForm,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.CHANNELS_VIBER_VIEW]: {
    component: C.ViberChannelViewForm,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.CHANNELS_SMS_NEW]: {
    component: C.SmsChannelEdit,
    permissions: and<EPermission>('PERMISSION_WRITE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_SMS_EDIT]: {
    component: C.SmsChannelEdit,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.CHANNELS_SMS_VIEW]: {
    component: C.SmsChannelEdit,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.CHANNELS_INSTAGRAM_NEW]: {
    component: C.InstagramChannelEdit,
    permissions: and<EPermission>('PERMISSION_WRITE', 'PERMISSION_INSTAGRAM'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_INSTAGRAM_VIEW]: {
    component: C.InstagramChannelEdit,
    permissions: and<EPermission>('PERMISSION_INSTAGRAM'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_PUSH_APP_DATA]: {
    component: C.PushAppData,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.CHANNELS_PUSH_NEW]: {
    component: C.PushChannelEdit,
    permissions: and<EPermission>('PERMISSION_WRITE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_PUSH_LIST]: {
    component: C.PushChannelApps,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.CHANNELS_PUSH_IOS_EDIT]: {
    component: C.PushAppsIOSEdit,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.CHANNELS_PUSH_APP_NEW]: {
    component: C.StartSetupApp,
    permissions: and<EPermission>('PERMISSION_WRITE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_PUSH_IOS_NEW_STEP]: {
    component: C.PushAppsIOSEdit,
    permissions: and<EPermission>('PERMISSION_WRITE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_PUSH_ANDROID_NEW_STEP]: {
    component: C.PushAppsAndroidEdit,
    permissions: and<EPermission>('PERMISSION_WRITE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_PUSH_ANDROID_EDIT]: {
    component: C.PushAppsAndroidEdit,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.CASCADES_LIST]: {
    component: C.Cascades,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.CASCADES_NEW]: {
    component: C.CascadeEdit,
    permissions: and<EPermission>('PERMISSION_WRITE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CASCADES_VIEW]: {
    component: C.CascadeEdit,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.MESSAGE_MATCHERS_LIST]: {
    component: C.MessageMatchers,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.MESSAGE_MATCHERS_WHATSAPP_NEW]: {
    component: C.WhatsAppMessageMatcherEdit,
    permissions: and<EPermission>('PERMISSION_WRITE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.MESSAGE_MATCHERS_WHATSAPP_EDIT]: {
    component: C.WhatsAppMessageMatcherEdit,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.MESSAGE_MATCHERS_WHATSAPP_VIEW]: {
    component: C.WhatsAppMessageMatcherView,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.MESSAGE_MATCHERS_VIBER_NEW]: {
    component: C.ViberMessageMatcherEdit,
    permissions: and<EPermission>('PERMISSION_WRITE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.MESSAGE_MATCHERS_VIBER_EDIT]: {
    component: C.ViberMessageMatcherEdit,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.MESSAGE_MATCHERS_VIBER_VIEW]: {
    component: C.ViberMessageMatcherEdit,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.MESSAGE_MATCHERS_SMS_NEW]: {
    component: C.SmsMessageMatcherEdit,
    permissions: and<EPermission>('PERMISSION_WRITE'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.MESSAGE_MATCHERS_SMS_EDIT]: {
    component: C.SmsMessageMatcherEdit,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.MESSAGE_MATCHERS_SMS_VIEW]: {
    component: C.SmsMessageMatcherView,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.MESSAGE_MATCHERS_INSTAGRAM_NEW]: {
    component: C.InstagramMessageMatcherEdit,
    permissions: and<EPermission>('PERMISSION_WRITE', 'PERMISSION_INSTAGRAM'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.MESSAGE_MATCHERS_INSTAGRAM_EDIT]: {
    component: C.InstagramMessageMatcherEdit,
    permissions: and<EPermission>('PERMISSION_INSTAGRAM'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.MESSAGE_MATCHERS_INSTAGRAM_VIEW]: {
    component: C.InstagramMessageMatcherEdit,
    permissions: and<EPermission>('PERMISSION_INSTAGRAM'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.MESSAGE_MATCHERS_VK_NOTIFY_NEW]: {
    component: C.VkNotifyMessageMatcherEdit,
    permissions: and<EPermission>('PERMISSION_WRITE', 'PERMISSION_VK_NOTIFY'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.MESSAGE_MATCHERS_VK_NOTIFY_EDIT]: {
    component: C.VkNotifyMessageMatcherEdit,
    permissions: and<EPermission>('PERMISSION_VK_NOTIFY'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.MESSAGE_MATCHERS_VK_NOTIFY_VIEW]: {
    component: C.VkNotifyMessageMatcherEdit,
    permissions: and<EPermission>('PERMISSION_VK_NOTIFY'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.TRIGGERS_LIST]: {
    component: C.Triggers,
    permissions: and<EPermission>('PERMISSION_FLOW'),
    roles: and<ERoles>('ROLE_USER', not<ERoles>('ROLE_BASIC_USER')),
  },
  [PATHS.TRIGGERS_NEW]: {
    component: C.TriggersEdit,
    permissions: and<EPermission>('PERMISSION_WRITE'),
    roles: and<ERoles>('ROLE_USER', not<ERoles>('ROLE_BASIC_USER')),
  },
  [PATHS.TRIGGERS_EDIT]: {
    component: C.TriggersEdit,
    roles: and<ERoles>('ROLE_USER', not<ERoles>('ROLE_BASIC_USER')),
    permissions: any(),
  },
  [PATHS.TRIGGERS_VIEW]: {
    component: C.TriggersEdit,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.SUPPORT_TRIGGERS_LIST]: {
    component: C.Triggers,
    roles: or<ERoles>('ROLE_SUPPORT', 'ROLE_ACCOUNT_MANAGER'),
    permissions: and<EPermission>('PERMISSION_TENANT_FLOW_READ'),
  },
  [PATHS.SUPPORT_TRIGGERS_VIEW]: {
    component: C.TriggersEdit,
    roles: or<ERoles>('ROLE_SUPPORT', 'ROLE_ACCOUNT_MANAGER'),
    permissions: and<EPermission>('PERMISSION_TENANT_FLOW_READ'),
  },
  [PATHS.SUBSCRIBERS_LIST]: {
    component: C.Subscribers,
    roles: and<ERoles>('ROLE_USER', not<ERoles>('ROLE_BASIC_USER')),
    permissions: any(),
  },
  [PATHS.SUBSCRIBERS_NEW]: {
    component: C.SubscribersEdit,
    permissions: and<EPermission>('PERMISSION_WRITE'),
    roles: and<ERoles>('ROLE_USER', not<ERoles>('ROLE_BASIC_USER')),
  },
  [PATHS.SUBSCRIBERS_EDIT]: {
    component: C.SubscribersEdit,
    roles: and<ERoles>('ROLE_USER', not<ERoles>('ROLE_BASIC_USER')),
    permissions: any(),
  },
  [PATHS.SUBSCRIBERS_GROUP_EDIT]: {
    component: C.SubscribersGroupEdit,
    roles: and<ERoles>('ROLE_USER', not<ERoles>('ROLE_BASIC_USER')),
    permissions: any(),
  },
  [PATHS.PROFILE]: {
    component: C.Profile,
    roles: any(),
    permissions: any(),
  },
  [PATHS.COMPANY_PROFILE]: {
    component: C.UserCompanyProfile,
    roles: any(),
    permissions: any(),
  },
  [PATHS.COMPANY_CLIENTS]: {
    component: C.LeadCompanyClients,
    roles: and<ERoles>('ROLE_ACCOUNT_MANAGER'),
    permissions: any(),
  },
  [PATHS.COMPANY_CLIENT_CARD]: {
    component: C.LeadCompanyClientCard,
    roles: and<ERoles>('ROLE_ACCOUNT_MANAGER'),
    permissions: any(),
  },
  [PATHS.COMPANY_CLIENT_NEW]: {
    component: C.LeadCompanyClientEdit,
    roles: and<ERoles>('ROLE_ACCOUNT_MANAGER'),
    permissions: any(),
  },
  [PATHS.CORRECTIONS]: {
    component: C.Corrections,
    roles: or<ERoles>('ROLE_SUPPORT', 'ROLE_TENANT_TRANSACT_STORNED', 'ROLE_TENANT_LICENSE_RENEW'),
    permissions: or<EPermission>(
      'PERMISSION_TENANT_LICENSE_RENEW',
      'PERMISSION_TENANT_TRANSACT_STORNED',
      and<EPermission>('PERMISSION_TARIFF_TEMPLATE_WRITE', 'PERMISSION_TENANT_TARIFF_WRITE'),
    ),
  },
  [PATHS.SUPPORT_CLIENTS]: {
    component: C.SupportClients,
    permissions: and<EPermission>('PERMISSION_TENANT_LIST_READ'),
    roles: and<ERoles>('ROLE_SUPPORT'),
  },
  [PATHS.SUPPORT_CLIENT_CARD]: {
    component: C.SupportClientCard,
    permissions: and<EPermission>('PERMISSION_TENANT_DATA_READ'),
    roles: and<ERoles>('ROLE_SUPPORT'),
  },
  [PATHS.INTEGRATION]: {
    component: C.Integration,
    roles: and<ERoles>('ROLE_USER', not<ERoles>('ROLE_BASIC_USER')),
    permissions: any(),
  },
  [PATHS.ANALYTICS_MESSAGES]: {
    component: C.Report,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.ANALYTICS_SUMMARY_REPORT]: {
    component: C.SummaryReport,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: or<EPermission>('PERMISSION_VIEW_BILLING', 'PERMISSION_VIEW_REPORT_SMS'),
  },
  [PATHS.ANALYTICS_CHANNEL]: {
    component: C.ChannelReport,
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
    permissions: any(),
  },
  [PATHS.NOTIFICATIONS]: {
    component: C.NotificationsHistory,
    roles: any(),
    permissions: any(),
  },
  [PATHS.HELP]: {
    component: C.Help,
    roles: any(),
    permissions: any(),
  },
  [PATHS.FILES]: {
    component: C.Files,
    roles: any(),
    permissions: any(),
  },
  [PATHS.UNAVAILABLE]: {
    component: C.ServiceUnavailable,
    roles: any(),
    permissions: any(),
  },
  [PATHS.CHANNELS_VK_NOTIFY_NEW]: {
    component: C.VkNotifyEdit,
    permissions: and<EPermission>('PERMISSION_WRITE', 'PERMISSION_VK_NOTIFY'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_VK_NOTIFY_EDIT]: {
    component: C.VkNotifyEdit,
    permissions: and<EPermission>('PERMISSION_VK_NOTIFY'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_VK_NOTIFY_VIEW]: {
    component: C.VkNotifyEdit,
    permissions: and<EPermission>('PERMISSION_VK_NOTIFY'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_OK_NOTIFY_NEW]: {
    component: C.OkNotifyEdit,
    permissions: and<EPermission>('PERMISSION_WRITE', 'PERMISSION_OK_NOTIFY'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_OK_NOTIFY_EDIT]: {
    component: C.OkNotifyEdit,
    permissions: and<EPermission>('PERMISSION_OK_NOTIFY'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.CHANNELS_OK_NOTIFY_VIEW]: {
    component: C.OkNotifyEdit,
    permissions: and<EPermission>('PERMISSION_OK_NOTIFY'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.MESSAGE_MATCHERS_OK_NOTIFY_NEW]: {
    component: C.OkNotifyMessageMatcherEdit,
    permissions: and<EPermission>('PERMISSION_WRITE', 'PERMISSION_OK_NOTIFY'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.MESSAGE_MATCHERS_OK_NOTIFY_EDIT]: {
    component: C.OkNotifyMessageMatcherEdit,
    permissions: and<EPermission>('PERMISSION_OK_NOTIFY'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.MESSAGE_MATCHERS_OK_NOTIFY_VIEW]: {
    component: C.OkNotifyMessageMatcherEdit,
    permissions: and<EPermission>('PERMISSION_OK_NOTIFY'),
    roles: or<ERoles>('ROLE_USER', 'ROLE_BASIC_USER'),
  },
  [PATHS.SUPPORT_INVOICE_ASSIGN]: {
    component: C.InvoiceAssign,
    permissions: and<EPermission>('CCB_OPERATION_CREDIT'),
    roles: any(),
  },
  [PATHS.SUPPORT_INVOICE_CANCEL]: {
    component: C.InvoiceCancel,
    permissions: and<EPermission>('CCB_OPERATION_CANCELLATION'),
    roles: any(),
  },
  [PATHS.SUPPORT_MESSAGE_MATCHERS_LIST]: {
    component: C.SupportMessageMatchers,
    roles: or<ERoles>('ROLE_SUPPORT', 'ROLE_ACCOUNT_MANAGER'),
    permissions: any(),
  },
  [PATHS.SUPPORT_MESSAGE_MATCHERS_WHATSAPP_VIEW]: {
    component: C.SupportMessageMatcherView,
    roles: or<ERoles>('ROLE_SUPPORT', 'ROLE_ACCOUNT_MANAGER'),
    permissions: any(),
  },
  [PATHS.SUPPORT_MESSAGE_MATCHERS_VIBER_VIEW]: {
    component: C.SupportMessageMatcherView,
    roles: or<ERoles>('ROLE_SUPPORT', 'ROLE_ACCOUNT_MANAGER'),
    permissions: any(),
  },
  [PATHS.SUPPORT_MESSAGE_MATCHERS_SMS_VIEW]: {
    component: C.SupportMessageMatcherView,
    roles: or<ERoles>('ROLE_SUPPORT', 'ROLE_ACCOUNT_MANAGER'),
    permissions: any(),
  },
  [PATHS.SUPPORT_MESSAGE_MATCHERS_INSTAGRAM_VIEW]: {
    component: C.SupportMessageMatcherView,
    roles: or<ERoles>('ROLE_SUPPORT', 'ROLE_ACCOUNT_MANAGER'),
    permissions: any(),
  },
  [PATHS.SUPPORT_MESSAGE_MATCHERS_OK_NOTIFY_VIEW]: {
    component: C.SupportMessageMatcherView,
    roles: or<ERoles>('ROLE_SUPPORT', 'ROLE_ACCOUNT_MANAGER'),
    permissions: any(),
  },
  [PATHS.SUPPORT_MESSAGE_MATCHERS_VK_NOTIFY_VIEW]: {
    component: C.SupportMessageMatcherView,
    roles: or<ERoles>('ROLE_SUPPORT', 'ROLE_ACCOUNT_MANAGER'),
    permissions: any(),
  },
  [PATHS.SUPPORT_ANALYTICS_MESSAGES]: {
    component: C.SupportReport,
    roles: or<ERoles>('ROLE_SUPPORT', 'ROLE_ACCOUNT_MANAGER'),
    permissions: any(),
  },
  [PATHS.SUPPORT_ANALYTICS_SUMMARY_REPORT]: {
    component: C.SupportSummaryReport,
    roles: or<ERoles>('ROLE_SUPPORT', 'ROLE_ACCOUNT_MANAGER'),
    permissions: any(),
  },
  [PATHS.SUPPORT_ANALYTICS_CHANNEL]: {
    component: C.SupportChannelReport,
    roles: or<ERoles>('ROLE_SUPPORT', 'ROLE_ACCOUNT_MANAGER'),
    permissions: any(),
  },
};

export type TRoutesOptions = Record<ValueOf<typeof PATHS>, ValueOf<typeof C>>;

export const PUBLIC_ROUTES: TRoutesOptions = {
  [PATHS.SIGN_IN]: C.SignIn,
  [PATHS.PASSWORD_SAVED]: C.PasswordSaved,
  [PATHS.SIGN_IN_BY_INVITE]: C.SignInByInviteLink,
  [PATHS.EMAIL_CONFIRM]: C.EmailConfirm,
  [PATHS.UNAVAILABLE]: C.ServiceUnavailable,
  [PATHS.PUBLIC_WHATSAPP_REGISTRATION]: C.WhatsAppPublicChannelForm,
};

export const getPrivateRoutesOptions = (userPermissions: EPermission[], userRoles: ERoles[]) =>
  Object.entries(PRIVATE_ROUTES).reduce((acc, [path, {component, permissions, roles}]) => {
    const hasPermissions = permissions(userPermissions);
    const hasRoles = roles(userRoles);

    if (hasPermissions && hasRoles) {
      return {...acc, [path]: component};
    }

    return acc;
  }, {});
