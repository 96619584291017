import React from 'react';
import {useMatch} from 'react-router-dom';

import {renderOnboardingId} from '../Onboarding';
import * as S from './style';

type TProps = {
  className?: string;
  tabs: string[];
  tabIconHash: Record<string, TAnyComponent>;
  tabOnboardingHash?: Record<string, string>;
  tabLabelHash: Record<string, string>;
  path: string;
};

const IconTabs = React.memo<TProps>(
  ({tabs, tabOnboardingHash, tabIconHash, className, tabLabelHash, path}) => {
    const match = useMatch(path);
    const routeTab = match?.params?.tab;

    return (
      <S.Tabs className={className}>
        {tabs.map((tab) => (
          <S.RouterLink to={tab} key={tab}>
            <S.Tab active={tab === routeTab} {...renderOnboardingId(tabOnboardingHash?.[tab])}>
              <S.TabsIcon as={tabIconHash[tab]} />
              {tabLabelHash[tab]}
            </S.Tab>
          </S.RouterLink>
        ))}
      </S.Tabs>
    );
  },
);

IconTabs.displayName = 'IconTabs';

export default IconTabs;
