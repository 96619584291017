import {EDocumentType, EPaymentType} from 'src/containers/User';

import {EFormStep, ELeadClientCreatingError} from '../definitions';

export default {
  errors: {
    [ELeadClientCreatingError.TAX_NUMBER_ALREADY_IN_USE]:
      'A profile with this TIN/INN and contact person already exists.',
    [ELeadClientCreatingError.EMAIL_ALREADY_IN_USE]: 'A profile with this email already exists.',
  },
  labels: {
    companyName: 'Company name',
    taxNumber: 'TIN',
    binNumber: 'Business Identification Number (BIN)',
    blrTaxNumber: 'UNP / TIN',
    ogrn: 'OGRN/OGRNIP code',
    countryCode: 'Country',
    currencyCode: 'Currency',
    resellerId: 'Jurisdiction',
    contactName: 'Contact details',
    phone: 'Phone number',
    company: 'Company',
    responsibleManagerId: 'Assigned edna manager',
    channel: 'Channel',
    tariff: 'Rate',
    special: 'Personal',
    documentType: 'Offer/Paper contract',
    tariffConditions: 'Description of pricing conditions',
    paymentType: 'Payment type',
    manager: 'Person responsible for audit',
    isChecked: 'The audit in the financial and legal department has been passed',
    depositAmount: 'Security payment amount',
    overdraftAmount: 'Overdraft amount',
    paymentPeriod: 'License payment period in days',
    cancellationPeriod: 'Number of days until the license is cancelled in case of non-payment',
    activateLicenseDebt: 'Activate license on credit',
    disableBilling: 'Disable billing for the client',
    contractNumber: 'Contract number',
    pilotContractNumber: 'Contract number (optionally)',
    contractDate: 'Date of contract',
    pilotContractDate: 'Valid till',
    partnerId: 'Agent',
    hasPartner: 'Lead from agent',
    bank: 'Registration bank',
    isAgreedOverdraft: 'The setup and the amount of overdraft were approved',
    agreedOverdraft: 'Overdraft approved by',
  },
  placeholders: {
    email: 'yourname@company.com',
    phone: 'Enter the phone number',
    currencyCode: 'Select a currency',
    resellerId: 'Select a jurisdiction',
    channel: '-',
    tariff: 'Select a rate',
    partnerId: 'Select agent',
    agreedOverdraft: 'Select the approver',
    managerResponsibleForCheckingId: 'Select person responsible for audit',
  },
  buttons: {
    create: 'Create',
    next: 'Next',
    addTariff: 'Add pricing',
    confirm: 'Confirm',
    agreed: 'Approved',
  },
  titles: {
    dataAndContacts: 'Company details and contacts',
    calculationAndLicenses: 'Mutual settlements and licenses',
    companyTariffs: 'Company rates',
  },
  subtitles: {
    commonData: 'General details',
    contacts: 'Contacts',
    paymentSystem: 'Agreed payment system',
    credits: 'Credit facilities',
    licensesConditions: 'License activation conditions',
    proposedCompanyTariffs: 'Proposed company rates',
    billing: 'Billing',
    contract: 'Contract',
  },
  steps: {
    [EFormStep.DETAILS]: 'Company details',
    [EFormStep.CALCULATION_AND_LICENSES]: 'Mutual settlements and licenses',
    [EFormStep.TARIFFS]: 'Client pricing',
  },
  documentTypes: {
    [EDocumentType.AGREEMENT]: {
      label: 'Offer',
      message:
        'Attention! This type of registration is available for clients with no paper contracts signed. To work with the service, the client will have to accept the user agreement. Confirm your selection',
    },
    [EDocumentType.CONTRACT]: {
      label: 'Paper contract',
      message:
        'Attention! This type of registration is only available for a client with whom you have already signed a contract. Confirm your selection',
    },
    [EDocumentType.TECH_PARTNER]: {
      label: 'Tech partner',
      message:
        'Attention! This type of registration is only available for a client with whom you have already signed a contract. Confirm your selection',
    },
    [EDocumentType.PILOT]: {
      label: 'Pilot',
    },
  },
  paymentTypes: {
    [EPaymentType.POSTPAID]: {
      label: 'Post-payment',
    },
    [EPaymentType.PREPAYMENT]: {
      label: 'Prepayment',
    },
    [EPaymentType.MIXED_PREPAYMENT]: {
      label: 'Mixed payment',
    },
  },
  error: {
    confirm: 'Confirmation required',
  },
  description: {
    blrTariff: 'Refer to the contract for the terms of the tariff.',
    whatsapp: {
      firstReseller: {
        template_1: `1. The license fee for an edna Pulse license with the WhatsApp functionality consists of:
1.1. The fixed part of the license fee is {{licensePrice}} {{currency}}/{{period}} (the package includes {{mauCount}} MAU*).
1.2. The variable part of the license fee consists of the cost of each additional MAU (over of the number of MAU of the fixed part) – {{defaultMauPrice}} {{currency}}.
2. {{paymentCondition}}`,
        template_2_link: `3. WhatsApp message routing service: the cost of conversations (<0>pricing details</0>)`,
        template_2_text_1: `3. WhatsApp message routing service: `,
        template_2_text_2: `the cost of conversations`,
        template_3: `
All prices are indicated without VAT.**

*MAU (monthly active user) is a unique phone number of your client who sent or received a message from your company within one calendar month.
**VAT is paid at the rate in accordance with the legislation of the Russian Federation.
The cost set in USD is paid in rubles at the exchange rate of the Central Bank of the Russian Federation on the last day of the month.
  `,
        template_3_kzt: `
All prices are indicated without VAT.**

*MAU (monthly active user) is a unique phone number of your client who sent or received a message from your company within one calendar month.
**VAT is paid at the rate in accordance with the legislation of the Republic of Kazakhstan.
  `,
        paymentCondition: `Connecting one phone number is {{price}} {{currency}}.`,
        freePaymentCondition: `Connecting a phone number is free of charge.`,
      },
      ioReseller: {
        agreement:
          'Connecting one account costs {{price}} {{currency}}.\n' +
          'The license fee consists of the following:\n' +
          '1. The fixed part of the license fee is {{licensePrice}} {{currency}}/{{period}}. (The package includes {{mauCount}} MAU)*\n' +
          '2. The variable part of the license fee consists of the cost of each additional MAU (over the number of MAU of the fixed part) - {{defaultMauPrice}} {{currency}}.\n' +
          '<0>Price per initiated conversation</0>\n' +
          'All prices are without VAT**\n' +
          '*MAU (monthly active user) is a unique phone number of your client who sent or received a message from your company within one calendar month.\n' +
          '**The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation',
        contract:
          'The cost of WhatsApp license and conversations according to the terms of the contract\n' +
          'All prices are without VAT. The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation',
      },
    },
    viber: {
      packagePrice: `
Viber minimum fee {{packagePrice}} {{currency}}`,
      packagePriceContract: `
The cost of Viber minimum fee according to the terms of the contract`,
      firstReseller: {
        template_1_1: `1. License fee for one edna Pulse license with the Viber functionality is {{licensePrice}} {{currency}}.`,
        template_1_2: `
    2. Cost of sending messages to the Viber messenger:`,
        template_2: 'The cost of Viber messages according to the terms of the contract',
        template_2_link: 'The cost of delivered Viber messages (<0>pricing details</0>)',
        template_3: `All prices are indicated without VAT. VAT is paid at the rate as required by the legislation of the Russian Federation.
    The cost set in EUR is paid in rubles at the exchange rate of the Central Bank of the Russian Federation on the last day of the month.`,
      },
      secondReseller: {
        template_1: `The cost of Viber messages according to the terms of the contract
All prices are without VAT*

*The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation of the Republic of Cyprus
`,
        template_1_license: `License price {{licensePrice}} {{currency}}`,
        template_1_link: `
<0>Price for delivered Viber messages</0>
All prices are without VAT*

*The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation of the Republic of Cyprus
`,
      },
      thirdReseller: {
        packagePrice: `
Viber minimum fee {{packagePrice}} {{currency}}`,
        packagePriceContract: `
The cost of Viber minimum fee according to the terms of the contract`,
        contract: `The cost of Viber messages according to the terms of the contract
All prices are without VAT. The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation of the Republic of Indonesia`,
        offer_1: `License price {{licensePrice}} {{currency}}`,
        offer_link: `
<0>Price for delivered Viber messages</0>`,
        offer_3: `
All prices are without VAT*
*The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation of the Republic of Indonesia`,
      },
    },
    sms: {
      firstReseller: {
        template_1: `1. License fee for one edna Pulse license with the SMS functionality is {{licensePrice}} {{currency}}.
    2. Cost of sending SMS messages:`,
        template_2: 'The cost of SMS messages according to the terms of the contract',
        template_2_link: 'The cost of sent SMS messages (<0>pricing details</0>).',
        template_3:
          'All prices are indicated without VAT. VAT is paid at the rate as required by the legislation of the Russian Federation.',
      },
      secondReseller: {
        template_1: `The cost of SMS messages according to the terms of the contract
All prices are without VAT*

*The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation of the Republic of Cyprus
`,
        template_1_link: `License price {{licensePrice}} {{currency}}
<0>Price for delivered SMS messages</0>
All prices are without VAT*

*The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation of the Republic of Cyprus
`,
      },
      thirdReseller: {
        contract: `The cost of SMS messages according to the terms of the contract
All prices are without VAT. The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation of the Republic of Indonesia`,

        offer_1: `License price {{licensePrice}} {{currency}}.
The cost of Viber minimum fee according to the terms of the contract`,
        offer_2: `
2. Cost of sending messages to the Viber messenger:`,
        offer_2_link: `
<0>Price for delivered SMS messages  </0>`,
        offer_3: `All prices are without VAT. The amount of the License fee and Service fee is subject to VAT in accordance with the current legislation of the Republic of Indonesia`,
      },
    },
  },
  addPersonalTariff: 'Add an personal rate',
  billingMessage:
    'Important! You can only disable billing for clients with whom old-type contacts were concluded. To disable billing, you must approve it with your manager. Confirm your selection',
  blrBillingMessage:
    "Billing isn't available in your jurisdiction. All client payments are handled by Infosintez Ltd.",
};
