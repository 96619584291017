import omit from 'lodash/omit';

import {TPeriodPickerValue} from '@edna/components';
import {getPeriod} from '@edna/utils';

import {ENotificationLevel, EWebNotificationSection} from 'src/containers/Notifications';
import {BellIcon, DownloadIcon} from 'src/icons';
import {arrayToBooleanHash} from 'src/utils';

export enum ECategory {
  SUBSCRIBERS_UPLOAD = 'SUBSCRIBERS_UPLOAD',
  BROADCAST_LIFECYCLE = 'BROADCAST_LIFECYCLE',
  LICENSE = 'LICENSE',
  CALLBACK_LIFECYCLE = 'CALLBACK_LIFECYCLE',
  APIKEY_USER_LIFECYCLE = 'APIKEY_USER_LIFECYCLE',
  TENANT_DETAILS_UPDATES = 'TENANT_DETAILS_UPDATES',
  TARIFF_ACCEPTANCE = 'TARIFF_ACCEPTANCE',
  TARIFF_CHANGE = 'TARIFF_CHANGE',
  TARIFF_REJECTION = 'TARIFF_REJECTION',
  MESSAGE_REPORT = 'MESSAGE_REPORT',
}

export const NOTIFICATIONS_TABS = Object.values(EWebNotificationSection);

const DOWNLOADS_CATEGORIES = [ECategory.SUBSCRIBERS_UPLOAD, ECategory.MESSAGE_REPORT];

export const CATEGORIES_FILTER = {
  [EWebNotificationSection.NOTIFICATIONS]: Object.values(omit(ECategory, DOWNLOADS_CATEGORIES)),
  [EWebNotificationSection.UPLOADS]: DOWNLOADS_CATEGORIES,
};

export const LEVELS_FILTER = {
  [EWebNotificationSection.NOTIFICATIONS]: Object.values(
    omit(ENotificationLevel, ENotificationLevel.LOADING),
  ),
  [EWebNotificationSection.UPLOADS]: Object.values(ENotificationLevel),
};

export const TAB_ICON_HASH = {
  [EWebNotificationSection.NOTIFICATIONS]: BellIcon,
  [EWebNotificationSection.UPLOADS]: DownloadIcon,
};

export type TFilters = {
  levels: Record<ENotificationLevel, boolean>;
  categories: Record<ECategory, boolean>;
  period: TPeriodPickerValue;
  initiators: number[];
  isUploads?: boolean;
};

export const DEFAULT_FILTERS: Record<EWebNotificationSection, TFilters> = {
  [EWebNotificationSection.UPLOADS]: {
    levels: arrayToBooleanHash(LEVELS_FILTER[EWebNotificationSection.UPLOADS]),
    categories: arrayToBooleanHash(CATEGORIES_FILTER[EWebNotificationSection.UPLOADS]),
    initiators: [],
    period: getPeriod(0),
    isUploads: true,
  },
  [EWebNotificationSection.NOTIFICATIONS]: {
    levels: arrayToBooleanHash(LEVELS_FILTER[EWebNotificationSection.NOTIFICATIONS]),
    categories: arrayToBooleanHash(CATEGORIES_FILTER[EWebNotificationSection.NOTIFICATIONS]),
    initiators: [],
    period: getPeriod(0),
    isUploads: false,
  },
};
