import {TCurrency} from '@edna/utils';

enum EPermission {
  // User permissions
  PERMISSION_READ = 'PERMISSION_READ',
  PERMISSION_WRITE = 'PERMISSION_WRITE',
  PERMISSION_INSTAGRAM = 'PERMISSION_INSTAGRAM',
  PERMISSION_BROADCAST_WRITE = 'PERMISSION_BROADCAST_WRITE',
  PERMISSION_BROADCAST_READ = 'PERMISSION_BROADCAST_READ',
  PERMISSION_WHATSAPP_PROFILE = 'PERMISSION_WHATSAPP_PROFILE',
  PERMISSION_BALANCE_READ = 'PERMISSION_BALANCE_READ',
  PERMISSION_TRANSACTION_READ = 'PERMISSION_TRANSACTION_READ',
  PERMISSION_VIEW_BILLING = 'PERMISSION_VIEW_BILLING',
  PERMISSION_BROADCAST_FLOW_INTEGRATION = 'PERMISSION_BROADCAST_FLOW_INTEGRATION',
  PERMISSION_LICENSE_BLOCK = 'PERMISSION_LICENSE_BLOCK',
  PERMISSION_TENANT_LICENSE_BLOCK = 'PERMISSION_TENANT_LICENSE_BLOCK',
  PERMISSION_VK_NOTIFY = 'PERMISSION_VK_NOTIFY',
  PERMISSION_OK_NOTIFY = 'PERMISSION_OK_NOTIFY',
  PERMISSION_FLOW = 'PERMISSION_FLOW',
  PERMISSION_TENANT_FLOW_READ = 'PERMISSION_TENANT_FLOW_READ',
  PERMISSION_STRIPE_ONETIME_INVOICE = 'PERMISSION_STRIPE_ONETIME_INVOICE',
  PERMISSION_VIEW_REPORT_SMS = 'PERMISSION_VIEW_REPORT_SMS',

  //  Support permissions
  PERMISSION_TENANT_LIST_READ = 'PERMISSION_TENANT_LIST_READ',
  PERMISSION_TENANT_DATA_READ = 'PERMISSION_TENANT_DATA_READ',
  PERMISSION_TENANT_LICENSE_READ = 'PERMISSION_TENANT_LICENSE_READ',
  PERMISSION_TENANT_CALLBACK_READ = 'PERMISSION_TENANT_CALLBACK_READ',
  PERMISSION_TENANT_CALLBACK_WRITE = 'PERMISSION_TENANT_CALLBACK_WRITE',
  PERMISSION_TENANT_APIKEY_READ = 'PERMISSION_TENANT_APIKEY_READ',
  PERMISSION_TENANT_APIKEY_WRITE = 'PERMISSION_TENANT_APIKEY_WRITE',
  PERMISSION_TENANT_DETAILS_READ = 'PERMISSION_TENANT_DETAILS_READ',
  PERMISSION_TENANT_DETAILS_WRITE = 'PERMISSION_TENANT_DETAILS_WRITE',
  PERMISSION_TENANT_TARIFF_READ = 'PERMISSION_TENANT_TARIFF_READ',
  PERMISSION_TENANT_TARIFF_WRITE = 'PERMISSION_TENANT_TARIFF_WRITE',
  PERMISSION_TARIFF_TEMPLATE_WRITE = 'PERMISSION_TARIFF_TEMPLATE_WRITE',
  PERMISSION_TENANT_TRANSACTION_READ = 'PERMISSION_TENANT_TRANSACTION_READ',
  PERMISSION_TENANT_BALANCE_READ = 'PERMISSION_TENANT_BALANCE_READ',
  PERMISSION_TENANT_BALANCE_ADD = 'PERMISSION_TENANT_BALANCE_ADD',
  PERMISSION_TENANT_BALANCE_SUBTRACT = 'PERMISSION_TENANT_BALANCE_SUBTRACT',
  PERMISSION_TARIFF_TEMPLATE_READ = 'PERMISSION_TARIFF_TEMPLATE_READ',
  PERMISSION_TENANT_TRANSACT_STORNED = 'PERMISSION_TENANT_TRANSACT_STORNED',
  CCB_OPERATION_CREDIT = 'CCB_OPERATION_CREDIT',
  CCB_OPERATION_CANCELLATION = 'CCB_OPERATION_CANCELLATION',
  PERMISSION_SOLUTION_ID_REDACTOR = 'SOLUTION_ID_REDACTOR',

  //  Tenant license permissions
  PERMISSION_TENANT_LICENSE_RENEW = 'PERMISSION_TENANT_LICENSE_RENEW',
}

enum ERoles {
  ROLE_USER = 'ROLE_USER',
  ROLE_BASIC_USER = 'ROLE_BASIC_USER',
  ROLE_ACCOUNT_MANAGER = 'ROLE_ACCOUNT_MANAGER',
  ROLE_SUPPORT = 'ROLE_SUPPORT',
  ROLE_SUPER_USER = 'ROLE_SUPER_USER',
  ROLE_TENANT_LICENSE_RENEW = 'ROLE_TENANT_LICENSE_RENEW',
  ROLE_TENANT_TRANSACT_STORNED = 'ROLE_TENANT_TRANSACT_STORNED',
  ROLE_ENGINEER = 'ROLE_ENGINEER',
}

enum EFeatureFlag {
  SMS_PROFILE_QUESTIONNAIRE = 'MFMSCOM-6093_sms_profile_questionnaire',
  STRIPE_AUTOPAYMENT = 'EP-3258_stripe_checkout_integration',
  WA_ES_ENABLED_FOR_ALL = 'EP-3260_wa_es_all',
  WA_ES_TENANT_EXCEPTION = 'EP-3260_wa_es_tenant_exception',
}

enum EResellerIds {
  OSK_ID = 1,
  MFM_ID = 2,
  PT_ID = 3,
  KZ_ID = 4,
  BLR_FIRST_ID = 5,
  BLR_SECOND_ID = 6,
}

enum EPaymentType {
  POSTPAID = 'POSTPAID',
  PREPAYMENT = 'PREPAYMENT',
  MIXED_PREPAYMENT = 'MIXED_PREPAYMENT',
}

type TPaymentType = keyof typeof EPaymentType;

const PAYMENT_TYPES = Object.values(EPaymentType);

enum EDocumentType {
  CONTRACT = 'CONTRACT', // Бумажный договор
  AGREEMENT = 'AGREEMENT', // Оферта
  TECH_PARTNER = 'TECH_PARTNER', // Технический партнер
  PILOT = 'PILOT', // Пилот
}

type TPaymentData = {
  operBankAccount: string | null;
  corrBankAccount: string | null;
  bankName: string | null;
  taxNumber: string | null;
  kpp: string | null;
  nonresident: boolean;
  postAddress: string | null;
  legalAddress: string | null;
  siteAddress: string | null;
  country: string | null;
  iban: string | null;
  registrationEmail: string | null;
  authorizedPersonName: string | null;
  vatNumber: string | null;
  bic: string | null;
  bankAddress: string | null;
};

type TTenant = {
  resellerId: EResellerIds;
  tenantId: number;
  paymentData: TPaymentData;
  contacts?: {
    email?: string;
    phone?: string;
    country?: string;
  };
  personalManagerId?: number;
  personalManagerName?: string;
  personalManagerEmail?: string;
  personalManagerPhone?: string;
  supportEmail?: string;
  companyName?: string;
  createdAt?: string;
  needAgreementConfirmation: boolean;
  offerContentUrl?: string;
  paymentType?: TPaymentType;
  licensePaymentTerm?: number;
  temporaryBlocked?: boolean;
  unblockAt?: string;
  locked?: boolean;
  defaultCurrency: TCurrency;
  locale?: string;
  timeZone?: string;
  country?: string;
  broadcastMaxMessageSpeed?: number;
};

type TUserTenant = Partial<
  Pick<TTenant, 'companyName' | 'locked' | 'temporaryBlocked' | 'createdAt' | 'locale'> &
    Pick<TTenant['paymentData'], 'taxNumber'>
> & {
  id: number;
  currencyCode: TCurrency;
};

type TUser = {
  id: number;
  login: string;
  name: string;
  roles: ERoles[];
  permissions: EPermission[];
  tenants: TUserTenant[];
  resellerId: EResellerIds;
  timeZone?: string;
  description?: string;
};

type TBankDetails = {
  id: number;
  resellerId: EResellerIds;
  beneficiaryBank: string;
  ibanIdr: string;
  ibanUsd: string;
  ibanEur: string;
  bankCode: string;
  branchName: string;
  swift: string;
  iban: string;
  accountNumber: string;
  currency: TCurrency;
  paymentType: TPaymentType;
  resellerName: string;
  resellerAddress: string;
  resellerRegNum: string;
  resellerVat: string;
  comment: string;
};

type TTenantUser = {
  id: number;
  login: string;
};

export {
  EPaymentType,
  PAYMENT_TYPES,
  EDocumentType,
  EPermission,
  ERoles,
  EResellerIds,
  EFeatureFlag,
};
export type {TTenant, TUserTenant, TPaymentType, TBankDetails, TUser, TTenantUser};
