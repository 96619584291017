import {matchPath, useLocation} from 'react-router';

import {EUserCompanyProfileTabs} from 'src/containers/UserCompanyProfile/definitions';

export type TPathPostfix = typeof EDIT_POSTFIX | typeof VIEW_POSTFIX | typeof NEW_POSTFIX;

export const AUTH_PATH_PREFIX = '/auth';
export const EDIT_POSTFIX = 'edit';
export const NEW_POSTFIX = 'new';
export const VIEW_POSTFIX = 'view';
export const CHANNELS_PREFIX = '/channels';
export const MESSAGE_MATCHERS_PREFIX = '/message-matchers';
export const TRIGGERS_PREFIX = '/triggers';
export const SUPPORT_PREFIX = '/support/clients';

const PATHS = {
  HOME: '/',
  UNAVAILABLE: '/unavailable',

  // Public
  PUBLIC_DOCS: '/public/docs',
  PUBLIC_WHATSAPP_REGISTRATION: `/public/register-whatsapp/:code`,

  // Auth
  SIGN_IN: `${AUTH_PATH_PREFIX}/signin`,
  PASSWORD_SAVED: `${AUTH_PATH_PREFIX}/password-saved`,
  SIGN_IN_BY_INVITE: `${AUTH_PATH_PREFIX}/signin/:hash`,
  EMAIL_CONFIRM: `${AUTH_PATH_PREFIX}/email-confirm`,

  // Channels
  CHANNELS_LIST: CHANNELS_PREFIX,
  CHANNELS_WHATSAPP_NEW: `${CHANNELS_PREFIX}/whatsapp/${NEW_POSTFIX}`,
  CHANNELS_WHATSAPP_EDIT: `${CHANNELS_PREFIX}/whatsapp/:id/${EDIT_POSTFIX}`,
  CHANNELS_WHATSAPP_VIEW: `${CHANNELS_PREFIX}/whatsapp/:id/${VIEW_POSTFIX}`,
  CHANNELS_EMBEDDED_WHATSAPP_NEW: `${CHANNELS_PREFIX}/embedded-whatsapp/${NEW_POSTFIX}`,
  CHANNELS_EMBEDDED_WHATSAPP_EDIT: `${CHANNELS_PREFIX}/embedded-whatsapp/:id/${EDIT_POSTFIX}`,
  CHANNELS_EMBEDDED_WHATSAPP_VIEW: `${CHANNELS_PREFIX}/embedded-whatsapp/:id/${VIEW_POSTFIX}`,
  CHANNELS_WHATSAPP_TECH_PARTNER_NEW: `${CHANNELS_PREFIX}/whatsapp-tech-partner/${NEW_POSTFIX}`,
  CHANNELS_WHATSAPP_TECH_PARTNER_EDIT: `${CHANNELS_PREFIX}/whatsapp-tech-partner/:id/${EDIT_POSTFIX}`,
  CHANNELS_WHATSAPP_TECH_PARTNER_VIEW: `${CHANNELS_PREFIX}/whatsapp-tech-partner/:id/${VIEW_POSTFIX}`,
  CHANNELS_VIBER_NEW: `${CHANNELS_PREFIX}/viber/${NEW_POSTFIX}`,
  CHANNELS_VIBER_EDIT: `${CHANNELS_PREFIX}/viber/:id/${EDIT_POSTFIX}`,
  CHANNELS_VIBER_VIEW: `${CHANNELS_PREFIX}/viber/:id/${VIEW_POSTFIX}`,
  CHANNELS_SMS_NEW: `${CHANNELS_PREFIX}/sms/${NEW_POSTFIX}`,
  CHANNELS_SMS_EDIT: `${CHANNELS_PREFIX}/sms/:id/${EDIT_POSTFIX}`,
  CHANNELS_SMS_VIEW: `${CHANNELS_PREFIX}/sms/:id/${VIEW_POSTFIX}`,
  CHANNELS_INSTAGRAM_NEW: `${CHANNELS_PREFIX}/instagram/${NEW_POSTFIX}`,
  CHANNELS_INSTAGRAM_VIEW: `${CHANNELS_PREFIX}/instagram/:id/${VIEW_POSTFIX}`,
  CHANNELS_PUSH_NEW: `${CHANNELS_PREFIX}/push/${NEW_POSTFIX}`,
  CHANNELS_PUSH_LIST: `${CHANNELS_PREFIX}/push/:pushId/apps`,
  CHANNELS_PUSH_APP_DATA: `${CHANNELS_PREFIX}/push/:pushId/apps/:appType/:appId/data`,
  CHANNELS_PUSH_APP_NEW: `${CHANNELS_PREFIX}/push/:pushId/apps/:appType/${NEW_POSTFIX}`,
  CHANNELS_PUSH_IOS_NEW_STEP: `${CHANNELS_PREFIX}/push/:pushId/apps/ios/${NEW_POSTFIX}/:step`,
  CHANNELS_PUSH_ANDROID_NEW_STEP: `${CHANNELS_PREFIX}/push/:pushId/apps/android/${NEW_POSTFIX}/:step`,
  CHANNELS_PUSH_IOS_EDIT: `${CHANNELS_PREFIX}/push/:pushId/apps/ios/:appId/${EDIT_POSTFIX}/:step`,
  CHANNELS_PUSH_ANDROID_EDIT: `${CHANNELS_PREFIX}/push/:pushId/apps/android/:appId/${EDIT_POSTFIX}/:step`,
  CHANNELS_VK_NOTIFY_NEW: `${CHANNELS_PREFIX}/vk-notify/${NEW_POSTFIX}`,
  CHANNELS_VK_NOTIFY_EDIT: `${CHANNELS_PREFIX}/vk-notify/:id/${EDIT_POSTFIX}`,
  CHANNELS_VK_NOTIFY_VIEW: `${CHANNELS_PREFIX}/vk-notify/:id/${VIEW_POSTFIX}`,
  CHANNELS_OK_NOTIFY_NEW: `${CHANNELS_PREFIX}/ok-notify/${NEW_POSTFIX}`,
  CHANNELS_OK_NOTIFY_EDIT: `${CHANNELS_PREFIX}/ok-notify/:id/${EDIT_POSTFIX}`,
  CHANNELS_OK_NOTIFY_VIEW: `${CHANNELS_PREFIX}/ok-notify/:id/${VIEW_POSTFIX}`,

  // Cascades
  CASCADES_NEW: `/cascades/${NEW_POSTFIX}`,
  CASCADES_VIEW: `/cascades/:id/${VIEW_POSTFIX}`,
  CASCADES_LIST: '/cascades',

  // Broadcasts
  BROADCASTS_LIST: '/broadcasts',
  BROADCASTS_NEW: `/broadcasts/${NEW_POSTFIX}`,
  BROADCASTS_DETAILS: '/broadcasts/:id/details',
  BROADCASTS_EDIT: '/broadcasts/:id/:action/:step',

  // Subscribers
  SUBSCRIBERS_LIST: '/audience',
  SUBSCRIBERS_NEW: `/audience/${NEW_POSTFIX}`,
  SUBSCRIBERS_GROUP_EDIT: `/audience/add-list/${EDIT_POSTFIX}`,
  SUBSCRIBERS_EDIT: `/audience/:id/${EDIT_POSTFIX}`,

  // Message matchers
  MESSAGE_MATCHERS_LIST: MESSAGE_MATCHERS_PREFIX,
  MESSAGE_MATCHERS_WHATSAPP_NEW: `${MESSAGE_MATCHERS_PREFIX}/whatsapp/${NEW_POSTFIX}`,
  MESSAGE_MATCHERS_WHATSAPP_EDIT: `${MESSAGE_MATCHERS_PREFIX}/whatsapp/:id/${EDIT_POSTFIX}`,
  MESSAGE_MATCHERS_WHATSAPP_VIEW: `${MESSAGE_MATCHERS_PREFIX}/whatsapp/:id/${VIEW_POSTFIX}`,
  MESSAGE_MATCHERS_VIBER_NEW: `${MESSAGE_MATCHERS_PREFIX}/viber/${NEW_POSTFIX}`,
  MESSAGE_MATCHERS_VIBER_EDIT: `${MESSAGE_MATCHERS_PREFIX}/viber/:id/${EDIT_POSTFIX}`,
  MESSAGE_MATCHERS_VIBER_VIEW: `${MESSAGE_MATCHERS_PREFIX}/viber/:id/${VIEW_POSTFIX}`,
  MESSAGE_MATCHERS_SMS_NEW: `${MESSAGE_MATCHERS_PREFIX}/sms/${NEW_POSTFIX}`,
  MESSAGE_MATCHERS_SMS_EDIT: `${MESSAGE_MATCHERS_PREFIX}/sms/:id/${EDIT_POSTFIX}`,
  MESSAGE_MATCHERS_SMS_VIEW: `${MESSAGE_MATCHERS_PREFIX}/sms/:id/${VIEW_POSTFIX}`,
  MESSAGE_MATCHERS_INSTAGRAM_NEW: `${MESSAGE_MATCHERS_PREFIX}/instagram/${NEW_POSTFIX}`,
  MESSAGE_MATCHERS_INSTAGRAM_EDIT: `${MESSAGE_MATCHERS_PREFIX}/instagram/:id/${EDIT_POSTFIX}`,
  MESSAGE_MATCHERS_INSTAGRAM_VIEW: `${MESSAGE_MATCHERS_PREFIX}/instagram/:id/${VIEW_POSTFIX}`,
  MESSAGE_MATCHERS_PUSH_NEW: `${MESSAGE_MATCHERS_PREFIX}/push/${NEW_POSTFIX}`,
  MESSAGE_MATCHERS_PUSH_EDIT: `${MESSAGE_MATCHERS_PREFIX}/push/:id/${EDIT_POSTFIX}`,
  MESSAGE_MATCHERS_PUSH_VIEW: `${MESSAGE_MATCHERS_PREFIX}/push/:id/${VIEW_POSTFIX}`,
  MESSAGE_MATCHERS_OK_NOTIFY_NEW: `${MESSAGE_MATCHERS_PREFIX}/ok-notify/${NEW_POSTFIX}`,
  MESSAGE_MATCHERS_OK_NOTIFY_EDIT: `${MESSAGE_MATCHERS_PREFIX}/ok-notify/:id/${EDIT_POSTFIX}`,
  MESSAGE_MATCHERS_OK_NOTIFY_VIEW: `${MESSAGE_MATCHERS_PREFIX}/ok-notify/:id/${VIEW_POSTFIX}`,
  MESSAGE_MATCHERS_VK_NOTIFY_NEW: `${MESSAGE_MATCHERS_PREFIX}/vk-notify/${NEW_POSTFIX}`,
  MESSAGE_MATCHERS_VK_NOTIFY_EDIT: `${MESSAGE_MATCHERS_PREFIX}/vk-notify/:id/${EDIT_POSTFIX}`,
  MESSAGE_MATCHERS_VK_NOTIFY_VIEW: `${MESSAGE_MATCHERS_PREFIX}/vk-notify/:id/${VIEW_POSTFIX}`,

  TRIGGERS_LIST: TRIGGERS_PREFIX,
  TRIGGERS_NEW: `${TRIGGERS_PREFIX}/${NEW_POSTFIX}`,
  TRIGGERS_EDIT: `${TRIGGERS_PREFIX}/:id/${EDIT_POSTFIX}`,
  TRIGGERS_VIEW: `${TRIGGERS_PREFIX}/:id/${VIEW_POSTFIX}`,

  SUPPORT_TRIGGERS_LIST: `${SUPPORT_PREFIX}/:tenantId${TRIGGERS_PREFIX}`,
  SUPPORT_TRIGGERS_VIEW: `${SUPPORT_PREFIX}/:tenantId${TRIGGERS_PREFIX}/:id/${VIEW_POSTFIX}`,

  // Company
  COMPANY_PROFILE: `/company/profile/*`,
  COMPANY_PROFILE_TAB: `/company/profile/:tab/*`,
  COMPANY_PROFILE_PAYMENT: `/company/profile/${EUserCompanyProfileTabs.PAYMENT}/:paymentTab`,
  COMPANY_CLIENTS: '/company/clients',
  COMPANY_CLIENT_NEW: `/company/clients/${NEW_POSTFIX}`,
  COMPANY_CLIENT_CARD: '/company/clients/:id',

  // Support
  SUPPORT_CLIENTS: SUPPORT_PREFIX,
  SUPPORT_CLIENT_CARD: `${SUPPORT_PREFIX}/:id/*`,
  SUPPORT_CLIENT_CARD_TAB: `${SUPPORT_PREFIX}/:id/:tab/*`,
  SUPPORT_CLIENT_CARD_PAYMENT: `${SUPPORT_PREFIX}/:id/${EUserCompanyProfileTabs.PAYMENT}/:paymentTab`,
  SUPPORT_INVOICE_ASSIGN: `/support/invoice/assign`,
  SUPPORT_INVOICE_CANCEL: `/support/invoice/cancel`,

  // Support message matchers and analytics
  SUPPORT_MESSAGE_MATCHERS_LIST: `${SUPPORT_PREFIX}/:tenantId${MESSAGE_MATCHERS_PREFIX}`,
  SUPPORT_MESSAGE_MATCHERS_WHATSAPP_VIEW: `${SUPPORT_PREFIX}/:tenantId${MESSAGE_MATCHERS_PREFIX}/whatsapp/:id/${VIEW_POSTFIX}`,
  SUPPORT_MESSAGE_MATCHERS_VIBER_VIEW: `${SUPPORT_PREFIX}/:tenantId${MESSAGE_MATCHERS_PREFIX}/viber/:id/${VIEW_POSTFIX}`,
  SUPPORT_MESSAGE_MATCHERS_SMS_VIEW: `${SUPPORT_PREFIX}/:tenantId${MESSAGE_MATCHERS_PREFIX}/sms/:id/${VIEW_POSTFIX}`,
  SUPPORT_MESSAGE_MATCHERS_INSTAGRAM_VIEW: `${SUPPORT_PREFIX}/:tenantId${MESSAGE_MATCHERS_PREFIX}/instagram/:id/${VIEW_POSTFIX}`,
  SUPPORT_MESSAGE_MATCHERS_PUSH_VIEW: `${SUPPORT_PREFIX}/:tenantId${MESSAGE_MATCHERS_PREFIX}/push/:id/${VIEW_POSTFIX}`,
  SUPPORT_MESSAGE_MATCHERS_OK_NOTIFY_VIEW: `${SUPPORT_PREFIX}/:tenantId${MESSAGE_MATCHERS_PREFIX}/ok-notify/:id/${VIEW_POSTFIX}`,
  SUPPORT_MESSAGE_MATCHERS_VK_NOTIFY_VIEW: `${SUPPORT_PREFIX}/:tenantId${MESSAGE_MATCHERS_PREFIX}/vk-notify/:id/${VIEW_POSTFIX}`,
  SUPPORT_ANALYTICS_MESSAGES: `${SUPPORT_PREFIX}/:tenantId/analytics/messages`,
  SUPPORT_ANALYTICS_SUMMARY_REPORT: `${SUPPORT_PREFIX}/:tenantId/analytics/channels`,
  SUPPORT_ANALYTICS_CHANNEL: `${SUPPORT_PREFIX}/:tenantId/analytics/channels/:type/:id`,

  //Integration
  INTEGRATION: '/integration/*',
  INTEGRATION_TAB: '/integration/:tab',

  // Analytics
  ANALYTICS_MESSAGES: '/analytics/messages',
  ANALYTICS_SUMMARY_REPORT: '/analytics/channels',
  ANALYTICS_CHANNEL: '/analytics/channels/:type/:id',

  // Corrections
  CORRECTIONS: '/corrections/*',
  CORRECTIONS_TAB: '/corrections/:tab',

  // Notifications
  NOTIFICATIONS: '/event-log/*',
  NOTIFICATIONS_TAB: '/event-log/:tab',

  // Sundry
  PROFILE: '/profile',
  AUDIENCE: '/audience',
  CONTENT_MANAGER: '/content-manager',
  HELP: '/help',
  SIGN_OUT: '/signout',
  FILES: '/files',
};

export default PATHS;

export const useViewMatch = () => {
  const {pathname} = useLocation();

  return pathname.endsWith(VIEW_POSTFIX);
};

export const getMatchedPath = (pathname: string) =>
  Object.values(PATHS).find((path) => matchPath(path, pathname));
