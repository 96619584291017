import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {TItemId} from 'src/constants';

import {closeNews, selectIsNewsOpenById} from './slice';

const useNewsState = (id: TItemId) => {
  const isOpen = useSelector(selectIsNewsOpenById(id));
  const dispatch = useDispatch();

  const handleClose = React.useCallback(() => {
    dispatch(closeNews(id));
  }, [closeNews, id]);

  return {isNewsOpen: isOpen, closeNews: handleClose};
};

export {useNewsState};
