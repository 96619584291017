export default {
  title: 'Multi-Partner Solutions setup',
  name: 'Solution name',
  tenantId: 'Tenant ID',
  solutionId: 'Solution ID',
  solutionIdError: 'SolutionID is not registered on edna Enterprise. Error "{{error}}"',
  solutionNameError: 'Solution name is not registered on edna Enterprise. Error "{{error}}"',
  solutionIdDuplicateError:
    'SolutionID {{solutionId}} has already been registered for tenant {{tenantId}}',
};
