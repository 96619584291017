import {TFunction} from 'react-i18next';

import i18n from 'i18next';
import isEmpty from 'lodash/isEmpty';

import {showNewNotification} from '@edna/components';

import {ETenantCode} from 'src/constants';
import {showTemporaryBlockModal} from 'src/containers/UserCompanyProfile/TemporaryBlockModal';

const hasError = (error?: unknown): error is Record<string, unknown> =>
  !!error && typeof error === 'object';

type TViolation = {
  field: string;
  message: string;
};

type TErrorPayload = {
  code?: string;
  title?: string;
  args?: TAnyObject;
  detail?: string;
  violations?: TViolation[];
};

type TCommonError = {
  data: TErrorPayload;
  config?: {
    url?: string;
  };
  status?: number;
};

const isCommonError = (error?: unknown): error is TCommonError => {
  return hasError(error) && 'data' in error && typeof error.data === 'object';
};

const isErrorWithCode = (error?: unknown): error is TCommonError => {
  if (!isCommonError(error)) {
    return false;
  }

  const code =
    'code' in error.data ? error.data.code : 'title' in error.data ? error.data.title : null;

  return typeof code === 'string';
};

const getErrorCode = (error?: unknown) => {
  if (!isErrorWithCode(error)) {
    return;
  }

  return error.data.code || error.data.title;
};

type TGetErrorMessage = (
  t: TFunction,
  error: unknown,
  i18nOptions?: {violations?: Record<string, TViolation>} & TAnyObject,
  messageKey?: string,
) => string;

const getErrorMessage: TGetErrorMessage = (t, error, i18nOptions, messageKey) => {
  const strings = [];

  if (messageKey) {
    strings.push(t(messageKey, i18nOptions));
  }

  if (isErrorWithCode(error)) {
    if (i18nOptions && !isEmpty(i18nOptions.violations)) {
      Object.values(i18nOptions.violations).forEach((value) => {
        strings.push(t(`Errors:violations.${value?.field}`, ''));
      });
    } else {
      const code = getErrorCode(error);

      strings.push(t([`Errors:${code}`, 'Errors:unknownErrorCode'], {...i18nOptions, code}));
    }
  }

  if (isEmpty(strings)) {
    const status = hasError(error) ? error.status : null;

    strings.push(t([`Errors:httpStatus.${status}`, 'Errors:unexpectedError']));
  }

  return strings.join(' ');
};

const showErrorNotification = (error: unknown, messageKey?: string, i18nOptions?: TAnyObject) => {
  if (isErrorWithCode(error) && getErrorCode(error) === ETenantCode.TENANT_TEMPORARY_BLOCKED) {
    showTemporaryBlockModal(error.config?.url, error.data.detail);

    return;
  }

  const options = isCommonError(error)
    ? {
        violations: {...error.data.violations},
        ...error.data.args,
        ...i18nOptions,
      }
    : i18nOptions;

  showNewNotification({
    type: 'error',
    message: getErrorMessage(i18n.t, error, options, messageKey),
  });
};

export {showErrorNotification, getErrorMessage, getErrorCode, isErrorWithCode};
