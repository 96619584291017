import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';

import {createListenerMiddleware, isAllOf, isFulfilled, isRejected} from '@reduxjs/toolkit';
import {BaseQueryFn, createApi} from '@reduxjs/toolkit/query/react';
import intersection from 'lodash/intersection';

import {showNewNotification} from '@edna/components';

import {ECacheTag, RESERVED_META_KEYS, THeaders, TMeta} from './definitions';
import {showErrorNotification} from './errorNotification';
import {isMatchQueryMeta} from './utils';

const axiosInstance = axios.create();

// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#examples---basequery
const axiosBaseQuery =
  (
    {baseUrl}: {baseUrl: string} = {baseUrl: ''},
  ): BaseQueryFn<
    {
      url: string;
      baseUrl?: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
      responseType?: AxiosRequestConfig['responseType'];
      meta?: TMeta;
    },
    unknown,
    AxiosResponse,
    unknown,
    TMeta
  > =>
  async (
    {baseUrl: customBaseUrl, url, method, data, params, responseType, headers = {}, meta = {}},
    api,
  ) => {
    const intersectedMetaKeys = intersection(RESERVED_META_KEYS, Object.keys(meta));

    if (intersectedMetaKeys.length) {
      throw new Error(
        `Reserved meta keys: ${intersectedMetaKeys.join(', ')} were used. Choose a different key`,
      );
    }

    const userPermissions = (api.getState() as TRootState).user.permissions ?? [];
    const userRoles = (api.getState() as TRootState).user.roles ?? [];

    if (
      (meta.permissions && !meta.permissions(userPermissions)) ||
      (meta.roles && !meta.roles(userRoles))
    ) {
      return {
        data: undefined,
        meta: {},
      };
    }

    const queryConfig = {
      url: `${customBaseUrl ?? baseUrl}${url}`,
      method,
      data,
      params,
      responseType,
      headers,
    };

    try {
      const result = await axiosInstance(queryConfig);

      return {data: result.data || {}, meta: {...meta, headers: result.headers as THeaders}};
    } catch (error) {
      return {error: error as AxiosResponse, meta: {...meta, isBackendError: true}};
    }
  };

const rootApi = createApi({
  reducerPath: 'rootApi',
  tagTypes: Object.values(ECacheTag),
  baseQuery: axiosBaseQuery({baseUrl: '/rest'}),
  endpoints: () => ({}),
});

const listenerMiddleware = createListenerMiddleware<TRootState>();

listenerMiddleware.startListening({
  matcher: isAllOf(isRejected, isMatchQueryMeta({isShowError: true, isBackendError: true})),
  effect: (action) => {
    showErrorNotification(
      action.payload,
      action.meta.baseQueryMeta.errorMessageKey,
      action.meta.baseQueryMeta.i18nOptions,
    );
  },
});

listenerMiddleware.startListening({
  matcher: isAllOf(isFulfilled, isMatchQueryMeta({isShowSuccess: true})),
  effect: (action) => {
    showNewNotification({
      type: 'success',
      messageKey: action.meta.baseQueryMeta.successMessageKey,
      messageArgs: action.meta.baseQueryMeta.i18nOptions,
    });
  },
});

export {rootApi, listenerMiddleware, axiosBaseQuery, axiosInstance};
