import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {generatePath} from 'react-router-dom';

import {formatDate} from '@edna/utils';

import {Link, RouterLink} from 'src/components/primitives';
import {News, NewsDate, StyledNewsDate, useNewsDate} from 'src/containers/News';
import {EUserCompanyProfileTabs} from 'src/containers/UserCompanyProfile/definitions';
import {MaintenanceImage, TariffImage} from 'src/images';
import PATHS from 'src/paths';

import {ESystemNewsType, TReleaseNews, TSystemNews} from '../model';
import {TWhatsAppLicensPriceNews} from '../model/systemNews';

type TProps = {
  item: TSystemNews;
};

const UpdatePlanned = React.memo<{item: TReleaseNews}>(({item}) => {
  const {t} = useTranslation();

  const date = useNewsDate(item.meta.startAt);
  const startTime = formatDate(new Date(item.meta.startAt), {format: 'HH:mm'});
  const endTime = formatDate(new Date(item.meta.endAt), {format: 'HH:mm'});

  return (
    <News
      id={item.id}
      title={t(`Notifications:news.${item.type}.title`, {date})}
      image={MaintenanceImage}
    >
      <Trans i18nKey={`Notifications:news.${item.type}.content`} values={{startTime, endTime}}>
        <NewsDate date={item.meta.startAt} isOrdinal />
      </Trans>
    </News>
  );
});

const WhatsAppLicensePriceUpdatePlannedContent = React.memo<{item: TWhatsAppLicensPriceNews}>(
  ({item}) => {
    return (
      <Trans i18nKey={`Notifications:news.${item.type}.content`}>
        <StyledNewsDate />
        <Link
          as={RouterLink}
          to={generatePath(PATHS.COMPANY_PROFILE_TAB, {
            tab: EUserCompanyProfileTabs.PRICING,
          })}
        />
      </Trans>
    );
  },
);

const WhatsAppLicensePriceUpdatePlanned = React.memo<{item: TWhatsAppLicensPriceNews}>(({item}) => {
  const {t} = useTranslation();

  return (
    <News id={item.id} title={t(`Notifications:news.${item.type}.title`)} image={TariffImage}>
      <WhatsAppLicensePriceUpdatePlannedContent item={item} />
    </News>
  );
});

const SystemNews = React.memo<TProps>(({item}) => {
  if (!item?.type) {
    return null;
  }

  switch (item.type) {
    case ESystemNewsType.UpdatePlanned:
      return <UpdatePlanned item={item} />;

    case ESystemNewsType.WhatsAppLicensePriceUpdatePlanned:
      return <WhatsAppLicensePriceUpdatePlanned item={item} />;

    default:
      return null;
  }
});

SystemNews.displayName = 'SystemNews';

export {SystemNews, WhatsAppLicensePriceUpdatePlannedContent};
