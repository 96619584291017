import React from 'react';
import {useTranslation} from 'react-i18next';

import {Message} from '../Message';
import {FacebookButton} from './FacebookButton';

type TProps = {
  onClick: TEmptyFunction;
  text?: string | React.ReactNode;
  isLoading?: boolean;
  label?: string;
};

const FacebookLogin = React.memo<TProps>(({onClick, text, label, isLoading}) => {
  const {t} = useTranslation();

  return (
    <Message type="Info" fontSize="S">
      {text}
      <FacebookButton
        onClick={onClick}
        isLoading={isLoading}
        label={label ?? t('Facebook:label')}
      />
    </Message>
  );
});

FacebookLogin.displayName = 'FacebookLogin';

export {FacebookLogin};
