import {EDocumentType} from 'src/containers/Billing';

export default {
  th: {
    uploadedAt: 'Transaction date',
    document: 'Document',
  },
  filters: {
    title: 'Filters',
    placeholder: 'All document types',
    reset: 'Reset',
  },
  button: {
    downloadItem: 'Download .pdf',
    uploadDocument: 'Upload document',
  },
  uploadDocumentModal: {
    title: 'Upload documents',
    chooseTypeLabel: 'Select the type of document you want to upload',
    chooseTypePlaceholder: 'Select type',
    timeAndDateLabel: 'Set the displayed document loading time',
    attachDocument: 'Attach file',
    attachAnotherDocument: 'Attach another file',
  },
  documentTypes: {
    [EDocumentType.ALL]: 'All documents',
    [EDocumentType.INVOICE]: 'Tax invoice',
    [EDocumentType.ACCOUNTING_BILL]: 'Accounting bill',
    [EDocumentType.ACCEPTANCE_ACT]: 'Acceptance act',
    [EDocumentType.LICENSE_BILL]: 'License payment invoice',
    [EDocumentType.PAYMENT_BILL]: 'Payment invoice',
    [EDocumentType.OTHER]: 'Other',
  },
  successUploadModal: {
    title: 'The document has been successfully uploaded',
    subtitle:
      'The document you uploaded is now available in the document list in your edna dashboard',
  },
  title: `Client's documents`,
};
