import {ESystemNotificationEntityType, TGenericNotification} from './definitions';

type TGenericNews<T, M = TAnyObject> = TGenericNotification<T, M> & {
  entityType: ESystemNotificationEntityType.NEWS;
};

enum ESystemNewsType {
  UpdatePlanned = 'UpdatePlanned',
  WhatsAppLicensePriceUpdatePlanned = 'WhatsAppLicensePriceUpdatePlanned',
}

type TReleaseNews = TGenericNews<
  ESystemNewsType.UpdatePlanned,
  {
    startAt: string;
    endAt: string;
  }
>;

type TWhatsAppLicensPriceNews = TGenericNews<ESystemNewsType.WhatsAppLicensePriceUpdatePlanned>;

type TSystemNews = TReleaseNews | TWhatsAppLicensPriceNews;

export type {TSystemNews, TReleaseNews, TWhatsAppLicensPriceNews};
export {ESystemNewsType};
