import {EAutopaymentError} from '../Autopayment';
import {EApproveStatus} from '../MessageMatchers/definitions';
import {
  EAutopaymentNotificationStatus,
  ESystemNewsType,
  ESystemNotificationType,
  EWebNotificationType,
} from './model';

export default {
  button: {
    downloadErrorFile: 'Download error file',
    toAudienceSection: 'To the audience section',
    contactTechnicalSupport: 'Contact technical support',
    toReportBroadcast: 'To the broadcast report',
  },
  news: {
    [ESystemNewsType.UpdatePlanned]: {
      title: '{{date}} Update',
      content:
        "edna Pulse will be updated on <0></0>. We recommend that you don't schedule or send broadcasts between {{startTime}} and {{endTime}} due to short software interruptions.\n" +
        'We try to be the best for you! We hope you enjoy this update.',
    },
    [ESystemNewsType.WhatsAppLicensePriceUpdatePlanned]: {
      title: '[en] Изменение стоимости лицензии WhatsApp',
      content:
        '[en] С <0>1 апреля</0> повышается стоимость вашей лицензии WhatsApp Business API. С новой стоимостью вы можете ознакомиться в разделе <1>Профиль -> Профиль Компании -> Тарифы</1>. Учитывайте это при оплате лицензии за апрель, чтобы продолжать пользоваться сервисом.' +
        '<br/><br/>Мы не повышали цены на WhatsApp Business API почти 2 года, но больше не можем бороться с возросшими затратами. В связи со значительным ростом расходов на закупку нового инфраструктурного оборудования, поддержку текущего оборудования, оплату высококвалифицированных ИТ-специалистов и существующей инфляцией на другие компоненты нашей работы, мы вынуждены поднять стоимость лицензии. Кроме этого на платформе edna были проведены глобальные доработки, которые позволят работать с сервисом ещё лучше.' +
        '<br/>При возникновение вопросов обратитесь к вашему менеджеру edna.',
    },
  },
  system: {
    [ESystemNotificationType.UpdateRunning]:
      '{{date}} from {{startTime}} to {{endTime}} <0>edna Pulse update</0>. During this time, short software interruptions may occur.',
    [ESystemNotificationType.MaintenanceRunning]:
      'There are short software interruptions in edna Pulse. We are already working on fixing them!',
  },
  toUploads: 'To downloads',
  toNotifications: 'To notifications',
  web: {
    [EWebNotificationType.SubscribersUploadFailed]: `The audience list has not been loaded.`,
    [EWebNotificationType.SubscribersUploadPartial]: `The audience list has not been fully loaded.`,
    [EWebNotificationType.SubscribersUploadSuccess]: `The audience list has been loaded.`,
    [EWebNotificationType.BroadcastCompleted]: `The broadcast "{{name}}" is completed.`,
    [EWebNotificationType.BroadcastCancelled]: `The broadcast "{{name}}" has been stopped.`,
    [EWebNotificationType.LicenseExpiring]:
      'Your license for edna Pulse expires {{date}}. To renew the license, please contact your personal manager: {{name}} (<0>{{email}}</0>)',
    [EWebNotificationType.CallbackUpdated]: `Callback URL for broadcast statuses has been successfully changed.`,
    [EWebNotificationType.ApikeyUserUpdated]: `The new API key has been successfully generated.`,
    [EWebNotificationType.CompanyDetailsUpdated]: `User "{{login}}" has changed the company details.`,
    [EWebNotificationType.PaymentDetailsUpdated]: `User "{{login}}" has changed the company payment details.`,
    [EWebNotificationType.TenantTariffAccepted]: `User "{{login}}" has agreed to the available pricing options.`,
    [EWebNotificationType.AgreedChangeTariffRequest]: `User "{{login}}" has accepted the pricing changes for <b>{{tariffName}}</b>.`,
    [EWebNotificationType.NotAgreedChangeTariffRequest]: `User "{{login}}" has rejected the pricing changes for <b>{{tariffName}}</b>.`,
    [EWebNotificationType.JivoEnabled]:
      'User {{login}} has connected all the WhatsApp channel traffic to the Jivo chat platform.',
    [EWebNotificationType.JivoDisabled]:
      'User {{login}} has disconnected all the WhatsApp channel traffic to the Jivo chat platform.',
    [EWebNotificationType.MessageMatcherStatusChanged]: {
      [EApproveStatus.DISABLED]:
        'Template "{{name}}" was disabled. All scheduled broadcasts using this template are stopped',
      [EApproveStatus.PAUSED]:
        'Template "{{name}}" was paused. All scheduled broadcasts using this template are stopped.',
      [EApproveStatus.APPROVED]: 'Template "{{name}}" status changed. New status: Active.',
      [EApproveStatus.REJECTED]: 'Template "{{name}}" status changed. New status: Rejected.',
      [EApproveStatus.PENDING]:
        'Template "{{name}}" status changed. New status: Pending registration.',
      [EApproveStatus.NOT_SENT]: 'Template "{{name}}" status changed. New status: Draft.',
      [EApproveStatus.ARCHIVED]: 'Template "{{name}}" status changed. New status: Archived.',
    },
    [EWebNotificationType.MessageMatcherCategoryChanged]: `The category of WhatsApp template "{{name}}" was changed by WhatsApp. New category: "{{newCategory}}".`,
    [EWebNotificationType.InvoicePaid]: 'Payment successful.',
    [EWebNotificationType.ChannelProfilePartnerProfileCreated]:
      'Select the channel pricing for the WhatsApp account "{{name}}" to complete the registration.',
    [EWebNotificationType.ChannelProfileQualityScoreChanged]:
      'WhatsApp Business API {{name}}: quality changed. New quality: {{qualityScore}}.',
    [EWebNotificationType.ChannelProfileMessagingLimitChanged]:
      'WhatsApp Business API {{name}}: limit changed. New limit: {{messagingLimit}}.',
    [EWebNotificationType.ChannelProfileQualityStatusChanged]:
      'WhatsApp account {{name}} status changed. New status: {{qualityStatus}}.',
    [EWebNotificationType.ChannelProfileWABAViolation]:
      'WhatsApp Business account {{wabaId}} is violating the WhatsApp Business Terms of Service. The reason is {{violationType}}. The following channels may be restricted or disabled: {{channels}}. We recommend that you <0>follow the recommendations</0>.',
    [EWebNotificationType.ChannelProfileWABARestriction]:
      'WhatsApp Business account {{wabaId}} has been restricted due to activity that violates the WhatsApp Business Terms of Service. The following channels are restricted until {{expirationDate}} and can only receive incoming messages: {{channels}}. To submit an appeal, <0>follow the recommendations</0>.',
    [EWebNotificationType.ChannelProfileWABADisable]:
      'WhatsApp Business account {{wabaId}} has been disabled due to activity that violates the WhatsApp Business Terms of Service. The following channels have stopped working: {{channels}}. You cannot register new channels with this WhatsApp Business account. To submit an appeal, <0>follow the recommendations</0>.',
    [EWebNotificationType.ChannelProfileWABAReinstate]:
      'WhatsApp Business account {{wabaId}} has been restored. The following channels become active again: {{channels}}.',
    [EWebNotificationType.StripeCheckout]: {
      [EAutopaymentError.GENERIC_DECLINE]:
        'The auto payment failed on {{date}}. For more information, contact technical support <0>{{email}}</0>.',
      [EAutopaymentError.AUTHENTICATION_REQUIRED]:
        'The auto payment failed on {{date}}. Your bank has requested payment confirmation.',
      [EAutopaymentError.CARD_DECLINE]: 'Failed to make an auto payment with the linked card.',
      [EAutopaymentError.INSUFFICIENT_FUNDS]: 'The charge failed on {{date}}. Insufficient funds.',
      [EAutopaymentNotificationStatus.AUTO_REPLENISHMENT_ENABLED]: 'Auto payment activated.',
      [EAutopaymentNotificationStatus.AUTO_REPLENISHMENT_DISABLED]: 'Auto payment deactivated.',
      [EAutopaymentNotificationStatus.PAYMENT_SUCCEEDED]:
        'Auto payment activated. To receive a payment receipt, follow the link to the <0>Stripe payment system website</0>.',
    },
    [EWebNotificationType.MessageReportSuccess]: 'The report {{name}} has been generated.',
    [EWebNotificationType.MessageReportFailed]: '{{name}} report generation has been cancelled.',
  },
};
