import React from 'react';
import {animated} from 'react-spring';

import styled, {css} from 'styled-components';

import {TSize} from '@edna/components';
import {mixins} from '@edna/components/styles';
import BaseCloseIcon from '@edna/icons/close.svg';

import {SecondaryText} from 'src/components/primitives';

import BaseStatusIcon from '../StatusIcon';

export const MessageWrapper = styled.div`
  display: flex;
`;

type TMessageProps = {
  noMargin?: boolean;
  shadowSize?: TSize;
};

export const Message = styled.div<TMessageProps>`
  display: flex;
  overflow: auto;
  width: 100%;
  ${({noMargin, shadowSize = 'S', theme}) => css`
    margin-bottom: ${noMargin ? 0 : theme.spacing.s5};
    padding: ${theme.spacing.s4};
    border-radius: ${theme.borderRadius.br4};
    box-shadow: ${theme.boxShadow[shadowSize]};
  `}
`;

export const StatusIcon = styled(BaseStatusIcon)`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: ${({theme}) => theme.spacing.s3};
`;

export const Content = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

type TTitleProps = {
  hasMargin: boolean;
};

export const Title = styled.div<TTitleProps>`
  ${({theme, hasMargin}) => css`
    margin-bottom: ${hasMargin ? theme.spacing.s2 : 0};
    color: ${theme.branding.content.secondary};
  `}
`;

export const Text = styled(SecondaryText)`
  white-space: pre-line;
`;

export const CloseIcon = styled(BaseCloseIcon)<{size: React.CSSProperties['width']}>`
  flex-shrink: 0;
  cursor: pointer;
  ${({theme: {branding, spacing}, size}) => css`
    ${mixins.wh(size)}
    color: ${branding.content.secondary};
    margin-top: ${spacing.s05};
    margin-left: ${spacing.s5};
  `}
`;

export const AnimatedDiv = styled(animated.div)`
  width: 100%;
`;
