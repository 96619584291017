import {ESmsCategory} from 'src/containers/MessageMatchers/SMSForm/definitions';
import {ELicensePeriod} from 'src/containers/Tariffs/definitions';

import {EWAConversation} from '../definitions';

export default {
  period: 'Period',
  downloadReport: 'Download report',
  hint: 'The number of message units for which you will need to pay according to your pricing option',
  more: 'More',
  direction: 'Direction',
  count: 'Count',
  cost: 'Cost, {{currencySymbol}}',
  costPerSegment: 'Cost per segment, {{currencySymbol}}',
  operator: 'Operator',
  type: 'Type',
  outgoing: 'Outgoing',
  incoming: 'Incoming',
  incomingMessages: 'Incoming messages',
  outgoingMessages: 'Outgoing messages',
  messagesCount: 'Total',
  sent: 'Sent',
  delivered: 'Delivered',
  notDelivered: 'Not delivered',
  read: 'Read',
  total: 'Total, {{currencySymbol}}',
  incomingCount: 'Number of incoming messages',
  charged: 'Charged',
  promotional: 'Promotional',
  service: 'Service',
  other: 'Other',
  viberReport: {
    minimumPayment: 'Minimum payment',
    viberMinimumPayment: 'Viber minimum payment, {{currencySymbol}}',
  },
  smsReport: {
    emptyMessages: 'No message data for the selected period',
    subscriptionFee: 'Subscription fee',
    operator: 'Operator',
    priceForMonth: 'Cost per month',
    trafficType: 'Traffic type',
    segmentCount: 'Total segments',
    multinaming: 'Multinaming',
    category: 'Category',
    averageCost: 'Message cost',
    totalCost: 'Total cost, rub.',
    currency: 'Currency',
    billingInfo:
      'The information provided is relevant when executing the final report. Data for current month may change',
    defaultInfo:
      'The information provided is preliminary and relevant when executing the final report. Accurate information will be available at the end of the reporting period',
    outgoingHint: 'Message cost depends on the number of segments in a message',
    categories: {
      [ESmsCategory.SERVICE]: 'Service',
      [ESmsCategory.AUTHORISATION]: 'Authentication',
      [ESmsCategory.TEMPLATE_ADV]: 'Advertising template',
      [ESmsCategory.ADVERTISEMENT]: 'Advertising',
    },
    download: 'Download report',
    averageCostHint:
      'If the operator uses stepped billing, the average message cost for the category is shown',
  },
  whatsappReport: {
    ndsHint: 'All prices exclude VAT',
    for: 'for',
    licenseTableTitle: 'License',
    licensePeriodTitle: 'License period',
    licenseDuration: 'License validity period',
    licensePrice: 'License cost by billing plan, {{currencySymbol}}',
    licenseBilled: 'Actual amount credited for the period, {{currencySymbol}}',
    licensePeriod: {
      [ELicensePeriod.MONTH]: 'Monthly',
      [ELicensePeriod.YEAR]: 'Annual',
    },
    mauTableTitle: 'MAU / AMAU',
    mauInPackage: 'MAU in package',
    mauQuantity: 'Current number of MAU',
    remainingMau: 'Remaining MAU',
    amauQuantity: 'Current number of AMAU',
    amauPrice: 'Price per AMAU, {{currencySymbol}}',
    totalForAmau: 'Total for AMAU, {{currencySymbol}}',
    conversations: 'Conversations',
    conversationsHint:
      "Only nonzero values are shown. This section doesn't display conversation categories that don't have conversations in the selected period.",
    free: 'Free',
    freeEntryPoints: 'Free entry points',
    chatMessages: 'Chat messages',
    hsm: 'HSM',
    user: 'User-initiated',
    business: 'Business-initiated',
    [EWAConversation.REFERRAL]: 'Free entry points',
    [EWAConversation.SERVICE]: 'Service',
    [EWAConversation.UTILITY]: 'Utility',
    [EWAConversation.AUTHENTICATION]: 'Authentication',
    [EWAConversation.MARKETING]: 'Marketing',
  },
};
