import {TItemId} from 'src/constants';

import {TSystemNews} from './systemNews';
import {TSystemNotification} from './systemNotifications';
import {TWebNotification} from './webNotifications';

enum ENotificationStatus {
  UNREAD = 'UNREAD',
  SHOWED = 'SHOWED',
  READ = 'READ',
}

type TNotificationStatus = keyof typeof ENotificationStatus;

enum ENotificationLevel {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
}

type TNotificationLevel = keyof typeof ENotificationLevel;

enum ESystemNotificationEntityType {
  NOTIFICATION = 'NOTIFICATION',
  NEWS = 'NEWS',
}

type TGenericNotification<T, M = TAnyObject> = {
  id: TItemId;
  status: TNotificationStatus;
  level: TNotificationLevel;
  occurredAt: string;
  initiator?: string;
  type: T;
  meta: M;
};

type TNotifications = {
  webNotifications: TWebNotification[];
  systemNotifications?: (TSystemNotification | TSystemNews)[];
};

const NOTIFICATIONS_POLLING_INTERVAL = 10_000;

export {
  NOTIFICATIONS_POLLING_INTERVAL,
  ENotificationStatus,
  ENotificationLevel,
  ESystemNotificationEntityType,
};
export type {TGenericNotification, TNotifications, TNotificationLevel, TNotificationStatus};
