import {TData, TPayload} from 'src/components/List';
import {TItemId} from 'src/constants';
import {CACHE_LIFETIME, ECacheTag, rootApi} from 'src/models';
import {mapNullToUndefined, mapUndefinedToNull} from 'src/utils';

import {TFlow, TFlowStep, TSchedule} from './types';

type TFlowMutation = {
  name: string;
  description: string;
  onlyOnes: boolean;
  subjectId?: number;
  startAt?: Date;
  endAt?: Date;
  schedule?: TSchedule;
};

const flowApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createFlow: builder.mutation<TFlow, TFlowMutation>({
      query: (data) => ({
        method: 'POST',
        url: '/flow/',
        data: mapUndefinedToNull(data),
        meta: {isShowError: true},
      }),
      invalidatesTags: [ECacheTag.FLOW],
    }),
    saveFlow: builder.mutation<TFlow, {id: TFlow['id']; data: TFlowMutation}>({
      query: ({id, data}) => ({
        method: 'PUT',
        url: `/flow/${id}`,
        data: mapUndefinedToNull(data),
        meta: {
          isShowSuccess: true,
          successMessageKey: 'List:notification.saveSuccess',
        },
      }),
      invalidatesTags: (_, __, {id}) => [{type: ECacheTag.FLOW, id}],
    }),
    activateFlow: builder.mutation<TFlow, TFlow['id']>({
      query: (id) => ({
        method: 'POST',
        url: `/flow/${id}/activate`,
        meta: {
          isShowError: true,
        },
      }),
      invalidatesTags: (_, __, id) => [{type: ECacheTag.FLOW, id}],
    }),
    deactivateFlow: builder.mutation<TFlow, TFlow['id']>({
      query: (id) => ({
        method: 'POST',
        url: `/flow/${id}/deactivate`,
        meta: {
          isShowError: true,
        },
      }),
      invalidatesTags: (_, __, id) => [{type: ECacheTag.FLOW, id}],
    }),
    copyFlow: builder.mutation<TFlow, TFlow['id']>({
      query: (id) => ({
        method: 'POST',
        url: `/flow/${id}/copy`,
        meta: {
          isShowError: true,
        },
      }),
      invalidatesTags: [ECacheTag.FLOW],
    }),
    deleteFlow: builder.mutation<TFlow, TFlow['id']>({
      query: (id) => ({
        method: 'DELETE',
        url: `/flow/${id}/delete`,
        meta: {
          isShowError: true,
          errorMessageKey: 'List:errors.failedToDeleteItem',
          isShowSuccess: true,
          successMessageKey: 'List:notification.deleteSuccess',
        },
      }),
      invalidatesTags: [ECacheTag.FLOW],
    }),
    getFlows: builder.query<TData<TFlow>, TPayload>({
      query: ({tenantId, ...data}) => ({
        method: 'POST',
        url: tenantId ? `/flow/list/tenant/${tenantId}` : '/flow/list',
        data,
      }),
      keepUnusedDataFor: CACHE_LIFETIME,
      providesTags: (result) => {
        if (!result) {
          return [ECacheTag.FLOW];
        }

        return [ECacheTag.FLOW, ...result.content.map(({id}) => ({id, type: ECacheTag.FLOW}))];
      },
    }),
    getFlow: builder.query<TFlow, {flowId: TFlow['id']; tenantId?: TItemId}>({
      query: ({flowId, tenantId}) => ({
        method: 'GET',
        url: tenantId ? `/flow/${flowId}/tenant/${tenantId}` : `/flow/${flowId}`,
      }),
      providesTags: (_, __, {flowId}) => [{id: flowId, type: ECacheTag.FLOW}],
    }),
    getFlowSteps: builder.query<TFlowStep[], {flowId: TFlow['id']; tenantId?: TItemId}>({
      query: ({flowId, tenantId}) => ({
        method: 'GET',
        url: tenantId ? `/flow/${flowId}/steps/tenant/${tenantId}` : `/flow/${flowId}/steps`,
      }),
      providesTags: [ECacheTag.FLOW_STEPS],
      transformResponse: (data: Array<TFlowStep & {meta: string}>) =>
        mapNullToUndefined(
          data.map((step) => ({
            ...step,
            meta: JSON.parse(step.meta || '{}'),
          })),
        ),
    }),
    saveFlowSteps: builder.mutation<TFlowStep[], {id: TFlow['id']; steps: TFlowStep[]}>({
      query: (data) => ({
        method: 'POST',
        url: `/flow/${data.id}/steps`,
        data: mapUndefinedToNull(
          data.steps.map((step) => ({...step, meta: JSON.stringify(step.meta)})),
        ),
        meta: {
          isShowError: true,
          errorMessageKey: 'List:errors.failedToSaveItem',
        },
      }),
      invalidatesTags: [ECacheTag.FLOW_STEPS],
    }),
  }),
});

export const {
  useGetFlowsQuery,
  useGetFlowQuery,
  useGetFlowStepsQuery,
  useCreateFlowMutation,
  useSaveFlowStepsMutation,
  useActivateFlowMutation,
  useDeactivateFlowMutation,
  useCopyFlowMutation,
  useDeleteFlowMutation,
  useSaveFlowMutation,
  endpoints,
} = flowApi;

export {TFlowMutation};
