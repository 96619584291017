import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {isSameDay} from 'date-fns/isSameDay';
import {startOfDay} from 'date-fns/startOfDay';
import {sub} from 'date-fns/sub';

import {TItemId} from 'src/constants';
import {EWebNotificationSection} from 'src/containers/Notifications';

import {notificationsEndpoints} from './api';
import {DEFAULT_FILTERS, TFilters} from './definitions';

type TState = {
  filters: Record<EWebNotificationSection, TFilters>;
  activeTab?: EWebNotificationSection;
  periodBorder: {today?: TItemId; yesterday?: TItemId; earlier?: TItemId};
};

const initialState: TState = {
  filters: {
    [EWebNotificationSection.NOTIFICATIONS]: DEFAULT_FILTERS[EWebNotificationSection.NOTIFICATIONS],
    [EWebNotificationSection.UPLOADS]: DEFAULT_FILTERS[EWebNotificationSection.UPLOADS],
  },
  periodBorder: {},
};

const slice = createSlice({
  name: 'notificationsHistory',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<TFilters>) {
      if (state.activeTab) {
        state.filters[state.activeTab] = action.payload;
      }
    },
    setActiveTab(state, action: PayloadAction<EWebNotificationSection>) {
      state.activeTab = action.payload ?? EWebNotificationSection.NOTIFICATIONS;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      notificationsEndpoints.getNotifications.matchFulfilled,
      (state, {payload}) => {
        if (payload.first) {
          state.periodBorder = {};
        }

        if (!state.periodBorder.today) {
          const firstTodayItem = payload.content.find((item) =>
            isSameDay(new Date(item.occurredAt), new Date()),
          );

          state.periodBorder.today = firstTodayItem?.id;
        }

        const yesterday = sub(new Date(), {days: 1});

        if (!state.periodBorder.yesterday) {
          const firstYesterdayItem = payload.content.find((item) =>
            isSameDay(new Date(item.occurredAt), yesterday),
          );

          state.periodBorder.yesterday = firstYesterdayItem?.id;
        }

        if (!state.periodBorder.earlier) {
          const firstEarlierItem = payload.content.find(
            (item) => new Date(item.occurredAt) < startOfDay(yesterday),
          );

          state.periodBorder.earlier = firstEarlierItem?.id;
        }
      },
    );
  },
});

export const selectFilters = (state: TRootState) =>
  state.notificationsHistory.activeTab
    ? state.notificationsHistory.filters[state.notificationsHistory.activeTab]
    : ({} as TFilters);
export const selectActiveTab = (state: TRootState) =>
  state.notificationsHistory.activeTab as EWebNotificationSection;
export const selectPeriodBorder = (state: TRootState) => state.notificationsHistory.periodBorder;
export const {setFilters: setFiltersAction, setActiveTab: setActiveTabAction} = slice.actions;

export default slice.reducer;
