import {resetAllNews} from 'src/containers/News/slice';
import {rootApi} from 'src/models';

import {resetTenant} from './slice';

export const resetUser = () => (dispatch: TDispatch) => {
  dispatch(rootApi.util.resetApiState());
  sessionStorage.clear();
  dispatch(resetAllNews());
  dispatch(resetTenant());
};
