import {storage} from '@edna/utils';

import {EStorageKeys, TItemId} from 'src/constants';
import {ECacheTag, listenerMiddleware, rootApi} from 'src/models';

import {ENotificationLevel, ENotificationStatus, TNotifications, TWebNotification} from './model';
import {manageSystemNotifications, showWebNotification} from './utils';

const MAX_TIME_DIFFERENCE = 300_000;

const api = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    changeStatus: builder.mutation<TWebNotification, {status: string; ids: TItemId[]}>({
      query: (data) => ({
        method: 'PUT',
        url: `/web-notification/change-status`,
        data,
        meta: {isShowError: true},
      }),
      invalidatesTags: (result, error, arg) => {
        if (arg.status === ENotificationStatus.READ) {
          return [ECacheTag.NOTIFICATIONS_COUNT];
        }

        return [];
      },
    }),
    pollNotifications: builder.query<TNotifications, number | void>({
      query: (data) => {
        const time = Date.now();
        const lastPollTime = data ?? Number(storage.get(EStorageKeys.NOTIFICATION_TIME)) ?? time;

        return {
          method: 'POST',
          url: `/web-notification/poll-all`,
          data: {forLastMillis: Math.min(time - lastPollTime, MAX_TIME_DIFFERENCE)},
          meta: {time},
        };
      },
    }),
    markAllNotificationsAsRead: builder.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: `/web-notification/mark-all-as-read`,
        meta: {isShowError: true},
      }),
      invalidatesTags: [ECacheTag.NOTIFICATIONS_COUNT],
    }),
    getNotificationsCount: builder.query<number, void>({
      query: () => ({
        method: 'GET',
        url: `/web-notification/count`,
        meta: {isShowError: true},
      }),
      transformResponse: (response: TAnyObject) => response.count,
      providesTags: [ECacheTag.NOTIFICATIONS_COUNT],
    }),
  }),
});

listenerMiddleware.startListening({
  matcher: api.endpoints.pollNotifications.matchFulfilled,
  effect: ({payload, meta}, listenerApi) => {
    storage.set(EStorageKeys.NOTIFICATION_TIME, (meta.baseQueryMeta as TAnyObject).time);

    manageSystemNotifications(payload);

    const count = api.endpoints.getNotificationsCount.select()(listenerApi.getState()).data ?? 0;

    if (count !== payload?.webNotifications.length) {
      listenerApi.dispatch(api.util.invalidateTags([ECacheTag.NOTIFICATIONS_COUNT]));
    }

    if (!payload?.webNotifications.length) {
      return;
    }

    listenerApi.dispatch(api.util.invalidateTags([ECacheTag.NOTIFICATIONS]));

    payload.webNotifications
      .filter((item) => item.level !== ENotificationLevel.LOADING)
      .forEach((item) => {
        showWebNotification(item, (status) =>
          listenerApi.dispatch(api.endpoints.changeStatus.initiate({ids: [item.id], status})),
        );
      });
  },
});

export const {
  usePollNotificationsQuery,
  useMarkAllNotificationsAsReadMutation,
  useGetNotificationsCountQuery,
  endpoints: notificationsEndpoints,
} = api;
