import styled, {css} from 'styled-components';

import {Button as BaseButton, NewNotifications as BaseNewNotifications} from '@edna/components';
import {mixins} from '@edna/components/styles';

export const Link = styled.a<{hasLeftMargin?: boolean}>`
  font-weight: 500;
  color: currentColor;
  margin-left: ${({hasLeftMargin, theme}) => (hasLeftMargin ? theme.spacing.s1 : 0)};
  text-decoration: underline;
`;

export const Button = styled(BaseButton).attrs({size: 'S'})`
  ${mixins.f2};
  display: inline;
  border: 2px solid currentColor;
  color: currentColor;
  background-color: transparent;
  box-shadow: none;
  text-decoration: none;
  border-radius: 6px;

  svg {
    ${mixins.wh('16px')};
    position: relative;
    bottom: 1px;
    margin-right: ${({theme}) => theme.spacing.s1};
  }

  ${({theme: {spacing}}) => css`
    margin-left: ${spacing.s3};
    padding: ${spacing.s1} ${spacing.s4};
  `}
`;

type TNotificationsProps = {
  width: string;
  left: string;
  hasSystemNotifications?: boolean;
};

export const Notifications = styled(BaseNewNotifications)<TNotificationsProps>`
  ${({left, width, hasSystemNotifications, theme}) => css`
    left: ${left};
    width: ${width};
    max-width: 100%;
    margin-bottom: ${hasSystemNotifications ? theme.spacing.s4 : 0};
    transition:
      left ${theme.animation.duration}ms ease-in-out,
      top ${theme.animation.duration}ms ease-in-out;
  `}
`;
