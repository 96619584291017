import {CustomHelpers} from 'joi';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import {EChannelType, EContentType, EOperatorName, TChannelType} from 'src/constants';
import {mapEmptyFieldsToUndefined, mapNullToUndefined} from 'src/utils';
import {joi, joiResolver, mixed} from 'src/utils/validator';

import {EMultiApproveStatus} from './SMSForm/definitions';
import {ETemplateButtonOtpType, EWhatsAppCategory} from './WhatsAppForm/definitions';
import {
  EApproveStatus,
  EMessageMatcherButtonTypes,
  EType,
  EVariableType,
  TEMPLATE_NAME_MAX_LENGTH,
  TEMPLATE_NAME_MIN_LENGTH,
  TEMPLATE_TEXT_MAX_LENGTH,
  TFormData,
  TFormDataButtons,
  TInitialMessageMatcher,
  TInstagramContent,
  TMessageMatcher,
  TMessageMatcherButton,
  TOkNotifyMessageMatchersContent,
  TPushContent,
  TPushMessageMatchersContent,
  TType,
  TVariables,
  TViberContent,
  TViberMessageMatchersContent,
  TVkNotifyContent,
  TVkNotifyFormData,
  TVkNotifyMessageMatchersContent,
  TWhatsAppContent,
  TWhatsAppMessageMatchersContent,
  VARIABLE_TEXT_MAX_LENGTH,
} from './definitions';

export const getPlaceholdersFromVariableTemplate = (text: string | null) =>
  text?.match(/{{.*?}}/g)?.map((string) => string.replace(/[{}]/g, '')) ?? [];

export const getPlaceholdersFromRegexTemplate = (text: string | null, regex?: RegExp) =>
  text?.match(regex ?? /%(d|w)\S*/g) ?? [];

export const getVariablesFromText = (text?: TMessageMatcher['content']['text'], type?: TType) => {
  const result = [] as TVariables[];

  if (type === EType.USER) {
    const placeholders = getPlaceholdersFromVariableTemplate(text ?? '');

    placeholders?.forEach((el, index) => {
      result[index] = {
        type: EVariableType.PARAMS,
        value: `{{${el}}}`,
      };
    });
  }

  return result;
};

// eslint-disable-next-line complexity
const getSmsInitialValues = (item: TInitialMessageMatcher): TFormData => ({
  channelType: EChannelType.SMS,
  approveStatus: item.approveStatus || EApproveStatus.NOT_SENT,
  multiApproveStatus: item.multiApproveStatus || EMultiApproveStatus.NOT_SENT,
  id: item.id ?? '',
  type: item.type || EType.OPERATOR,
  name: item.name ?? '',
  description: item.description ?? '',
  createdBy: item.createdBy ?? '',
  exampleText: item.exampleText ?? '',
  parentId: item.parentId ?? '',
  content: {
    text: item.content?.text ?? '',
  },
  language: item.language ?? '',
  category: item.category ?? '',
  subjectIds: item.subjectIds ?? [],
  smsProviders: (item.smsProviders ?? []).reduce((acc, {code}) => ({...acc, [code]: true}), {}),
  contentType: item.contentType ?? EContentType.TEXT,
  variables: getVariablesFromText(item.content?.text, item.type),
});

export const getViberInitialValuesContent = (
  content: TInitialMessageMatcher<TViberContent>['content'],
) => ({
  text: content?.text ?? '',
  caption: content?.caption,
  action: content?.action,
  attachment: content?.attachment,
  isUploadImage: true,
  isUploadDocument: true,
});

const getViberInitialValues = (item: TInitialMessageMatcher): TFormData => {
  const smsValues = getSmsInitialValues(item);

  return {
    ...smsValues,
    channelType: EChannelType.VIBER,
    content: getViberInitialValuesContent(item.content),
    type: item.type || EType.OPERATOR,
    category: item.category,
    language: item.language,
  };
};

const parsePhone = (value: string) => {
  const stringValue = String(value);
  const replacedValue = stringValue.replace(/\D/g, '');
  const hasPlus = stringValue.startsWith('+');

  return `${hasPlus ? '+' : ''}${replacedValue}`;
};

export const deleteVariable = (contentText?: string | null) => {
  if (!contentText) {
    return '';
  }

  return contentText.replace(/{{\d+}}/, '');
};

export const cleanTemplateText = (value?: string) => {
  if (!value) {
    return '';
  }

  const valueWithLineBrakes = value.replace(/\n/g, '\\n');

  return valueWithLineBrakes.replace(/{{.*?}}/g, '');
};

export const hasVariable = (contentText?: string | null | undefined) =>
  Boolean(contentText?.match(/{{\d+}}/));

export const cleanUrl = (url?: string) => deleteVariable(url)?.replace(/\/$/, '');

export const splitButtons = (buttons?: TMessageMatcherButton[]): TFormDataButtons | undefined => {
  if (buttons === undefined) {
    return buttons;
  }

  return buttons.reduce((result, button) => {
    const text = button.text || '';

    if (!button.buttonType) {
      return result;
    }

    return {
      ...result,
      isChatType: button.buttonType === EMessageMatcherButtonTypes.CHAT,
      [button.buttonType]: [
        ...(result[button.buttonType] ?? []),
        {
          ...button,
          text,
          required: button.required ?? true,
          payload: button.payload || '',
          url: deleteVariable(button.url) || '',
          hasUrlPostfix: hasVariable(button.url),
          phone: parsePhone(button.phone || ''),
          autofillText: button.autofillText ?? '',
          otpType: button.otpType ?? '',
          packageName: button.packageName ?? '',
          hash: button.hash ?? '',
        },
      ],
    };
  }, {} as TFormDataButtons);
};

export const getPushButtonsInitialValues = (
  defaultButtons: TFormDataButtons,
  values?: TMessageMatcherButton[],
) => {
  const buttons = splitButtons(values) ?? defaultButtons;

  if (!buttons[EMessageMatcherButtonTypes.CHAT]) {
    buttons[EMessageMatcherButtonTypes.CHAT] = [
      {
        required: false,
        text: '',
        payload: '',
      },
    ];
  }

  if (!buttons[EMessageMatcherButtonTypes.URL]) {
    buttons[EMessageMatcherButtonTypes.URL] = [
      {
        required: false,
        text: '',
        url: '',
      },
    ];
  }

  if (!buttons[EMessageMatcherButtonTypes.PHONE]) {
    buttons[EMessageMatcherButtonTypes.PHONE] = [
      {
        required: false,
        text: '',
        phone: '',
      },
    ];
  }

  if (!buttons[EMessageMatcherButtonTypes.OTP]) {
    buttons[EMessageMatcherButtonTypes.OTP] = [
      {
        autofillText: '',
        text: '',
        otpType: '',
        packageName: '',
        hash: '',
      },
    ];
  }

  return buttons;
};

export const getWhatsAppButtonsInitialValues = (
  buttons?: TMessageMatcherButton[],
  isAuthentication?: boolean,
) => {
  if (!buttons?.length) {
    return isAuthentication ? [{text: '', payload: ''}] : [];
  }

  return mapNullToUndefined(buttons).map((button: TMessageMatcherButton) => {
    return {
      ...button,
      text: button.text ?? '',
      payload: button.payload ?? '',
      required: button.required ?? true,
      url: deleteVariable(button.url) || '',
      hasUrlPostfix: hasVariable(button.url),
      urlPostfix: button.urlPostfix,
      phone: parsePhone(button.phone || ''),
      autofillText: button.autofillText ?? '',
      otpType: button.otpType ?? '',
      packageName: button.packageName ?? '',
      hash: button.hash ?? '',
    };
  });
};

export const getWhatsAppInitialValuesContent = (
  content: TInitialMessageMatcher<TWhatsAppContent>['content'],
  isAuthentication?: boolean,
): TWhatsAppMessageMatchersContent => {
  return {
    text: content?.text ?? '',
    hasHeader: !!content?.header?.headerType,
    header: {
      ...content?.header,
      headerType: content?.header?.headerType ?? EContentType.TEXT,
      text: content?.header?.text ?? '',
      headerExampleMediaUrl: content?.header?.headerExampleMediaUrl ?? '',
    },
    addFooter: !!content?.footer?.text,
    footer: {
      text: content?.footer?.text ?? '',
    },
    buttons: getWhatsAppButtonsInitialValues(content?.buttons, isAuthentication),
    addPasswordExpiration: Number(content?.codeExpirationMinutes) > 0,
    codeExpirationMinutes: content?.codeExpirationMinutes ?? 10,
    securityRecommendation: !!content?.securityRecommendation,
  };
};

const getWhatsAppInitialValues = (item: TInitialMessageMatcher): TFormData => {
  const content = item.content ?? ({} as TMessageMatcher['content']);
  const smsValues = getSmsInitialValues(item);

  return {
    ...smsValues,
    channelType: EChannelType.WHATSAPP,
    category: item.category ?? '',
    language: item.language ?? '',
    subjectId: item?.subjectIds?.length ? item?.subjectIds[0] : undefined,
    subjectIds: item?.subjectIds ?? [],
    content: getWhatsAppInitialValuesContent(
      content,
      item.category === EWhatsAppCategory.AUTHENTICATION,
    ),
    variables: item.id ? getVariablesFromText(content?.text, item.type) : [],
  };
};

export const getOkNotifyInitialValues = (
  item: TInitialMessageMatcher = {},
): TFormData<TOkNotifyMessageMatchersContent> => {
  const content = item.content ?? ({} as TMessageMatcher['content']);
  const smsValues = getSmsInitialValues(item);

  return {
    ...smsValues,
    channelType: EChannelType.OK_NOTIFY,
    variables: getVariablesFromText(content?.text, item.type),
    content: {
      text: content?.text ?? '',
    },
  };
};

const getInstagramInitialValues = (item: TInitialMessageMatcher<TInstagramContent>): TFormData => ({
  channelType: EChannelType.INSTAGRAM,
  approveStatus: item.approveStatus,
  id: item.id,
  type: item.type || EType.OPERATOR,
  name: item.name,
  createdBy: item.createdBy,
  subjectIds: item.subjectIds ?? [],
  contentType: item.contentType ?? EContentType.TEXT,
  content: {
    isUploadImage: true,
    text: item.content?.text ?? '',
    attachment: item.content?.attachment,
  },
  variables: [],
});

export const DEFAULT_PUSH_BUTTON = {
  isChatType: false,
  [EMessageMatcherButtonTypes.URL]: [{text: '', url: '', required: true}],
};

export const getPushContentInitialValues = (
  content: TInitialMessageMatcher<TPushContent>['content'] = {},
): TPushMessageMatchersContent => ({
  text: content.text || '',
  headerText: content.header?.text || '',
  headerAttachment: content.header?.attachment,
  bigContentText: content.bigContent?.text || '',
  bigContentAttachment: content.bigContent?.header?.attachment,
  addButtons: !isEmpty(content.buttons),
  buttons: getPushButtonsInitialValues(DEFAULT_PUSH_BUTTON, content?.buttons),
  action: content.action || '',
  addLinks: !isEmpty(content.action),
  style: content.style,
  deviceSettings: content?.deviceSettings,
  parameters:
    content.serviceAttrs && Object.keys(content.serviceAttrs).length
      ? Object.keys(content.serviceAttrs).map((serviceAttrsKey) => ({
          key: serviceAttrsKey ?? '',
          value: content.serviceAttrs?.[serviceAttrsKey] ?? '',
        }))
      : [{key: '', value: ''}],
});

const getPushInitialValues = (item: TInitialMessageMatcher): TFormData => ({
  channelType: EChannelType.PUSH,
  id: item.id,
  name: item.name,
  createdBy: item.createdBy,
  subjectIds: [],
  content: getPushContentInitialValues(item.content),
  variables: [],
});

const getVkNotifyButtonsInitialValues = (buttons?: TMessageMatcherButton[]) => {
  if (!buttons?.length) {
    return [{text: '', hash: '', payload: ''}];
  }

  return mapNullToUndefined(buttons).map((button: TMessageMatcherButton) => ({
    ...button,
    text: button.text ?? '',
    hash: button.hash ?? '',
    payload: button.payload ?? '',
  }));
};

export const getVkNotifyContentInitialValues = (
  content: TInitialMessageMatcher<TVkNotifyContent>['content'],
) => ({
  text: content?.text ?? '',
  addAttachments: !isEmpty(content?.vkAttachments),
  vkAttachments: content?.vkAttachments ?? [{fileUrl: ''}],
  addButtons: !isEmpty(content?.buttons),
  buttons: getVkNotifyButtonsInitialValues(content?.buttons),
  vkTwoWayEnabled: content?.vkTwoWayEnabled ?? true,
});

export const getVkNotifyInitialValues = (item: TInitialMessageMatcher = {}): TVkNotifyFormData => {
  const content = item.content ?? ({} as TMessageMatcher['content']);
  const smsValues = getSmsInitialValues(item);

  return {
    ...smsValues,
    channelType: EChannelType.VK_NOTIFY,
    id: item.id,
    name: item.name,
    createdBy: item.createdBy,
    subjectIds: item.subjectIds ?? [],
    content: getVkNotifyContentInitialValues(content),
    variables: getVariablesFromText(content?.text, item.type),
  };
};

export const getInitialValues = (
  channelType?: EChannelType,
  payload: Partial<TMessageMatcher> | null = {},
) => {
  const item = payload || {};

  switch (channelType) {
    case EChannelType.WHATSAPP:
      return getWhatsAppInitialValues(item);
    case EChannelType.VIBER:
      return getViberInitialValues(item);
    case EChannelType.INSTAGRAM:
      return getInstagramInitialValues(item);
    case EChannelType.PUSH:
      return getPushInitialValues(item);
    case EChannelType.OK_NOTIFY:
      return getOkNotifyInitialValues(item);
    case EChannelType.VK_NOTIFY:
      return getVkNotifyInitialValues(item);
    case EChannelType.SMS:
    default:
      return getSmsInitialValues(item);
  }
};

export const insertValueToVariables = (contentText: string, variables: TVariables[]) => {
  let index = 0;

  return contentText.replace(/({{[^{}]+}})/g, (_, text) => {
    const variable = variables?.[index];

    index += 1;

    if (variable?.value) {
      return variable?.value;
    }

    return text;
  });
};

export const templateStringRule = (value: string, helpers: CustomHelpers) => {
  if (/^\s/.test(value)) {
    return helpers.error('invalidSpaceInBegin');
  }

  if (/\s$/.test(value)) {
    return helpers.error('invalidSpaceInEnd');
  }

  if (/\n{3}|\t|\s{4}/g.test(value)) {
    return helpers.error('invalidTextFormat');
  }

  return value;
};

export const templateHashRule = (value: string, helpers: CustomHelpers) => {
  const [, parameters] = helpers.state.ancestors;
  const result = cleanTemplateText(insertValueToVariables(value, parameters.variables));

  if (result.includes('#')) {
    return helpers.error('invalidTextWithHashFormat');
  }

  return value;
};

// считаются символы без учета переменных
export const operatorTextMaxRule = (
  value: string,
  helpers: CustomHelpers,
  templateTextMaxLength = TEMPLATE_TEXT_MAX_LENGTH,
) => {
  if (cleanTemplateText(value).length > templateTextMaxLength) {
    return helpers.error('string.max', {limit: templateTextMaxLength});
  }

  return value;
};

// Текст шаблона не может содержать только переменные
export const operatorTextOnlyVariablesRule = (value: string, helpers: CustomHelpers) => {
  if (value.length && cleanTemplateText(value).length === 0) {
    return helpers.error('onlyVariable');
  }

  return value;
};

//считаются символы с учетом длины значений переменных
export const userTextMaxRule = (
  value: string,
  helpers: CustomHelpers,
  templateTextMaxLength = TEMPLATE_TEXT_MAX_LENGTH,
) => {
  const [, parameters] = helpers.state.ancestors;

  const result = cleanTemplateText(insertValueToVariables(value, parameters.variables));

  if (result.length > templateTextMaxLength) {
    return helpers.error('invalidTemplateTextLength', {count: templateTextMaxLength});
  }

  return value;
};

export const commonContentSchema = {
  text: joi
    .string()
    .custom(templateStringRule)
    .min(1)
    .custom(operatorTextMaxRule)
    .required()
    .when('...type', {
      is: EType.USER,
      then: mixed.textWithVariables.custom(userTextMaxRule),
    }),
};

export const nameSchema = joi
  .string()
  .min(TEMPLATE_NAME_MIN_LENGTH)
  .max(TEMPLATE_NAME_MAX_LENGTH)
  .custom((value, helpers) => {
    if (!/^[a-z0-9_]*$/.test(value)) {
      return helpers.error('invalidTemplateName');
    }

    return value;
  })
  .required();

export const subjectIdsSchema = joi.array().min(1).required();

export const commonSchema = {
  name: nameSchema,
  subjectIds: subjectIdsSchema,
  parentId: joi.when('type', {
    is: EType.USER,
    then: joi.number().required(),
  }),
  content: joi.object(commonContentSchema),
  variables: joi.array().items(
    joi.object({
      type: joi.string().required(),
      value: joi.required().when('type', {
        is: joi.valid('TEXT'),
        then: joi
          .string()
          .max(VARIABLE_TEXT_MAX_LENGTH)
          .custom((value, helpers) => {
            if (/\n/g.test(value)) {
              return helpers.error('invalidVariableTextFormat');
            }

            return value;
          }),
        otherwise: joi.string().required(),
      }),
    }),
  ),
};

export const commonSchemaStrict = {
  ...commonSchema,
  channelType: joi.string().required(),
  parentId: joi.when('type', {
    is: EType.USER,
    then: joi.number().required(),
  }),
  clientComment: joi.string().max(500),
  subjectIds: joi.array().items(mixed.id).min(1).required(),
};

export const whatsappVariablesSchema = {
  variables: joi.array().items(
    joi.object({
      type: joi.string().required(),
      value: joi.required().when('type', {
        is: joi.valid('TEXT'),
        then: joi
          .string()
          .max(VARIABLE_TEXT_MAX_LENGTH)
          .custom((value, helpers) => {
            if (/\n/g.test(value)) {
              return helpers.error('invalidVariableTextFormat');
            }

            return value;
          }),
        otherwise: joi.string().required(),
      }),
    }),
  ),
};

export const commonValidate = joiResolver((payloadJoi) => payloadJoi.object(commonSchema));

const insertCountTemplateText = (contentText?: string | null) => {
  if (!contentText) {
    return '';
  }

  let countVariable = 0;

  return contentText.replace(/{{[^{}]+}}/g, () => {
    countVariable += 1;

    return `{{${countVariable}}}`;
  });
};

export const joinButtons = (
  buttons?: TFormDataButtons,
  required?: boolean,
): TMessageMatcherButton[] => {
  if (buttons === undefined) {
    return [];
  }

  if (buttons.isChatType) {
    const chatButtons = buttons[EMessageMatcherButtonTypes.CHAT] || [];

    return chatButtons.reduce((res, button) => {
      if (button.text) {
        res.push({
          buttonType: EMessageMatcherButtonTypes.CHAT,
          text: button.text.trim(),
          payload: button.payload,
        });
      }

      return res;
    }, [] as TMessageMatcherButton[]);
  }

  const result: TMessageMatcherButton[] = [];
  const urlButtons = buttons[EMessageMatcherButtonTypes.URL];

  urlButtons?.forEach((button) => {
    if ((!required || button.required) && button.text) {
      result.push({
        buttonType: EMessageMatcherButtonTypes.URL,
        text: button.text.trim(),
        url: button.hasUrlPostfix ? `${button.url}{{1}}` : button.url,
        hasUrlPostfix: hasVariable(button.url),
      });
    }
  });
  const phoneButtons = buttons[EMessageMatcherButtonTypes.PHONE];

  phoneButtons?.forEach((button) => {
    if ((!required || button.required) && button.text) {
      result.push({
        buttonType: EMessageMatcherButtonTypes.PHONE,
        text: button.text,
        phone: button.phone,
      });
    }
  });

  const otpButtons = buttons[EMessageMatcherButtonTypes.OTP];

  otpButtons?.forEach((button) => {
    if (button.text) {
      result.push({
        ...button,
        buttonType: EMessageMatcherButtonTypes.OTP,
        text: button.text.trim(),
        autofillText: button.autofillText?.trim(),
      });
    }
  });

  return result;
};

export const prepareWhatsappButtons = (
  buttons?: TMessageMatcherButton[],
  required?: boolean,
): TMessageMatcherButton[] => {
  if (buttons === undefined) {
    return [];
  }
  const result: TMessageMatcherButton[] = [];

  buttons
    .filter((button): button is Required<TMessageMatcherButton> => button.text !== undefined)
    .forEach((button) => {
      const needAddButton = !required || button.required;

      switch (button.buttonType) {
        case EMessageMatcherButtonTypes.CHAT:
          result.push({
            buttonType: EMessageMatcherButtonTypes.CHAT,
            text: button.text?.trim(),
            payload: button.payload,
          });

          break;

        case EMessageMatcherButtonTypes.URL:
          if (needAddButton) {
            const hasUrlPostfix = button.hasUrlPostfix || hasVariable(button.url);

            result.push({
              buttonType: EMessageMatcherButtonTypes.URL,
              text: button.text?.trim(),
              url: hasUrlPostfix ? `${cleanTemplateText(button.url)}{{1}}` : button.url,
              urlPostfix: button.urlPostfix,
              shouldCollectClicks: button.shouldCollectClicks,
            });
          }
          break;

        case EMessageMatcherButtonTypes.PHONE:
          if (needAddButton) {
            result.push({
              buttonType: EMessageMatcherButtonTypes.PHONE,
              text: button.text?.trim(),
              phone: button.phone?.trim(),
            });
          }
          break;

        case EMessageMatcherButtonTypes.OTP:
          const baseOtpButton = {
            buttonType: EMessageMatcherButtonTypes.OTP,
            otpType: button.otpType,
            text: button.text?.trim(),
          };

          if (button.otpType === ETemplateButtonOtpType.AUTO) {
            result.push({
              ...baseOtpButton,
              autofillText: button.autofillText?.trim(),
              hash: button.hash,
              packageName: button.packageName,
            });

            break;
          }

          result.push(baseOtpButton);
          break;

        default:
          result.push(button);
      }
    });

  return result;
};

const prepareWhatsAppContent = (
  type?: TType,
  payload?: TWhatsAppMessageMatchersContent,
  variables?: TVariables[],
) => {
  const content: TMessageMatcher['content'] = {
    text: payload?.text || '',
    buttons: payload ? prepareWhatsappButtons(payload.buttons) : [],
  };

  if (type === EType.OPERATOR) {
    content.text = insertCountTemplateText(content?.text);
    content.textExampleParams = payload?.textExampleParams;
  }

  content.securityRecommendation = payload?.securityRecommendation;

  if (payload?.addPasswordExpiration) {
    content.codeExpirationMinutes = payload?.codeExpirationMinutes;
  }

  if (payload?.hasHeader) {
    content.header = {
      headerType: payload.header?.headerType,
      text:
        payload.header?.headerType === EContentType.TEXT
          ? payload.header.text?.trim() || undefined
          : undefined,
    };
  }

  if (payload?.addFooter) {
    content.footer = payload.footer;
  }
  content.text =
    content?.text && variables ? insertValueToVariables(content?.text, variables) : content?.text;

  return content;
};

export const prepareViberContent = (
  type?: TType,
  payload?: TViberMessageMatchersContent,
  contentType?: EContentType,
) => {
  const textContent: TMessageMatcher<TViberContent>['content'] = {
    text: payload?.text,
  };

  if (type !== EType.CUSTOM) {
    return textContent;
  }

  const attachment = payload?.attachment?.fileUrl ? payload.attachment : null;

  switch (contentType) {
    case EContentType.IMAGE:
      return {attachment};
    case EContentType.BUTTON:
      return {
        attachment,
        caption: payload?.caption,
        text: payload?.text,
        action: payload?.action,
        shouldCollectClicks: payload?.shouldCollectClicks,
      };
    case EContentType.DOCUMENT:
      return {attachment};
    case EContentType.TEXT:
    default:
      return textContent;
  }
};

export const preparePushContent = (payload: Partial<TPushMessageMatchersContent> = {}) => {
  const content: TMessageMatcher['content'] = {
    ...omit(payload, ['headerAttachment', 'bigContentAttachment']),
    header: {
      headerType: payload.headerAttachment?.fileUrl ? EContentType.IMAGE : EContentType.TEXT,
      attachment: payload.headerAttachment,
      text: payload.headerText,
    },
    bigContent: {
      text: payload.text,
      header: {
        text: payload.headerText,
        headerType: payload.bigContentAttachment?.fileUrl ? EContentType.IMAGE : EContentType.TEXT,
        attachment: payload.bigContentAttachment,
      },
    },
    deviceSettings: payload.deviceSettings,
    buttons: payload.addButtons ? joinButtons(payload.buttons) : [],
    serviceAttrs: payload.parameters?.reduce((obj, parameter) => {
      if (parameter.key) {
        return {...obj, [parameter.key]: parameter.value};
      }

      return obj;
    }, {}),
  };

  if (payload.addLinks) {
    content.action = payload.action;
  }

  return mapEmptyFieldsToUndefined(content);
};

export const prepareVkNotifyContent = (
  type?: TType,
  payload?: Partial<TVkNotifyMessageMatchersContent>,
  variables?: TVariables[],
) => {
  const content: TMessageMatcher['content'] = {
    text: payload?.text?.trim() || '',
    vkTwoWayEnabled: !!payload?.vkTwoWayEnabled,
    vkAttachments: payload?.addAttachments
      ? (payload?.vkAttachments?.filter((item) => !!item?.fileUrl) as TFile[]) ?? []
      : [],
    buttons: payload?.addButtons
      ? payload?.buttons
          ?.filter((item) => !!item.text || item.buttonType === EMessageMatcherButtonTypes.LOCATION)
          .map(mapEmptyFieldsToUndefined) ?? []
      : [],
  };

  if (type === EType.OPERATOR) {
    content.text = insertCountTemplateText(content?.text);
  }

  content.text =
    content?.text && variables ? insertValueToVariables(content?.text, variables) : content?.text;

  return content;
};

export const prepareOkNotifyContent = (
  type?: TType,
  payload?: TOkNotifyMessageMatchersContent,
  variables?: TVariables[],
) => {
  const content: TMessageMatcher['content'] = {
    text: payload?.text?.trim() || '',
  };

  if (type === EType.OPERATOR) {
    content.text = insertCountTemplateText(content?.text);
  }

  content.text =
    content?.text && variables ? insertValueToVariables(content?.text, variables) : content?.text;

  return content;
};

type TPrepareParams = {
  type?: TType;
  content?: TFormData['content'];
  variables?: TVariables[];
  contentType?: EContentType;
};

export const prepareMessageMatherContent = (
  channelType?: TChannelType,
  {type, content, variables, contentType}: TPrepareParams = {},
) => {
  switch (channelType) {
    case EChannelType.WHATSAPP:
      return prepareWhatsAppContent(type, content as TWhatsAppMessageMatchersContent, variables);
    case EChannelType.VIBER:
      return mapEmptyFieldsToUndefined(
        prepareViberContent(type, content as TViberMessageMatchersContent, contentType),
      );
    case EChannelType.PUSH:
      return preparePushContent(content as TPushMessageMatchersContent);
    case EChannelType.VK_NOTIFY:
      return prepareVkNotifyContent(type, content as TVkNotifyMessageMatchersContent, variables);
    case EChannelType.OK_NOTIFY:
      return prepareOkNotifyContent(type, content as TOkNotifyMessageMatchersContent, variables);
    default:
      return {
        text: content?.text?.trim() || '',
      };
  }
};

export const prepareDataForSave = (payload: Partial<TFormData>): Partial<TMessageMatcher> => ({
  id: payload.id,
  approveStatus: payload.approveStatus,
  type: payload.type,
  name: payload.name?.trim(),
  category: payload.category || undefined,
  createdBy: payload.createdBy,
  language: payload.language || undefined,
  channelType: payload.channelType,
  exampleText: payload.exampleText?.trim() || undefined,
  subjectIds: payload.subjectIds,
  parentId: payload.type === EType.USER ? payload.parentId : undefined,
  description: payload.description?.trim() || undefined,
  smsProviders: payload.smsProviders
    ? Object.entries(payload.smsProviders)
        .filter(([, value]) => value)
        .map(([code]) => ({code: code as EOperatorName}))
    : undefined,
  contentType: payload.contentType ?? EContentType.TEXT,
  content: prepareMessageMatherContent(payload.channelType, payload),
});

export const getTimeInterval = (channelType?: TChannelType) => {
  switch (channelType) {
    case EChannelType.SMS:
      return {from: 3, count: 5};
    case EChannelType.VIBER:
      return {from: 1, count: 2};
    default:
      return null;
  }
};

export const getWordsFromVariableTemplate = (text: string | null) => text?.split(/{{.*?}}/g);
export const getWordsFromRegexTemplate = (text: string | null, regex?: RegExp) =>
  text?.split(regex ?? /%[dw]\S*/g);

type TMessageMatcherDataConfig = {
  duplicateFromId?: string | null;
  basedOnId?: string | null;
  messageMatcher?: TMessageMatcher;
};

const FieldForDuplicatesMap = [
  'name',
  'description',
  'adminComment',
  'exampleText',
  'category',
  'language',
  'content',
  'contentType',
  'subjectIds',
  'smsProviders',
  'type',
  'channelType',
  'parentId',
];

export const getMessageMatcherData = ({
  duplicateFromId,
  basedOnId,
  messageMatcher,
}: TMessageMatcherDataConfig): Partial<TMessageMatcher> | undefined => {
  const data = pick(messageMatcher, FieldForDuplicatesMap);

  if (duplicateFromId) {
    return data;
  }

  if (basedOnId) {
    return {
      ...data,
      parentId: Number(messageMatcher?.id),
      type: EType.USER,
    };
  }

  return messageMatcher;
};
